import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

// Split the coupon description string for position based styling
const splitStringOnDiscount = (emphasisText, inputString) => {
	if (inputString.startsWith(emphasisText)) {
		const discountPart = inputString.slice(0, emphasisText.length);
		const modifiedString = inputString.slice(emphasisText.length).trim();
		return { discountPart, modifiedString };
	}
	return { discountPart: null, modifiedString: inputString };
};

export const BasePromotionText = (props) => {
	const { company } = useContext(CompanyContext);
	const { emphasisText } = props;

	const couponDescriptionText = company?.fields?.couponDescription;
	if (!couponDescriptionText) return null;

	const { discountPart, modifiedString } = splitStringOnDiscount(
		emphasisText,
		couponDescriptionText,
	);

	return (
		<div className={props?.wrapper}>
			<div className="tw-flex tw-h-full tw-flex-col">
				<div className={props?.offerWrapper}>
					{props?.icon && (
						<span className={props?.offerIcon}>{props.icon}</span>
					)}
					<p className={props?.offerContent}>special offer</p>
				</div>
				<div className={props?.contentWrapper}>
					{discountPart && (
						<span className={props?.desktopLargeContent}>{discountPart}</span>
					)}
					<p className={props?.desktopContent}>{modifiedString}</p>
					<p className={props?.mobileContent}>{couponDescriptionText}</p>
				</div>
			</div>
		</div>
	);
};

BasePromotionText.propTypes = {
	icon: PropTypes.element,
	wrapper: PropTypes.string,
	offerWrapper: PropTypes.string,
	offerContent: PropTypes.string,
	contentWrapper: PropTypes.string,
	offerIcon: PropTypes.string,
	desktopLargeContent: PropTypes.string,
	desktopContent: PropTypes.string,
	mobileContent: PropTypes.string,
	emphasisText: PropTypes.string,
};
