import PropTypes from "prop-types";

import { Icon } from "../../../../components/BaseComponents/Icon";

export const CheckMarkIcon = ({ color, ...props }) => (
	<Icon viewBox="0 0 32 32" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.275 1.03824L30.275 5.03824C30.484 5.09801 30.6678 5.22424 30.7986 5.39782C30.9294 5.5714 31.0001 5.78288 31 6.00024C31 16.1482 27.041 22.3802 23.719 25.8192C21.6504 28.0323 19.1333 29.7785 16.336 30.9412C16.1189 31.0199 15.8811 31.0199 15.664 30.9412C15.517 30.8902 1 25.4792 1 6.00024C0.99988 5.78288 1.07058 5.5714 1.2014 5.39782C1.33221 5.22424 1.51603 5.09801 1.725 5.03824L15.725 1.03824C15.9048 0.987254 16.0952 0.987254 16.275 1.03824ZM22.5607 13.0607C23.1464 12.4749 23.1464 11.5251 22.5607 10.9393C21.9749 10.3536 21.0251 10.3536 20.4393 10.9393L14 17.3787L12.0607 15.4393C11.4749 14.8536 10.5251 14.8536 9.93934 15.4393C9.35355 16.0251 9.35355 16.9749 9.93934 17.5607L12.9393 20.5607C13.5251 21.1464 14.4749 21.1464 15.0607 20.5607L22.5607 13.0607Z"
			fill={color}
		/>
	</Icon>
);

CheckMarkIcon.propTypes = {
	color: PropTypes.string.isRequired,
};
