import { getUserZip } from "@/selectors";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw from "twin.macro";
import * as Yup from "yup";

const Zip = ({ setMediaAlphaState, isLastStep }) => {
	const submitZip = (zip) => {
		setMediaAlphaState({ zip }, isLastStep);
	};

	const invalidMessage = "Please enter a valid 5 digit zip code";
	const userZip = useSelector(getUserZip);

	const ZipElement = ({ userZip }) => {
		const usedZip = userZip || "";
		return (
			<Formik
				initialValues={{
					zip: usedZip,
				}}
				onSubmit={(values) => {
					const { zip } = values;
					submitZip(zip);
				}}
				validationSchema={Yup.object().shape({
					zip: Yup.string()
						.matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, { message: invalidMessage })
						.required(invalidMessage),
				})}
			>
				{({ handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<div>
							<div css={tw`w-full pt-2`}>
								<Field
									component={TextField}
									name="zip"
									label="Enter Zipcode"
									variant="outlined"
									css={tw`w-full bg-white`}
									id="zip"
									InputLabelProps={{ htmlFor: "zip" }}
									inputProps={{
										inputMode: "numeric",
										maxLength: 10,
										type: "text",
									}}
									FormHelperTextProps={{
										style: tw`text-center bg-ir-multiStep-modal m-0 pt-5px`,
									}}
								/>
							</div>
							<Button
								css={tw`w-full mt-4 text-white normal-case font-bold`}
								className="tw-bg-ir-multiStep-modal-button"
								variant="contained"
								type="submit"
								aria-label="Get results"
								endIcon={<ArrowForward />}
								onClick={handleSubmit}
							>
								Get Results
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		);
	};

	ZipElement.propTypes = {
		userZip: PropTypes.string,
	};
	return <ZipElement userZip={userZip} />;
};

Zip.propTypes = {
	setMediaAlphaState: PropTypes.func.isRequired,
	isLastStep: PropTypes.bool.isRequired,
};

export default Zip;
