import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

import { FVY_DOMAIN, IR_DOMAIN, SITE } from "../../../config/constants";
import { sortedCompaniesSelector } from "../../selectors";

const styles = {
	paginationWrapper: {
		baseStyles: css`
			display: flex;
			justify-content: center;
			margin-top: 40px;
			color: #787e81;

			ul {
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 0 auto;
				padding-left: 0;

				li {
					background-image: none;
					padding: 0;
					background-repeat: no-repeat;
					background-position: left top 5px;
					margin-bottom: 6px;
					color: #333;
					font-size: 16px;
					line-height: 1.4286;
					list-style-type: none;

					:nth-of-type(5) {
						@media (max-width: 640px) {
							display: none;
						}
					}

					:first-of-type a {
						margin-right: 20px;
						padding: 10px 17px 10px 40px;
						position: relative;

						:before {
							position: absolute;
							content: "";
							width: 6px;
							height: 10px;
							background-repeat: no-repeat;
							background-size: cover;
							background-image: url("/react-images/common/previousArrow.svg");
							top: 14px;
							left: 18px;

							@media (max-width: 640px) {
								left: 8px;
							}
						}

						@media (max-width: 640px) {
							margin-right: 10px;
							padding-left: 20px;
						}

						@media (max-width: 350px) {
							margin-right: 7px;
							padding-left: 18px;
							padding-right: 10px;
						}
					}

					:last-child a {
						margin-left: 11px;
						margin-right: 0;
						padding: 10px 40px 10px 17px;
						position: relative;

						@media (max-width: 415px) {
							padding-right: 30px;
							margin-left: 3px;
						}

						@media (max-width: 350px) {
							padding: 10px 29px 10px 10px;
						}

						:after {
							position: absolute;
							content: "";
							width: 6px;
							height: 10px;
							background-repeat: no-repeat;
							background-size: cover;
							background-image: url("/react-images/common/nextArrow.svg");
							top: 14px;
							right: 18px;
						}
					}

					a {
						font-size: 14px;
						font-family: Montserrat;
						font-style: normal;
						font-weight: 400;
						color: #333;
						background-color: #fff;
						padding: 10px 17px;
						margin-right: 9px;
						border-radius: 3px;
						line-height: 1;
						text-transform: capitalize;

						@media (max-width: 365px) {
							font-size: 13px;
						}

						:hover {
							color: #2f90da;
							transition: none;
						}

						@media (max-width: 640px) {
							padding: 10px 17px;
							margin-right: 7px;
						}
					}
				}
			}
		`,
		domainLevelOverrides: {
			[IR_DOMAIN]: {
				allVerticals: css`
					ul li a {
						box-shadow: 1px 3px 6px #d3d3d3;
					}
				`,
			},
			[FVY_DOMAIN]: {
				allVerticals: css`
					ul li a {
						box-shadow: 1px 3px 6px #d3d3d3;
					}
					margin-bottom: 32px;
				`,
			},
		},
	},
};

export const PaginationWrapper = ({ numberOfCompaniesPerPage, children }) => {
	const sortedCompaniesLength = useSelector(sortedCompaniesSelector).length;
	const isPaginationRequired = sortedCompaniesLength > numberOfCompaniesPerPage;

	return isPaginationRequired ? (
		<div
			data-cy="pagination-wrapper"
			css={[
				path(["paginationWrapper", "baseStyles"], styles),
				path(
					["paginationWrapper", "domainLevelOverrides", SITE, "allVerticals"],
					styles,
				),
			]}
		>
			{children}
		</div>
	) : null;
};

PaginationWrapper.propTypes = {
	numberOfCompaniesPerPage: PropTypes.number.isRequired,
	children: PropTypes.node.isRequired,
};
