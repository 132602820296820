import { useContext } from "react";
// import constants
import { FVY_DOMAIN, IR_DOMAIN, SITE } from "@/config/constants";
import { IR_AUTO_INSURANCE_DEFAULT_PATH } from "@/config/site/insuranceranked";
// import selector(s)
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path, prop } from "ramda";
import { useSelector } from "react-redux";

import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
// import components
import { CompanyContext } from "../../CompanyList/CompanyContext";

const styles = {
	revamped: {
		baseStyles: css`
			max-width: 160px;

			@media (max-width: 991px) {
				max-width: 70%;
			}
		`,

		withoutReviewWidget: css`
			@media (max-width: 580px) {
				max-width: 40%;
			}
		`,

		domainLevelOverrides: {
			[IR_DOMAIN]: {
				[IR_AUTO_INSURANCE_DEFAULT_PATH]: css`
					max-width: 240px;
					@media (max-width: 991px) {
						max-width: 80%;
					}
					@media (max-width: 580px) {
						max-width: 60%;
					}
				`,
			},
			[FVY_DOMAIN]: {
				allVerticals: css`
					@media (max-width: 991px) {
						max-width: 160px;
					}
				`,
			},
		},
	},
	legacy: css`
		max-width: 55%;
		text-align: center;
	`,
	Fabric: css`
		@media (min-width: 992px) {
			max-width: 130px !important;
		}
	`,
};

export const CompanyLogo = (props) => {
	const { company } = useContext(CompanyContext);
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);
	const { variant } = props;
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const vertical = useSelector(selectors.getVertical);
	const isAnyReviewWidgetRendered = useSelector(
		selectors.getIsAnyReviewWidgetRendered,
	);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);

	const serviceBlockStyles = [
		isServiceBlockLegacyStyled ? styles.legacy : styles.revamped.baseStyles,
		!isServiceBlockLegacyStyled && !isAnyReviewWidgetRendered
			? styles.revamped.withoutReviewWidget
			: null,
		prop(companyName, styles),
		path(["revamped", "domainLevelOverrides", SITE, vertical], styles),
		path(["revamped", "domainLevelOverrides", SITE, "allVerticals"], styles),
		usesSimplifiedDesign &&
			css`
				@media (max-width: 991px) {
					max-width: 180px !important;
				}

				@media (max-width: 375px) {
					max-width: 160px !important;
				}
			`,
	];

	const imageCssStyles = [
		serviceBlockStyles,
		variant &&
			css`
				max-width: 200px;
				@media (max-width: 580px) {
					max-width: 50%;
				}
			`,
	];

	return <BaseCompanyLogo style={{ image: imageCssStyles }} />;
};

CompanyLogo.propTypes = {
	variant: PropTypes.string,
};
export default CompanyLogo;
