import { useContext } from "react";
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CompanyContext } from "../CompanyList/CompanyContext";

const BaseAwardText = ({ style, icon, classNames }) => {
	const { company } = useContext(CompanyContext);
	const awardText = useSelector((state) =>
		selectors.getAwardText(state, { company }),
	);
	if (!awardText) return null;

	const renderText = () => (
		<div css={style} className={classNames?.text}>
			{awardText}
		</div>
	);

	if (icon) {
		return (
			<div className={classNames?.contentWrapper}>
				<div className={classNames?.icon}>{icon}</div>
				{renderText()}
			</div>
		);
	}

	return renderText();
};

export default BaseAwardText;

BaseAwardText.propTypes = {
	style: PropTypes.object,
	icon: PropTypes.element,
	classNames: PropTypes.object,
};
