import { SET_FEATURED_COMPANY } from "@/config/constants";

const initialState = { company: undefined };

const featuredCompanyReducer = (state = initialState, { type, payload }) => {
	if (type === SET_FEATURED_COMPANY) {
		return {
			company: payload.company,
		};
	} else return state;
};

export default featuredCompanyReducer;
