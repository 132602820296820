import { Icon } from "../../../../components/BaseComponents/Icon";

export const ServiceFlagTrophyIcon = (props) => (
	<Icon fill="none" viewBox="0 0 14 12" {...props}>
		<path
			fill="currentColor"
			d="M13.22 0H.78a.8.8 0 0 0-.55.22.74.74 0 0 0-.23.53V3c0 .8.33 1.56.91 2.12.58.56 1.38.88 2.2.88h.33a3.96 3.96 0 0 0 2.78 2.17v.08c0 .6-.24 1.17-.68 1.6-.44.41-1.03.65-1.65.65a.8.8 0 0 0-.55.22.74.74 0 0 0 0 1.06.8.8 0 0 0 .55.22h6.22c.2 0 .4-.08.55-.22a.74.74 0 0 0 0-1.06.8.8 0 0 0-.55-.22c-.62 0-1.21-.24-1.65-.66a2.2 2.2 0 0 1-.68-1.59v-.08A3.96 3.96 0 0 0 10.56 6h.33c.82 0 1.62-.32 2.2-.88.58-.56.91-1.32.91-2.12V.75c0-.2-.08-.39-.23-.53a.8.8 0 0 0-.55-.22ZM3.12 4.5c-.42 0-.82-.16-1.1-.44-.3-.28-.46-.66-.46-1.06V1.5H3.1v3ZM12.43 3c0 .4-.16.78-.45 1.06-.3.28-.69.44-1.1.44v-3h1.55V3Z"
		/>
	</Icon>
);
