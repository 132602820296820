import { useContext } from "react";
import { twMerge } from "@/helpers/twMerge";
import PropTypes from "prop-types";

import { useOutboundPaths } from "../../links/useOutboundPaths";
import { CompanyContext } from "../CompanyList/CompanyContext";
import OutboundLink from "../general/OutboundLink";

export const BaseLinkWrapper = ({
	children,
	extraQueryParams,
	className,
	css,
}) => {
	const { company } = useContext(CompanyContext);
	const classNameProp = twMerge(className);
	const { companyVisitPath } = useOutboundPaths();

	const outboundUrl = constructOutboundUrl(companyVisitPath, extraQueryParams);

	return (
		<OutboundLink
			to={outboundUrl}
			company={company}
			className={classNameProp}
			css={css}
		>
			{children}
		</OutboundLink>
	);
};

export default BaseLinkWrapper;

BaseLinkWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	css: PropTypes.object,
	extraQueryParams: PropTypes.object,
};

const constructOutboundUrl = (companyVisitPath, extraQueryParams) => {
	// This is to contruct the URL in a way that is compatible with SSR
	const tempOrigin = "http://local.applied-minds.com";
	const url = new URL(companyVisitPath, tempOrigin);
	const searchParams = new URLSearchParams(url.search);

	if (extraQueryParams) {
		Object.entries(extraQueryParams).forEach(([key, value]) => {
			searchParams.set(key, value);
		});
	}

	url.search = searchParams.toString();
	return url.toString().replace(tempOrigin, "");
};
