import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { FVY_DOMAIN, SITE } from "../../../../config/constants";
import { DuoToneSearchIcon } from "../../../client/assets/icons/favy/DuoToneSearchIcon";
import { getVertical } from "../../../selectors";
import { CompanyContext } from "../../CompanyList/CompanyContext";
import { FormWrapper } from "./FormWrapper";

export const BackgroundSearchWidget = ({ companies }) => {
	const vertical = useSelector(getVertical);
	const beenVerifiedCompany = companies.find(
		(company) => company.fields.companySlug === "beenverified",
	);

	if (
		SITE !== FVY_DOMAIN ||
		vertical !== "background-search" ||
		!beenVerifiedCompany
	) {
		return null;
	}

	return (
		<CompanyContext.Provider value={{ company: beenVerifiedCompany }}>
			<div className="tw-my-6 tw-flex tw-items-center tw-justify-between tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-[0px_4px_16px_0px_rgba(30,25,40,0.06)] max-lg:tw-flex-col max-lg:tw-gap-6">
				<div className="tw-flex tw-items-center tw-gap-4 max-lg:tw-w-full">
					<div className="tw-rounded-full tw-bg-[#FDEEF2] tw-p-4 max-md:tw-p-3">
						<DuoToneSearchIcon
							className="tw-text-[32px] tw-text-[#22293A] max-md:tw-text-[26px]"
							curveColor="#E1255B"
						/>
					</div>
					<div className="tw-max-w-[280px] tw-text-2xl tw-font-bold tw-leading-8 tw-tracking-[-0.48px] tw-text-[#131D2B] max-md:tw-text-xl max-md:tw-leading-[26px] max-md:tw-tracking-[-0.4px]">
						Want to Know About Someone?
					</div>
				</div>
				<FormWrapper />
			</div>
		</CompanyContext.Provider>
	);
};

BackgroundSearchWidget.propTypes = {
	companies: PropTypes.array.isRequired,
};
