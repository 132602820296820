import {
	FINAL_EXPENSE_INSURANCE_PATH,
	IR_AUTO_INSURANCE_DEFAULT_PATH,
	IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH,
	MEDICARE_ADVANTAGE_PATH,
	MEDICARE_DEFAULT_PATH,
	MEDICARE_SUPPLEMENT_PATH,
	WHOLE_LIFE_INSURANCE_PATH,
} from "../site/insuranceranked";

/* Used for matching Contentful entries to MA feed companies when company name provided ('carrier' property) in the MA response doesn't
 * match with an existing Contentful entry for that company.
 *
 * NOTE: if the 'companyName' property in the contentful entry === the 'carrier' property in the MA ad, you need not add a property below.
 *
 * */

export const MEDIA_ALPHA_CARRIER_MAPPING = {
	/*
  IMPORTANT: Needs to be keyed by 'companySlug' NOT 'companyName'.
  Necessary due to the possibility of multiple company entries for the same company across different verticals where the 'companyName' field would be identical
   */
	"united-healthcare-medicare-advantage":
		"UHC/AARP Medicare Advantage (Horizon)",
	trustage: "TruStage",
	"legal-and-general": "Legal & General America",
	"national-family-final-expense": "NationalFamily.com",
	"national-family-whole-life": "NationalFamily.com",
	"trustage-whole-life": "TruStage Life",
	"noblr-auto-insurance": "NOBLR",
	"go-medicare-medicare-advantage": "MedicareEnroller.com",
	"go-medicare-medicare-supplement": "MedicareEnroller.com",
};

// Prevents rendering MA companies with a bid less than the defined value
export const MEDIA_ALPHA_MINIMUM_BIDS = {
	[FINAL_EXPENSE_INSURANCE_PATH]: 1,
	[WHOLE_LIFE_INSURANCE_PATH]: 4.5,
	[MEDICARE_DEFAULT_PATH]: 1.5,
	[MEDICARE_ADVANTAGE_PATH]: 1.5,
	[MEDICARE_SUPPLEMENT_PATH]: 1.5,
};

/*
 Use the object below to enable companies of the corresponding
 vertical to render benefits from the MA payload, not the 'Company Main Benefits' field of Contentful.
*/
export const MEDIA_ALPHA_BENEFITS_MAP = {
	[IR_AUTO_INSURANCE_DEFAULT_PATH]: true,
	[IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH]: true,
	[FINAL_EXPENSE_INSURANCE_PATH]: true,
	[MEDICARE_ADVANTAGE_PATH]: true,
	[MEDICARE_SUPPLEMENT_PATH]: true,
};

/*
 Use the object below to enable using the Media Alpha company logo instead of pulling it from Contentful
*/
export const USE_MEDIA_ALPHA_COMPANY_LOGO = {
	[IR_AUTO_INSURANCE_DEFAULT_PATH]: true,
	[IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH]: true,
	[FINAL_EXPENSE_INSURANCE_PATH]: true,
	[MEDICARE_ADVANTAGE_PATH]: true,
	[MEDICARE_SUPPLEMENT_PATH]: true,
};

/*
 Use the object below to enable rendering the Media Alpha headline
*/
export const USE_MEDIA_ALPHA_HEADLINE = {
	[IR_AUTO_INSURANCE_DEFAULT_PATH]: true,
	[IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH]: true,
	[FINAL_EXPENSE_INSURANCE_PATH]: true,
	[MEDICARE_ADVANTAGE_PATH]: true,
	[MEDICARE_SUPPLEMENT_PATH]: true,
};
