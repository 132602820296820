import { path, pathOr, pipe } from "ramda";

export const filterPurelyMediaAlphaCompanies = (companies) =>
	companies.filter((company) => {
		if (company.fields.isMediaAlpha) {
			return true;
		} else return !company.fields.isPurelyMediaAlphaPartner;
	});

export const filterAdNetworkCompanies = (companies) =>
	pipe(filterPurelyMediaAlphaCompanies)(companies);

export const filterMediaAlphaByMinimumBid = (minimumBid) => (companies) =>
	companies.filter((company) =>
		company.fields.bid ? company.fields.bid > minimumBid : true,
	);

export const filterByLoanAmount = (loanAmount) => (companies) => {
	const amount =
		typeof loanAmount === "object" && loanAmount.hasOwnProperty("value")
			? loanAmount.value
			: loanAmount;
	return amount
		? companies.filter(
				(company) =>
					path(["fields", "maximumLoanAmount"], company) >= amount &&
					amount >= path(["fields", "minimumLoanAmount"], company),
			)
		: companies;
};

export const filterByCreditQuality = (creditQuality) => (companies) =>
	creditQuality
		? companies.filter((company) => {
				const creditTypes = pathOr(
					[],
					["fields", "acceptedCreditTypes"],
					company,
				);
				return creditTypes.includes(creditQuality);
			})
		: companies;

export const filterEligibleAndGeoRelevantCompanies = (
	companies,
	userGeoState,
	isMediaAlphaTargetedPath,
) => {
	let recommendedCompanies = companies.filter((company) =>
		pathOr(false, ["fields", "recommended"], company),
	);

	if (isMediaAlphaTargetedPath) {
		recommendedCompanies = recommendedCompanies.filter(
			(company) =>
				!path(
					["fields", "prohibitRenderingOnMediaAlphaTargetedPaths"],
					company,
				),
		);
	}

	return typeof userGeoState === "undefined"
		? recommendedCompanies
		: recommendedCompanies.filter((company) => {
				// normalize company states and user geo state to lowercase just to be safe (between NetAcuity and Contentful)
				// if no excluded states, then company is not geo-locked
				const excludedStates = pathOr(
					[],
					["fields", "excludedStates"],
					company,
				).map((excludedState) => excludedState.toLowerCase());
				return !excludedStates.includes(userGeoState.toLowerCase());
			});
};

export const filterByFundingType = (fundingType) => (companies) =>
	fundingType
		? companies.filter((company) => {
				const loanOptions = pathOr(
					[],
					["fields", "availableLoanOptions"],
					company,
				);
				return loanOptions.includes(fundingType);
			})
		: companies;

/**
* Filtering function for business loans filtering UI.
The filtering UI implements a <Select> with options like: '$8,000 - $12,000'.
We're not necessarily concerned about filtering within the specific range, but want to return
all companies with a minimumMonthlyRevenue requirement that's less than or equal to the highest value of that range.
* @param {number} monthlyRevenue - filtering range high value e.g. 12000
*/
export const filterByMonthlyRevenue = (monthlyRevenue) => (companies) =>
	monthlyRevenue
		? companies.filter((company) => {
				const minimumMonthlyRevenue = path(
					["fields", "minimumMonthlyRevenue"],
					company,
				);

				/* Don't filter out companies with no monthly revenue requirement */
				if (minimumMonthlyRevenue === 0) return true;

				/* Returns true for all companies with a minimumMonthlyRevenue requirement <= monthlyRevenue param*/
				return minimumMonthlyRevenue <= monthlyRevenue;
			})
		: companies;

export const filterByPolicyType = (policyType) => (companies) =>
	policyType
		? companies.filter((company) => {
				const policyTypesOffered = pathOr(
					[],
					["fields", "policyTypesOffered"],
					company,
				);
				return policyTypesOffered.includes(policyType);
			})
		: companies;

export const filterByMortgageType = (mortgageType) => (companies) =>
	mortgageType
		? companies.filter((company) => {
				const mortgageTypesOffered = pathOr(
					[],
					["fields", "mortgageTypesOffered"],
					company,
				);
				return mortgageTypesOffered.includes(mortgageType);
			})
		: companies;

export const filterByLanguage = (language) => (companies) =>
	language
		? companies.filter((company) => {
				const languageOptions = pathOr(
					[],
					["fields", "languagesOffered"],
					company,
				);
				return languageOptions.includes(language);
			})
		: companies;

export const filterByHairGrowthType = (gender) => (companies) =>
	gender
		? companies.filter((company) => {
				const genderOptions = pathOr([], ["fields", "gendersOffered"], company);
				return genderOptions.includes(gender);
			})
		: companies;

export const filterByBadCreditQuality = filterByCreditQuality("Poor");
export const filterByFairCreditQuality = filterByCreditQuality("Fair");
export const filterByGoodCreditQuality = filterByCreditQuality("Good");
export const filterByExcellentCreditQuality =
	filterByCreditQuality("Excellent");
