import { prop } from "ramda";
import useSSR from "use-ssr";

// Since we need a way of being able to make http request to our backend (If needed) and also to contentful
// our cms, we need to be able to send two different headers for each.
// Although both tokens are different (bearer and not bearer), if we add the CSRF token as a common to our header,
// our browser preflight will be rejected by contentful as contentful does not recognize a X-CSRF-TOKEN key.
// Hence the exported objects can ge used with axios when making a HTTP request
const { isServer } = useSSR();

const CSRF = isServer
	? undefined
	: document.head.querySelector('meta[name="csrf-token"]');
const CSRF_TOKEN = prop("content", CSRF);

export const LARAVEL_HEADER = {
	headers: { "X-CSRF-TOKEN": CSRF_TOKEN },
};
