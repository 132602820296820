import { css } from "@emotion/react";
import PropTypes from "prop-types";
import tw from "twin.macro";

const styles = {
	colors: {
		default: css`
			background-color: #e2e4e4;
		`,
		poor: css`
			background-color: #ff343a;
		`,
		fair: css`
			background-color: #ffc334;
		`,
		average: css`
			background-color: #ddd14d;
		`,
		good: css`
			background-color: #a0c15a;
		`,
		excellent: css`
			background-color: #27b020;
		`,
	},
};

export default function ColorBar(props) {
	const { rating } = props;
	const ratingMultiplier = 2;
	const adjustedRating = rating * ratingMultiplier;
	const rounded = Math.floor(adjustedRating);
	const arrayLength = 10;
	const remainderRating = arrayLength - rounded;
	const poor = 2;
	const fair = 4;
	const average = 6;
	const good = 8;
	let colorArr = [];

	for (let i = 0; i < rounded; i++) {
		if (rounded <= poor) {
			colorArr.push("poor");
		} else if (rounded <= fair) {
			colorArr.push("fair");
		} else if (rounded <= average) {
			colorArr.push("average");
		} else if (rounded <= good) {
			colorArr.push("good");
		} else {
			colorArr.push("excellent");
		}
	}
	for (let j = 0; j < remainderRating; j++) {
		colorArr.push("default");
	}

	const spans = colorArr.map((span, idx) => (
		<span key={idx} css={[styles.colors[span], tw`block w-9px h-10px`]} />
	));

	return (
		<div
			css={[
				styles.colorBar,
				tw`flex items-center justify-around w-full mx-auto shadow-lg bg-white max-w-114px py-3px px-2px`,
			]}
		>
			{spans}
		</div>
	);
}

ColorBar.propTypes = {
	rating: PropTypes.number,
};
