export const ONLINE_DEGREES_PATH = "online-degrees";
export const PRIVATE_STUDENT_LOANS_PATH = "private-student-loans";

export const BE_SITE_CONFIGURATION = {
	[ONLINE_DEGREES_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[PRIVATE_STUDENT_LOANS_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
};
