import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ isFeatured, companyIndex }) => ({
	layoutWrapper: css`
		${tw`block w-full overflow-hidden mb-4 rounded-[12px] border-[0.5px] border-solid border-[rgba(22,34,51,0.07)]`}
		${isFeatured ? tw`border-2 border-solid border-[#15803c] mt-6` : tw``}

		box-shadow: 0px 2px 16px -4px rgba(22, 34, 51, 0.08);
	`,
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#dcfce8] tw-text-center tw-rounded-t-[12px]",
		contentWrapper:
			"tw-leading-6 tw-font-semibold tw-text-xs tw-text-black tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	rootGrid: {
		main: css`
			${tw`relative bg-white rounded-[12px] flex flex-col p-4 lg:flex-row lg:py-6 lg:pr-6 lg:pl-14`}
		`,
	},
	col1: css`
		${tw`relative w-full flex justify-center items-center pt-6 pb-10 lg:p-0 lg:w-[20%] lg:justify-center lg:mr-4 [&>a]:w-full [&>a]:h-full [&>a]:flex [&>a]:items-center [&>a]:justify-center`}
	`,
	col2: css`
		${tw`w-full flex flex-col justify-center items-start mb-4 sm:max-w-[420px] sm:mx-auto lg:w-[55%] lg:mb-0 lg:mr-4 lg:max-w-none lg:ml-0`}
	`,
	col3: css`
		${tw`gap-4 flex flex-col justify-center items-center lg:gap-6 lg:flex-col lg:w-[21%]`}
	`,
	coupon: {
		wrapper: "tw-p-2 tw-bg-white tw-flex tw-items-center tw-justify-center",
		contentWrapper:
			"tw-w-full tw-p-2 tw-flex tw-items-center tw-justify-center tw-text-center tw-bg-[#F0FDF4] tw-text-[#16A34A] tw-rounded-lg hover:tw-underline",
		contentIcon: "tw-inline-flex tw-mr-2 tw-text-2xl tw-text-[#16A34A]",
		content: "tw-text-sm sm:tw-text-base tw-font-bold",
	},
	// Col1
	serviceFlag: {
		wrapper: css`
			${tw``}
		`,
		flag: css`
			${tw`absolute top-4 left-4 justify-center items-center w-6 h-6 rounded-full border-2 border-solid border-[#131D2B] lg:top-1/2 lg:w-8 lg:h-8 lg:left-6`}

			@media (min-width: 1024px) {
				transform: translateY(-50%);
			}

			${isFeatured || companyIndex === 1 ? tw`hidden lg:flex` : tw`flex`}
		`,
		number: css`
			${tw`text-[#131D2B] font-semibold leading-6 text-sm text-center lg:text-base lg:font-bold`}
		`,
		text: css`
			${tw`absolute top-4 left-0 text-center bg-[#16A34A] text-white text-xs py-1 pl-2.5 pr-4 leading-4 font-semibold lg:text-sm lg:top-6 lg:left-0`}
			clip-path: polygon(100% 0, calc(100% - 10px) 50%, 100% 100%, 0 100%, 0 0);
		`,
	},
	logoWrapper: css`
		${tw``}
	`,
	companyLogo: {
		picture: css`
			${tw`w-[160px] flex justify-center`}
		`,
		image: [
			css`
				${tw`w-full h-full object-contain`}
			`,
		],
	},

	// Col2
	companyName: {
		text: css`
			${tw`text-lg font-extrabold mb-2 text-[#131D2B] leading-7 tracking-[-0.18px] lg:text-2xl`}
		`,
	},
	rating: {
		wrapper: css`
			${tw`flex mb-2`}
		`,
		score: css`
			${tw`font-extrabold text-[#1a1a1a] text-xl mb-0 mr-4 lg:mr-2`}
		`,
		stars: css`
			${tw`flex justify-between lg:justify-center flex-row items-center mr-4 lg:mr-2 w-fit`}
		`,
		starColor: css`
			.MuiRating-iconFilled {
				${tw`text-[#f68700]`}
			}
		`,
		count: css`
			${tw`text-sm font-semibold text-[#1a1a1a] mb-0 tracking-tight text-center ml-2`}
		`,
	},
	benefitsList: {
		wrapper: "tw-w-full tw-px-0",
		list: "tw-w-full tw-mb-0 tw-p-0",
		listItem:
			"tw-w-full tw-text-sm tw-leading-6 md:tw-text-base tw-text-[#2B3440] tw-pl-6 tw-mb-2 last:tw-mb-0 tw-bg-[url(/public/react-images/common/check.svg)] [background-position:left_top_5px] tw-bg-no-repeat [background-size:16px]",
	},

	// Col3
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-max-w-fit tw-self-center tw-rounded-lg tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none md:after:tw-w-3 md:after:tw-h-3 after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#F68700] tw-text-2xl md:tw-text-[32px]",
		content:
			"tw-min-w-[100px] tw-max-w-[100px] tw-font-semibold tw-text-[0.6875rem] tw-text-left tw-text-[#22293A]",
	},
	visitLink: {
		wrapper:
			"tw-group tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-1 tw-bg-[#16A34A] tw-rounded-lg tw-transition-all tw-cursor-pointer hover:tw-shadow-lg",
		contentWrapper:
			"tw-py-3 tw-text-center tw-font-semibold tw-text-base tw-leading-6 tw-text-white",
		iconClass:
			"tw-hidden tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-inline-flex",
	},
});
