import { css } from "@emotion/react";
import PropTypes from "prop-types";

const styles = {
	ServiceBlockCallOut: css`
		display: inline-block;
		padding: 7px 10px 5px;
		letter-spacing: 0.05em;
		margin-top: 7px;
		line-height: 1;
		background-color: #fff;
		border: 2px solid #1d8ce0;
		border-radius: 3px;
		box-shadow: 0 3px 9px rgba(59, 59, 59, 0.28);
		color: #1d8ce0;
		font-family: Montserrat;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
	`,
};

const ServiceBlockCallOut = ({ children }) => (
	<span css={styles.ServiceBlockCallOut}>{children}</span>
);

export default ServiceBlockCallOut;

ServiceBlockCallOut.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
