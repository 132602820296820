import { twMerge } from "@/helpers/twMerge";
import PropTypes from "prop-types";

// For accessibility - https://www.smashingmagazine.com/2021/05/accessible-svg-patterns-comparison/
// Default: aria hidden props are used as the majority of icons are decorative
export const Icon = ({ ariaLabel = false, className, children, ...props }) => {
	const ariaLabelProps = ariaLabel
		? {
				role: "img",
			}
		: {
				"aria-hidden": "true",
				focusable: "false",
			};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={twMerge(
				"tw-inline-block tw-h-[1em] tw-w-[1em] tw-shrink-0 tw-align-middle tw-leading-[1em]",
				className,
			)}
			viewBox="0 0 24 24"
			{...ariaLabelProps}
			{...props}
		>
			{ariaLabel ? <title>{ariaLabel}</title> : null}
			{children}
		</svg>
	);
};

Icon.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	viewBox: PropTypes.string,
	ariaLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
