import { SET_MULTISTEP_MODAL_STATE } from "@/config/constants";

const initialState = {};

const multistepModalReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_MULTISTEP_MODAL_STATE:
			return {
				...payload["data"],
			};
		default:
			return state;
	}
};

export default multistepModalReducer;
