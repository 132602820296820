import { useContext, useEffect } from "react";
import {
	FILTERING_PARAMETERS_SET,
	IR_DOMAIN,
	SITE,
	VARIATION_ID,
} from "@/config/constants";
import {
	IR_AUTO_INSURANCE_DEFAULT_PATH,
	IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH,
} from "@/config/site/insuranceranked";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useDispatch, useSelector } from "react-redux";

import { CompanyContext } from "../../CompanyList/CompanyContext";
import ImageGallery from "../ServiceRight/ImageGallery";
import LogoSection from "./LogoSection";
import RatingSection from "./RatingSection";

const styles = {
	legacy: css`
		width: 40%;
		display: flex;

		@media (max-width: 991px) {
			width: 100%;
			display: table;
			padding: 20px;
		}

		@media (max-width: 767px) {
			padding: 20px 0 0;
			width: 100%;
			flex-wrap: wrap;
			max-width: 100%;
		}

		@media (max-width: 500px) {
			padding-bottom: 10px;
		}
	`,
	revamped: css`
		width: 20%;
		display: flex;

		@media (max-width: 991px) {
			width: 100%;
		}

		@media (max-width: 991px) and (min-width: 767px) {
			display: table;
			padding: 20px 20px 20px 40px;
		}

		@media (max-width: 767px) {
			padding: 20px 0 0 0;
			padding-left: 15px;
			padding-right: 15px;
		}

		@media (max-width: 580px) {
			padding-top: 15px;
			padding-bottom: 0;
		}
	`,
	domainLevelOverrides: {
		[IR_DOMAIN]: {
			allVerticals: css`
				@media (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}
			`,
			[IR_AUTO_INSURANCE_DEFAULT_PATH]: css`
				width: 30%;
			`,
		},
	},
};

export const ServiceLeftLayout = ({ companyIndex, variant }) => {
	const { company } = useContext(CompanyContext);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const vertical = useSelector(selectors.getVertical);
	const isProductListingPage = useSelector(selectors.getIsProductListingPage);
	const dispatch = useDispatch();
	useEffect(
		function dispatchParams() {
			if (vertical === IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH) {
				if (variant) {
					dispatch({
						type: FILTERING_PARAMETERS_SET,
						payload: { [VARIATION_ID]: "logoSizeV2" },
					});
				} else
					dispatch({
						type: FILTERING_PARAMETERS_SET,
						payload: { [VARIATION_ID]: "logoSizeV1" },
					});
			}
		},
		[variant],
	);

	return (
		<div
			css={[
				isServiceBlockLegacyStyled ? styles.legacy : styles.revamped,
				path(["domainLevelOverrides", SITE, "allVerticals"], styles),
				path(["domainLevelOverrides", SITE, vertical], styles),
				isProductListingPage &&
					css`
						flex-direction: column;
						padding-top: 3rem;
						width: 25%;
					`,
				variant &&
					css`
						width: 30%;
					`,
			]}
		>
			<LogoSection companyIndex={companyIndex} />
			<RatingSection company={company} />
			{isProductListingPage && (
				<ImageGallery company={company} companyIndex={companyIndex} />
			)}
		</div>
	);
};

export default ServiceLeftLayout;

ServiceLeftLayout.propTypes = {
	companyIndex: PropTypes.number.isRequired,
	variant: PropTypes.string,
};
