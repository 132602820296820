import { useContext } from "react";
import { getCompanyLogoUrl, getCompanyName } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CompanyContext } from "../CompanyList/CompanyContext";
import { BasePictureElement } from "./BasePictureElement";

export const BaseCompanyLogo = ({ style, classNames, ...props }) => {
	const { company } = useContext(CompanyContext);
	const companyName = useSelector((state) =>
		getCompanyName(state, { company }),
	);
	const companyLogoUrl = useSelector((state) =>
		getCompanyLogoUrl(state, { company }),
	);

	return (
		<BasePictureElement
			src={companyLogoUrl}
			alt={companyName}
			style={style}
			classNames={classNames}
			{...props}
		/>
	);
};

BaseCompanyLogo.propTypes = {
	style: PropTypes.object,
	classNames: PropTypes.object,
};
