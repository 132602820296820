export const TAX_ASSISTANCE_PATH = "tax-assistance";

export const BTR_SITE_CONFIGURATION = {
	[TAX_ASSISTANCE_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		usesSimplifiedDesign: true,
		usesProductDescription: true,
		reviewWidgets: {
			widget: "webReview",
		},
	},
};
