import { Icon } from "../../../../components/BaseComponents/Icon";

export const CouponDiscountIcon = (props) => (
	<Icon fill="none" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.38.22a1 1 0 0 1 1.24 0l3.4 2.71h4.05a1 1 0 0 1 1 1v4.05l2.71 3.4a1 1 0 0 1 0 1.24l-2.71 3.4v4.05a1 1 0 0 1-1 1h-4.05l-3.4 2.71a1 1 0 0 1-1.24 0l-3.4-2.71H3.93a1 1 0 0 1-1-1v-4.05l-2.71-3.4a1 1 0 0 1 0-1.24l2.71-3.4V3.93a1 1 0 0 1 1-1h4.05l3.4-2.71ZM12 2.28 8.96 4.71a1 1 0 0 1-.63.22h-3.4v3.4a1 1 0 0 1-.22.63L2.28 12l2.43 3.04a1 1 0 0 1 .22.63v3.4h3.4a1 1 0 0 1 .63.22L12 21.72l3.04-2.43a1 1 0 0 1 .63-.22h3.4v-3.4a1 1 0 0 1 .22-.63L21.72 12l-2.43-3.04a1 1 0 0 1-.22-.63v-3.4h-3.4a1 1 0 0 1-.63-.22L12 2.28ZM9.07 8.6a.47.47 0 1 0 0 .93.47.47 0 0 0 0-.93Zm-2.47.47a2.47 2.47 0 1 1 4.93 0 2.47 2.47 0 0 1-4.93 0Zm9.77-.03-7.33 7.33a1 1 0 0 1-1.41-1.41l7.33-7.33a1 1 0 1 1 1.41 1.41Zm-1.44 5.43a.47.47 0 1 0 0 .93.47.47 0 0 0 0-.93Zm-2.46.46a2.47 2.47 0 1 1 4.93 0 2.47 2.47 0 0 1-4.93 0Z"
			clipRule="evenodd"
		/>
	</Icon>
);
