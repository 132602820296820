// import components
import ColorBar from "@/components/general/ColorBar";
// import selectors
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const styles = {
	ratingSection: css`
		text-align: center;
		font-family: AvenirNextLTPro-Demi;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		background-color: #f8fafa;
		padding: 5px 10px;
		min-width: 150px;
		position: relative;
		border: none;
		display: block;

		@media (min-width: 992px) {
			min-height: 150px;
		}

		@media (max-width: 991px) {
			background-color: transparent;
		}

		@media (max-width: 767px) {
			display: none;
		}
	`,

	desktopRatings: css`
		text-align: center;
		font-family: AvenirNextLTPro-Demi;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		background-color: #f8fafa;
		border-bottom: 0;
		min-width: 150px;
		position: relative;
		border: none;
		display: block;

		top: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		left: 50%;
		width: 100%;

		@media (max-width: 991px) {
			display: none;
		}
	`,

	tableRatings: css`
		text-align: center;
		font-family: AvenirNextLTPro-Demi;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		display: flex;
		border-radius: 3px;
		border: 1px solid #d6dbdf;
		padding: 5px 10px;
		background-color: #f8fafa;
		max-width: 300px;
		min-width: 150px;
		position: relative;

		@media (min-width: 992px) {
			display: none;
		}
	`,

	ratingSpan: css`
		font-size: 12px;
		margin-right: 0;
		text-transform: uppercase;
		letter-spacing: 0.025em;
		text-align: left;
		color: #71777a;
	`,

	ratingCount: css`
		color: #333742;
		font-size: 3rem;
		margin-top: 0;
		font-family: AvenirNextLTPro-Demi;

		@media (max-width: 991px) {
			color: #333742;
			font-size: 30px;
		}
	`,
};

export const RatingSection = ({ company }) => {
	// exit early if vertical is not legacy styled
	const isRendered = useSelector(selectors.getIsServiceBlockLegacyStyled);
	if (!isRendered) return null;

	const rating = useSelector((state) =>
		selectors.getCompanyRating(state, { company }),
	);
	const ratingTextParts = useSelector(selectors.getRatingTextParts);
	const overallRating = `${ratingTextParts["1"]} ${ratingTextParts["2"]}`;

	return (
		<div css={styles.ratingSection}>
			<div css={styles.desktopRatings}>
				<span css={styles.ratingSpan}>{overallRating}</span>
				<br />
				<p css={styles.ratingCount}>{rating}</p>
				<ColorBar rating={rating} />
			</div>
			<div css={styles.tableRatings}>
				<span css={styles.ratingSpan}>
					{ratingTextParts["1"]} <br /> {ratingTextParts["2"]}
				</span>
				<ColorBar rating={rating} />
				<span css={styles.ratingCount}>{rating}</span>
			</div>
		</div>
	);
};

RatingSection.propTypes = {
	company: PropTypes.object.isRequired,
};

export default RatingSection;
