import * as constants from "@/config/constants";
import * as Headers from "@/config/headers";
import axios from "axios";

export const fetchMediaAlphaFeed = (vertical, data) => (dispatch) => {
	const url = `/placements/media-alpha/${vertical}`;
	dispatch({ type: constants.MEDIA_ALPHA_REQUEST });
	return axios
		.post(url, data, Headers.LARAVEL_HEADER)
		.then((response) =>
			dispatch({
				type: constants.MEDIA_ALPHA_SUCCESS,
				payload: { [constants.MEDIA_ALPHA_FEED]: response.data },
			}),
		)
		.catch((error) =>
			dispatch({
				type: constants.MEDIA_ALPHA_FAILURE,
				payload: { error },
			}),
		);
};
