import { Rating } from "@mui/material";
import PropTypes from "prop-types";
import { css } from "twin.macro";

import { useWebReviewScore } from "../../hooks/useWebReviewScore";

const BaseGridRating = ({ style }) => {
	const { score, ratingLabel } = useWebReviewScore();
	if (score === 0) return null;

	return (
		<div css={style.wrapper}>
			<p css={style.score}>{score}</p>
			<div css={style.stars}>
				<Rating
					css={css`
						.MuiRating-iconFilled {
							${style.starColor}
						}
					`}
					precision={0.1}
					readOnly
					defaultValue={score}
				/>
			</div>
			<p css={style.count}>{ratingLabel}</p>
		</div>
	);
};

export default BaseGridRating;

BaseGridRating.propTypes = {
	style: PropTypes.object,
};
