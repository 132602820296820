import { useCallback } from "react";
import {
	getCompanyName,
	getCompanySlug,
	getIsDirectCompanyFromPayload,
	getIsMediaAlphaPublisherVertical,
	getVertical,
} from "@/selectors";
import { useSelector } from "react-redux";

const OPEN_NEW_TAB = "_blank";

const useLinkProps = ({
	to,
	company,
	onClick = null,
	openInNewTab = true,
	conversionValue = 0,
}) => {
	const isMediaAlphaPublisherVertical = useSelector(
		getIsMediaAlphaPublisherVertical,
	);
	const isDirectCompanyFromPayload = useSelector((state) =>
		getIsDirectCompanyFromPayload(state, { company }),
	);
	const companyName = useSelector((state) =>
		getCompanyName(state, { company }),
	);
	const vertical = useSelector(getVertical);
	const companySlug = useSelector((state) =>
		getCompanySlug(state, { company }),
	);

	const trackLink = useCallback(() => {
		window.gtag("event", "click", {
			event_category: vertical,
			event_label: companySlug,
			value: conversionValue,
		});

		window.uetq = window.uetq || [];
		window.uetq.push({
			ec: vertical,
			ea: "click",
			el: companySlug,
			ev: conversionValue,
		});
	}, [vertical, companySlug, conversionValue]);

	const handleClick = useCallback(
		(event) => {
			if (!isMediaAlphaPublisherVertical || isDirectCompanyFromPayload) {
				trackLink();
			}

			if (onClick) {
				onClick(event);
			}
		},
		[
			onClick,
			isMediaAlphaPublisherVertical,
			isDirectCompanyFromPayload,
			trackLink,
		],
	);

	const linkProps = {
		href: to,
		onClick: handleClick,
		onAuxClick: handleClick,
		rel: "nofollow",
		"aria-label": "Visit " + companyName,
	};

	if (openInNewTab) {
		linkProps.target = OPEN_NEW_TAB;
	}

	return linkProps;
};

export default useLinkProps;
