import { Icon } from "../../../../components/BaseComponents/Icon";

export const SocialProofIcon = (props) => (
	<Icon viewBox="0 0 32 32" fill="none" {...props}>
		<circle cx="16" cy="16" r="16" fill="currentColor" />
		<path
			d="M23.2746 18.6376L21.087 17.989C20.9101 17.9365 20.7506 17.8344 20.6261 17.694C20.5015 17.5536 20.4167 17.3803 20.381 17.1931L20.2465 16.4932C20.769 16.2457 21.212 15.8479 21.5229 15.347C21.8337 14.8461 21.9993 14.2632 22 13.6676V12.2429C22.0124 11.4179 21.7168 10.62 21.1749 10.0159C20.633 9.41178 19.8869 9.04829 19.0925 9.00143C18.4996 8.98279 17.9147 9.1468 17.4117 9.47274C16.9087 9.79867 16.5102 10.2719 16.2665 10.8326C16.7436 11.5529 16.9993 12.406 17 13.2798V14.7045C16.9981 15.0817 16.9461 15.4568 16.8455 15.8192C17.1049 16.1006 17.4129 16.3292 17.7535 16.4932L17.619 17.1931C17.5831 17.3801 17.4983 17.5532 17.3737 17.6934C17.2492 17.8336 17.0897 17.9355 16.913 17.9879L16.0695 18.2378L17.5495 18.6764C17.9669 18.8014 18.3339 19.0635 18.5955 19.4232C18.857 19.783 18.999 20.221 19 20.6715V22.4814C18.9983 22.6584 18.9672 22.8338 18.908 22.9999H23.5001C23.6327 22.9999 23.7599 22.9453 23.8536 22.848C23.9474 22.7508 24.0001 22.6189 24.0001 22.4814V19.6346C24.0001 19.4092 23.9292 19.19 23.7983 19.0101C23.6674 18.8302 23.4836 18.6994 23.2746 18.6376Z"
			fill="white"
		/>
		<path
			d="M17.2746 19.6747L15.0871 19.0261C14.9102 18.9736 14.7507 18.8716 14.6261 18.7312C14.5016 18.5908 14.4168 18.4174 14.3811 18.2303L14.2466 17.5298C14.769 17.2824 15.212 16.8846 15.5228 16.3839C15.8336 15.8831 15.9993 15.3003 16.0001 14.7047V13.28C16.0125 12.4551 15.7168 11.6572 15.175 11.053C14.6331 10.4489 13.8869 10.0855 13.0926 10.0386C12.6909 10.0257 12.2908 10.0967 11.9161 10.2473C11.5414 10.3978 11.1996 10.6249 10.9112 10.9151C10.6227 11.2052 10.3934 11.5525 10.2369 11.9363C10.0804 12.3201 9.99984 12.7326 10 13.1494V14.7047C10.0006 15.3006 10.1661 15.8837 10.477 16.3848C10.7878 16.8859 11.2309 17.2838 11.7536 17.5314L11.6191 18.2303C11.5831 18.4172 11.4982 18.5902 11.3737 18.7303C11.2491 18.8704 11.0897 18.9722 10.913 19.0246L8.72551 19.6747C8.51652 19.7366 8.33266 19.8673 8.20175 20.0472C8.07084 20.2271 8.00002 20.4464 8 20.6717V22.4817C8 22.6192 8.05268 22.751 8.14645 22.8483C8.24022 22.9455 8.3674 23.0001 8.50001 23.0001H17.5001C17.6328 23.0001 17.7599 22.9455 17.8537 22.8483C17.9475 22.751 18.0002 22.6192 18.0002 22.4817V20.6717C18.0001 20.4464 17.9293 20.2271 17.7984 20.0472C17.6675 19.8673 17.4836 19.7366 17.2746 19.6747Z"
			fill="white"
		/>
	</Icon>
);
