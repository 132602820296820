import * as constants from "@/config/constants";
import { companyRankingReducer } from "@/store/companyRanking/reducer";
import { combineReducers } from "redux";

import companyQueryParamsReducer from "./companySpecificQueryParams/reducer";
import featuredCompanyReducer from "./featuredCompany/reducer";
import filteringReducer from "./filtering/reducer";
import geoTargetingReducer from "./geotargeting/reducer";
import mediaAlphaReducer from "./mediaAlpha/reducer";
import multistepModalReducer from "./multistepModal/reducer";
import paramReducer from "./routingParams/reducer";
import { variationsReducer } from "./variations/reducer";
import verticalInformationReducer from "./verticalinformation/reducer";

const appReducer = combineReducers({
	[constants.VERTICAL_INFORMATION]: verticalInformationReducer,
	[constants.GEO_TARGETING]: geoTargetingReducer,
	[constants.FILTERING]: filteringReducer,
	[constants.ROUTING_PARAMS]: paramReducer,
	[constants.MEDIA_ALPHA_FEED]: mediaAlphaReducer,
	[constants.MULTISTEP_MODAL]: multistepModalReducer,
	[constants.COMPANY_QUERY_PARAMS]: companyQueryParamsReducer,
	[constants.FEATURED_COMPANY]: featuredCompanyReducer,
	[constants.VARIATIONS]: variationsReducer,
	[constants.COMPANY_RANKING]: companyRankingReducer,
});

// Will Implement an action type below to destroy state. e.g. if (action.type === LOGOUT) {state = null}
// Because of the architecture and only relying on one react component, we are essentially creating
// a SPA withing the PHP blade. This also means that navigating back to the home blade will nullify
// all redux stores. Therefore, the below rootReducer does not need to look for a specific action to
// nullify store state, but we'll keep it around anyway in case we need it in the future.
export const rootReducer = (state, action) => appReducer(state, action);
