import { Icon } from "../../../../components/BaseComponents/Icon";

export const DiscountIcon = (props) => (
	<Icon fill="none" {...props}>
		<path
			d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
			fill="currentColor"
		/>
		<path
			d="M19 14.729c0 1.628-1.384 2.93-3.093 2.93-1.71 0-3.093-1.302-3.093-2.93 0-1.628 1.384-2.93 3.093-2.93 1.71 0 3.093 1.302 3.093 2.93zm-1.823 0c0-.667-.586-1.21-1.27-1.21-.7 0-1.27.543-1.27 1.21 0 .666.57 1.194 1.27 1.194.684 0 1.27-.528 1.27-1.194zM11.186 9.24c0 1.613-1.384 2.93-3.093 2.93C6.383 12.17 5 10.854 5 9.24c0-1.643 1.384-2.93 3.093-2.93 1.71 0 3.093 1.287 3.093 2.93zm-1.823 0c0-.666-.57-1.209-1.27-1.209-.7 0-1.27.543-1.27 1.21 0 .666.57 1.193 1.27 1.193.7 0 1.27-.527 1.27-1.194zm6.772-2.542L9.347 18l-1.45-.682L14.622 6l1.514.698z"
			fill="#fff"
		/>
	</Icon>
);
