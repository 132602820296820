// import helpers
import { numToCurrency } from "@/helpers";
// import selectors
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path, prop } from "ramda";
import { useSelector } from "react-redux";

// import components
import ServiceBlockCallOut from "../ServiceBlockCallOut";

export const LegacyCallOut = ({ company }) => {
	const callOutInformation = useSelector(
		selectors.getServiceBlockCallOutInformation,
	);
	const isRenderable = useSelector(selectors.getIsServiceBlockLegacyStyled);

	// exit early
	if (!isRenderable || !callOutInformation) return null;

	const field = prop("field", callOutInformation);
	const preFormatterConversions = prop(
		"preFormatterConversions",
		callOutInformation,
	);
	const isConvertedToCurrency = prop(
		"isConvertedToCurrency",
		preFormatterConversions,
	);
	const formatter = prop("formatter", callOutInformation);
	const contentfulFieldValue = path(["fields", field], company);
	const convertedFieldValue = isConvertedToCurrency
		? numToCurrency(contentfulFieldValue)
		: contentfulFieldValue;
	const callOut = formatter
		? formatter(convertedFieldValue)
		: convertedFieldValue;

	return <ServiceBlockCallOut>{callOut}</ServiceBlockCallOut>;
};

LegacyCallOut.propTypes = {
	company: PropTypes.object.isRequired,
};

export default LegacyCallOut;
