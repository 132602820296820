export const HOME_WARRANTY_PATH = "home-warranty";
export const AUTO_WARRANTY_PATH = "auto-warranty";

export const BW_SITE_CONFIGURATION = {
	[HOME_WARRANTY_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[AUTO_WARRANTY_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
};
