import { Icon } from "../../../../components/BaseComponents/Icon";

export const CheckIcon = (props) => (
	<Icon fill="none" viewBox="0 0 16 16" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.716 4.117l-.344-.335a.995.995 0 00-1.388 0L6.107 9.525 4.02 7.492a1.015 1.015 0 00-1.388 0l-.344.325a.938.938 0 000 1.352l3.12 3.049a.995.995 0 001.388 0l6.92-6.749a.947.947 0 00.02-1.331l-.02-.02z"
			fill="currentColor"
		/>
	</Icon>
);
