import { css } from "@emotion/react";
import PropTypes from "prop-types";
import tw from "twin.macro";

const styles = {
	down: css`
  ${tw`w-[160px] h-[53px] relative bg-white mx-auto mb-[20px] rounded-lg`}

      box-shadow: 0px 0px 24px rgba(88, 102, 126, 0.1), 0px 0px 1px rgba(88, 102, 126, 0.48);
      :after {
        top: 100%;
        right: calc(50% - 6px);
        content: '';
        position: absolute;
        pointer-events: none;
        width: 12px;
        transform: translate(0, -50%) rotate(45deg);
        height: 12px;
        background: white;
        box-shadow: 0px 0px 0px rgb(88 102 126 / 10%), 2px 2px 3px rgb(88 102 126 / 7%);
        overflow: hidden;
        }
      }
  `,
};

const SocialProof = ({ companyIndex }) =>
	companyIndex === 1 ? (
		<div css={styles.down}>
			<div
				css={tw`w-full h-full flex flex-row justify-center items-center relative bg-white mx-auto mb-[20px] rounded-lg`}
			>
				<img
					css={tw`max-w-[32px] ml-2`}
					src="/react-images/favy/socialPerson.svg"
					alt="person icon"
				/>
				<div
					css={tw`ml-2 mr-2 font-aktivGrotesk font-bold text-[11px] text-left text-[#22293A]`}
				>
					81% of our visitors chose this
				</div>
			</div>
		</div>
	) : null;

export default SocialProof;

SocialProof.propTypes = {
	companyIndex: PropTypes.number.isRequired,
};
