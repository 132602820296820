import {
	CURRENT_YEAR,
	FVY_DOMAIN,
	IR_DOMAIN,
	SITE,
	TCR_DOMAIN,
} from "@/config/constants";
import {
	ACCOUNTS_PAYABLE_PATH,
	BUSINESS_PASSWORD_MANAGERS_PATH,
	BUSINESS_VPN_PATH,
	GLOBAL_HR_PATH,
	MATTRESS_TOPPERS_PATH,
	MATTRESSES_PATH,
	MEAT_DELIVERY_SERVICES_PATH,
	MORTGAGE_PATH,
	PDF_EDITORS_PATH,
	PERSONAL_VPN_PATH,
	SOLAR_PATH,
	WINE_CLUBS_PATH,
} from "@/config/site/favy";
import * as tcrConstants from "@/config/site/thecreditreview";
import withOutboundPaths from "@/links/withOutboundPaths";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path, pathOr } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import { getVerticalConfig } from "../../../selectors";

const styles = {
	baseStyles: css`
		align-self: center;
		color: #1d8ce0;
		font-size: 14px;
		border-bottom: 1px dashed;
		margin-left: auto;
		margin-right: auto;
		display: table;
		font-weight: 600;
		margin-top: 10px;
		&:hover {
			text-decoration: none;
			outline: none;
			color: #1d8ce0;
		}

		@media (max-width: 991px) {
			margin-left: 0;
			margin-right: 0;
		}
	`,
	legacyStyles: css`
		@media (max-width: 991px) {
			margin-left: auto;
			margin-right: auto;
		}
	`,
	domainLevelOverrides: {
		[TCR_DOMAIN]: {
			[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: css`
				border-bottom: none;
			`,
		},
		[IR_DOMAIN]: {
			allVerticals: css`
				border-bottom: none;
			`,
		},
		[FVY_DOMAIN]: {
			software: css`
				margin-left: 1rem;
				@media (min-width: 540px) {
					margin-top: 0px;
				}
			`,
			allVerticals: css`
				border-bottom: none;
				align-items: center;
				height: 40px;
				width: 147px;

				@media (max-width: 991px) and (min-width: 581px) {
					margin-top: 0;
				}

				@media (max-width: 1199px) {
					width: 160px;
				}

				@media (max-width: 580px) {
					width: 75%;
					max-width: 210px;
				}

				${tw`flex justify-center text-green-700 transition-all hover:border-2 hover:border-solid bg-gray-100 hover:bg-white hover:border-green-600 leading-6 p-2 rounded-full hover:text-green-700`}
			`,
			[WINE_CLUBS_PATH]: tw`text-red-800 hover:border-red-900 hover:text-red-900`,
			[MEAT_DELIVERY_SERVICES_PATH]: css`
				width: 152px;
				${tw`text-red-600 hover:border-red-700 hover:text-red-800`}
			`,
			[MATTRESSES_PATH]: css`
				width: 170px;
				${tw`text-blue-700 hover:border-blue-800 hover:text-blue-800`}
			`,
			[MATTRESS_TOPPERS_PATH]: css`
				width: 170px;
				@media (max-width: 1199px) {
					width: 170px;
				}
				${tw`text-blue-700 hover:border-blue-800 hover:text-blue-800`}
			`,
			[MORTGAGE_PATH]: css`
				width: 170px;
			`,
			[SOLAR_PATH]: css`
				width: 175px;
				@media (max-width: 580px) {
					width: 175px;
				}
				${tw`text-orange-500 hover:border-orange-600 hover:text-orange-600`}
			`,
			[BUSINESS_VPN_PATH]: tw`!text-[#359492] hover:border-[#359492]`,
			[PERSONAL_VPN_PATH]: css`
				width: 165px;
			`,
			[BUSINESS_PASSWORD_MANAGERS_PATH]: css`
				width: 165px;
				${tw`text-blue-600 hover:border-blue-700 hover:text-blue-700`}
			`,
			[ACCOUNTS_PAYABLE_PATH]: tw`text-blue-500 hover:border-blue-600 hover:text-blue-600`,
			[GLOBAL_HR_PATH]: tw`text-red-500 hover:border-red-600 hover:text-red-600`,
			[PDF_EDITORS_PATH]: tw`text-[#359492] hover:text-[#359492] hover:border-[#359492] hover:cursor-pointer`,
		},
	},
};

export const ReviewLink = ({
	company,
	companyReviewPath,
	isCompanyReviewLinkable,
}) => {
	const isReviewLinkProhibited = useSelector(
		selectors.getIsReviewLinkProhibited,
	);
	const reviewLinkWithDateText = useSelector(
		selectors.getReviewLinkWithDateText,
	);
	const reviewLinkWithoutDateText = useSelector(
		selectors.getReviewLinkWithoutDateText,
	);
	const vertical = useSelector(selectors.getVertical);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const phoneNumber = useSelector((state) =>
		selectors.getCompanyPhoneNumber(state, { company }),
	);
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const usesNewSoftwareDesign = useSelector(selectors.getUsesNewSoftwareDesign);

	const verticalConfig = useSelector(getVerticalConfig);
	/* Boolean for determining what Contentful field to use for review link text */
	const isReviewLinkRenderedWithDate = pathOr(
		false,
		["isServiceRightReviewLinkRenderedWithDate"],
		verticalConfig,
	);

	const reviewText = isReviewLinkRenderedWithDate
		? reviewLinkWithDateText.replace("{year}", CURRENT_YEAR)
		: reviewLinkWithoutDateText;

	/* Only rendered for legacy if the company doesn't have a phone number associated*/
	const isRenderedForLegacyStyling = isServiceBlockLegacyStyled && !phoneNumber;

	/* Always rendered for revamped ServiceBlock styling unless disabled via config */
	const isRenderable = isReviewLinkProhibited
		? false
		: isRenderedForLegacyStyling || !isServiceBlockLegacyStyled;

	return isRenderable ? (
		<a
			css={[
				styles.baseStyles,
				path(["domainLevelOverrides", SITE, "allVerticals"], styles),
				usesSoftwareDesign &&
					path(["domainLevelOverrides", SITE, "software"], styles),
				path(["domainLevelOverrides", SITE, vertical], styles),
				isRenderedForLegacyStyling ? styles.legacyStyles : null,
				usesNewSoftwareDesign && tw`!w-[200px] !text-xl !mt-4 sm:!mt-0 sm:!m-0`,
			]}
			{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
			aria-label={companyName + " Review"}
		>
			{reviewText}
		</a>
	) : null;
};

export default withOutboundPaths(ReviewLink);

ReviewLink.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
};
