import { FVY_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import { css } from "twin.macro";

const styles = {
	expandBenefitsSpan: {
		revamped: css`
			display: none;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			justify-content: left;
			align-items: center;
			margin-top: 1.25rem;
			cursor: pointer;

			@media (max-width: 767px) {
				margin-top: 5px;
				font-size: 13px;
				margin-bottom: 0;
			}

			svg {
				path {
					fill: #787e81;
					fill-rule: evenodd;
				}

				width: 10px;
				margin-left: 5px;
			}

			@media (max-width: 767px) {
				padding-left: 25px;

				svg {
					width: 9px;
					margin-left: 8px;
				}
			}
		`,

		legacy: css`
			display: none;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			justify-content: left;
			align-items: center;
			margin-top: 1.25rem;
			cursor: pointer;

			svg {
				path {
					fill: #787e81;
					fill-rule: evenodd;
				}

				width: 10px;
				margin-left: 5px;
			}

			@media (max-width: 767px) {
				margin-bottom: 1.25rem;
				margin-top: 0;
				padding-left: 25px;
			}
		`,

		[FVY_DOMAIN]: css`
			@media (max-width: 767px) {
				margin-top: -20px;
				margin-bottom: 20px;
				color: #dc2726;
				padding-left: 30px;
			}
		`,

		[TCR_DOMAIN]: css`
			color: #48484b;
		`,

		usesSoftwareDesign: css`
			@media (max-width: 767px) {
				margin-top: 10px;
				margin-bottom: 5px;
			}
		`,
	},
};

const ExpandMobileServiceBenefits = ({
	expandServiceContent,
	serviceContentSelectorStyle,
}) => {
	const expandServiceBenefitsText = useSelector(
		selectors.getExpandServiceBenefitsText,
	);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);

	return (
		<span
			css={[
				isServiceBlockLegacyStyled
					? styles.expandBenefitsSpan.legacy
					: styles.expandBenefitsSpan.revamped,
				css`
					display: ${serviceContentSelectorStyle};
				`,
				path(["expandBenefitsSpan", SITE], styles),
				usesSoftwareDesign && styles.expandBenefitsSpan.usesSoftwareDesign,
			]}
			onClick={expandServiceContent}
		>
			{expandServiceBenefitsText}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="7"
				viewBox="0 0 12 7"
				focusable="false"
			>
				<path
					d="M938.388,129.719l-0.6-.6a0.378,0.378,0,0,0-.554,0l-4.732,4.732-4.732-4.732a0.378,0.378,0,0,0-.554,0l-0.6.6a0.38,0.38,0,0,0,0,.554l5.611,5.611a0.38,0.38,0,0,0,.554,0l5.611-5.611A0.38,0.38,0,0,0,938.388,129.719Z"
					transform="translate(-926.5 -129)"
				/>
			</svg>
		</span>
	);
};

export default ExpandMobileServiceBenefits;

ExpandMobileServiceBenefits.propTypes = {
	expandServiceContent: PropTypes.func.isRequired,
	serviceContentSelectorStyle: PropTypes.string.isRequired,
};
