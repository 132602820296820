import OutboundLink from "@/components/general/OutboundLink";
import { ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT } from "@/config/constants";
import withOutboundPaths from "@/links/withOutboundPaths";
import {
	getCompanyName,
	getIsTargetedPath,
	getIsTrustpilotWidgetRendered,
	getMainBenefits,
	getPreventRenderingSoftwareColumns,
	getUsesSoftwareDesign,
} from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import Coupon from "../Coupon";
import ProductDescription from "../ProductDescription";
import ReviewWidgetSection from "../ServiceLeft/ReviewWidgetSection";
import ColumnLayout from "./ColumnLayout";
import CompanyBenefitsLinkWrapper from "./CompanyBenefitsLinkWrapper";

const styles = {
	listItem: css`
		padding-left: 20px;
		background-image: url("/react-images/common/favyCheck.svg");
		background-position: left top 11px;
		min-width: 360px;

		@media (max-width: 991px) {
			min-width: 260px;
			max-width: 275px;
		}

		@media (max-width: 767px) {
			min-width: 200px;
			max-width: 225px;
		}

		@media (max-width: 640px) {
			min-width: unset;
			max-width: unset;
		}
		${tw`text-sm leading-6 text-gray-900 flex-1 lg:text-base lg:leading-7 bg-no-repeat`},
	`,
};

const CompanyHeading = ({
	companyName,
	company,
	companyVisitPath,
	isTargetedPath,
}) => {
	const TextualElement = ({ companyName }) => (
		<p className="tw-pb-2 tw-text-[1.6rem] tw-font-bold tw-leading-6 tw-tracking-tight tw-text-gray-900 lg:tw-pb-3">
			{companyName}
		</p>
	);
	return isTargetedPath ? (
		<OutboundLink to={companyVisitPath} company={company}>
			<TextualElement companyName={companyName} />
		</OutboundLink>
	) : (
		<TextualElement companyName={companyName} />
	);
};
CompanyHeading.propTypes = {
	company: PropTypes.object.isRequired,
	companyName: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	isTargetedPath: PropTypes.bool.isRequired,
};

export const SoftwareLayout = ({
	company,
	companyIndex,
	renderAllBenefits,
	companyVisitPath,
}) => {
	const isTargetedPath = useSelector(getIsTargetedPath);
	const usesSoftwareDesign = useSelector(getUsesSoftwareDesign);
	const preventRenderingSoftwareColumns = useSelector(
		getPreventRenderingSoftwareColumns,
	);
	const isTrustpilotWidgetRendered = useSelector(getIsTrustpilotWidgetRendered);
	const companyName = useSelector((state) =>
		getCompanyName(state, { company }),
	);
	const companyBenefits = useSelector((state) =>
		getMainBenefits(state, { company }),
	).map((benefit, idx) => (
		<li css={styles.listItem} key={idx}>
			{benefit}
		</li>
	));
	// eslint-disable-next-line no-magic-numbers
	const halfLength = Math.ceil(companyBenefits.length / 2);
	const firstHalfBenefits = companyBenefits.slice(0, halfLength);
	const secondHalfBenefits = companyBenefits.slice(halfLength);

	return usesSoftwareDesign ? (
		<div css={tw`w-full`}>
			<CompanyHeading
				company={company}
				companyName={companyName}
				isTargetedPath={isTargetedPath}
				companyVisitPath={companyVisitPath}
			/>
			<Coupon company={company} companyIndex={companyIndex} />
			{!isTrustpilotWidgetRendered && <ReviewWidgetSection company={company} />}
			<div css={tw`hidden sm:flex`}>
				<ProductDescription company={company} />
			</div>
			{!preventRenderingSoftwareColumns && (
				<ColumnLayout company={company} companyIndex={companyIndex} />
			)}
			<div css={tw`sm:hidden`}>
				<ProductDescription company={company} />
			</div>
			<div css={tw`sm:hidden`}>
				<CompanyBenefitsLinkWrapper
					company={company}
					companyIndex={companyIndex}
				>
					<ul>
						{renderAllBenefits || companyIndex === 1
							? companyBenefits
							: companyBenefits.slice(
									0,
									ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT,
								)}
					</ul>
				</CompanyBenefitsLinkWrapper>
			</div>
			<div css={tw`hidden sm:flex w-full`}>
				<CompanyBenefitsLinkWrapper
					company={company}
					companyIndex={companyIndex}
				>
					<div css={tw`flex justify-between`}>
						<ul css={tw`w-full mr-4 sm:mr-8 md:mr-4`}>{firstHalfBenefits}</ul>
						<ul css={tw`w-full`}>{secondHalfBenefits}</ul>
					</div>
				</CompanyBenefitsLinkWrapper>
			</div>
		</div>
	) : null;
};

SoftwareLayout.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string,
	companyVisitPath: PropTypes.string,
	renderAllBenefits: PropTypes.bool.isRequired,
};

export default withOutboundPaths(SoftwareLayout);
