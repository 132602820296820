import { CacheProvider } from "@emotion/react";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";

import { emotionCache } from "../../helpers/emotionCache";
import { errorHandler } from "../../helpers/errorHandler";
import { useSyncDerivedRoutingParamsToStore } from "../../store/routingParams/actions";
import { FallbackComponent } from "../general/FallbackComponent";
import { RoutesProvider } from "./RoutesProvider";

export const AppProviders = ({ children }) => {
	// Event watcher on history object to sync derived routing params on history changes
	useSyncDerivedRoutingParamsToStore();

	return (
		<ErrorBoundary onError={errorHandler} FallbackComponent={FallbackComponent}>
			<CacheProvider value={emotionCache}>
				<RoutesProvider>{children}</RoutesProvider>
			</CacheProvider>
		</ErrorBoundary>
	);
};

AppProviders.propTypes = {
	children: PropTypes.node.isRequired,
};
