import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseCompanyName = ({ style, className }) => {
	const { company } = useContext(CompanyContext);
	const companyName = company.fields.companyName;
	if (!companyName) return null;

	return (
		<div css={style?.text} className={className}>
			{companyName}
		</div>
	);
};

export default BaseCompanyName;

BaseCompanyName.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
};
