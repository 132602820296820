import { useCallback, useEffect, useState } from "react";
import { propOr } from "ramda";
import useSSR from "use-ssr";

const { isServer } = useSSR();
const desktop = 1200;
const widths = {
	mobile: 580,
	tablet: 768,
	desktop,
};

export const useInnerWidth = (deviceType) => {
	const [innerWidth, setInnerWidth] = useState(
		isServer ? propOr(desktop, deviceType, widths) : window.innerWidth,
	);

	const updateInnerWidth = useCallback(() => {
		setInnerWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", updateInnerWidth, { passive: true });

		return () => {
			window.removeEventListener("resize", updateInnerWidth);
		};
	}, []);

	return innerWidth;
};
