import { css } from "@emotion/react";
import PropTypes from "prop-types";

const styles = {
	filterButtons: css`
		display: none;

		@media (max-width: 640px) {
			display: flex;
			flex-direction: row-reverse;
			margin-top: 15px;

			button {
				width: 30%;
				height: 40px;
				padding: 7px 7px;
				text-transform: none;
				font-size: 13px;
			}

			button:focus,
			button:hover {
				outline: none;
				color: #1774ff;
			}
		}
	`,
	clearFiltersButton: css`
		background-color: white;
		color: #111;
	`,
	applyFiltersButton: css`
		background-color: #1774ff;
		color: white;
	`,
};

const FilterButtons = ({
	clearMobileFilters,
	applyFilters,
	isMobile,
	text,
	disabled,
}) => {
	const applyClass = isMobile
		? "filterButtons-applyBtn_mobile"
		: "filterButtons-applyBtn_desktop";

	return (
		<div css={styles.filterButtons}>
			<button
				css={styles.applyFiltersButton}
				type="button"
				className={`btn filterButtons-applyBtn ${applyClass}`}
				onTouchStart={() => {}}
				onClick={applyFilters}
				disabled={disabled}
			>
				{text}
			</button>
			<button
				css={styles.clearFiltersButton}
				className="btn"
				onClick={clearMobileFilters}
			>
				Clear All
			</button>
		</div>
	);
};

export default FilterButtons;

FilterButtons.propTypes = {
	clearMobileFilters: PropTypes.func.isRequired,
	applyFilters: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	isMobile: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
};
