import { Component } from "react";
import { AGE_THRESHOLD, IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import {
	FILTERING_AGE_VALUE,
	IR_LIFE_INSURANCE_DEFAULT_PATH,
} from "@/config/site/insuranceranked";
import * as selectors from "@/selectors";
import { setModalFilteringState } from "@/store/filtering/actions";
import PropTypes from "prop-types";
import { path } from "ramda";
import { default as ReactModal } from "react-modal2";
import { connect } from "react-redux";
import tw, { css } from "twin.macro";

const styles = {
	filteringModalWrapper: css`
		width: 100%;
		position: absolute;
		bottom: 0px;
		:focus {
			outline: none;
		}
	`,

	modalContent: css`
		background-color: #fff;
		background-clip: padding-box;
		border-radius: 10px 10px 0px 0px;
	`,

	modalContentWrapper: css`
		position: fixed;
		width: 92%;
		left: 50%;
		bottom: 0;
		transform: translate(-50%);
	`,

	modalImage: css`
		width: 45px;
	`,

	modalHeader: css`
		text-align: center;
		min-height: 30px;
		padding: 30px 30px 10px 30px;

		h2 {
			font-size: 1.5rem;
			margin-top: 5px;
			margin-bottom: 5px;
		}
	`,

	verticalIdentifier: {
		base: css`
			font-size: 13px;
			font-weight: 600;
			letter-spacing: 1px;
			color: #57a040;
			font-family: Montserrat;
			margin-top: 5px;
			margin-bottom: 5px;
			text-transform: uppercase;
		`,
		[IR_DOMAIN]: css`
			font-family: sans-serif;
		`,
	},

	stepCounter: css`
		position: absolute;
		top: 15px;
		left: 15px;
		font-weight: 600;
		font-size: 13px;
		margin-bottom: 0px;
		margin-top: 0px;
	`,

	closeModalButton: css`
    opacity: 1;
    margin-top: -2px;

    span {
      position: absolute;
      top: 15px;
      right: 15px;
      background-image: url("/react-images/common/mobileModalCloseIcon.svg");
      background-size: contain;
      background-position: left;
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
  `,

	modalBody: css`
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 10px;
		position: relative;

		button {
			${tw`bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow`}
			margin-bottom: .75rem;
			min-width: 225px;
			background-position: center;
			transition: background 0.6s;
		}

		button:hover {
			background: #6ac54e radial-gradient(circle, transparent 1%, #6ac54e 1%)
				center/15000%;
			color: white;
			outline: none;
		}

		button:active {
			background-color: #96e87d;
			background-size: 100%;
			transition: background 0s;
			outline: none;
		}
	`,

	floatingDiv: {
		wrapper: css`
			width: 100%;
			position: relative;
			bottom: 6px;
		`,

		element: css`
			height: 5px;
			max-width: 100px;
			margin: 0 auto;
			border-radius: 5px;
			background-color: #ffffff;
		`,
	},
};

const msDelay = 600;

const mapDispatchToProps = {
	setModalFilteringState,
};

const mapStateToProps = (state) => ({
	isTransitionApplied: selectors.getIsTransitionApplied(state),
	vertical: selectors.getVertical(state),
	isMobileModalUrlParamMatched:
		selectors.getIsMobileModalUrlParamMatched(state),
	mobileFilteringModalProperties:
		selectors.getMobileFilteringModalProperties(state),
	categoryName: selectors.getCategoryName(state),
});

export class MobileFilteringModal extends Component {
	state = {
		hasModalBeenClosed: false,
	};

	applyModalFiltering = (key, val) => {
		if (
			this.props.vertical === IR_LIFE_INSURANCE_DEFAULT_PATH &&
			SITE === IR_DOMAIN
		) {
			if (val) {
				this.props.setModalFilteringState(
					{ [key]: val, [AGE_THRESHOLD]: FILTERING_AGE_VALUE },
					this.props.vertical,
				);
			} else
				this.props.setModalFilteringState({ [key]: val }, this.props.vertical);
		} else
			this.props.setModalFilteringState({ [key]: val }, this.props.vertical);

		setTimeout(() => this.closeModal(), msDelay);
	};

	closeModal = () => {
		this.setState({ hasModalBeenClosed: true });
	};

	render() {
		const {
			isMobileModalUrlParamMatched,
			mobileFilteringModalProperties,
			categoryName,
			vertical,
		} = this.props;
		const { hasModalBeenClosed } = this.state;

		const iconMap = {
			[TCR_DOMAIN]: {
				["auto-refinance"]: "/react-images/thecreditreview/loanIcon.svg",
			},
			[IR_DOMAIN]: {
				["life-insurance"]: "/react-images/insuranceranked/toolTip.svg",
			},
		};

		if (!mobileFilteringModalProperties) return null;

		const buttons = mobileFilteringModalProperties.buttons.map((btn, idx) => (
			<button
				onClick={(e) =>
					this.applyModalFiltering(btn.filteringKey, btn.value, e)
				}
				key={idx}
			>
				{btn.label}
			</button>
		));
		const isOpen = !hasModalBeenClosed && isMobileModalUrlParamMatched;
		const modalImage = path([SITE, vertical], iconMap);
		const imageElement = modalImage ? (
			<img
				alt={`${categoryName} icon`}
				css={styles.modalImage}
				src={modalImage}
			/>
		) : null;

		return isOpen ? (
			<ReactModal
				css={styles.filteringModalWrapper}
				onClose={this.closeModal}
				closeOnBackdropClick={true}
				backdropStyles={{
					backgroundColor: "rgba(140,142,148,.3)",
					opacity: 1,
					transition: "opacity .15s ease-out",
					zIndex: 100,
					position: "fixed",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
				}}
				closeOnEsc={true}
			>
				<div css={styles.modalContentWrapper}>
					<div css={styles.floatingDiv.wrapper}>
						<div css={styles.floatingDiv.element}></div>
					</div>
					<div css={styles.modalContent} id="mobileFilteringModal">
						<div css={styles.modalHeader}>
							<p css={styles.stepCounter}>Step 1 of 1</p>
							{imageElement}
							<p
								css={[
									styles.verticalIdentifier.base,
									path(["verticalIdentifier", SITE], styles),
								]}
							>
								{categoryName}
							</p>
							<button
								css={styles.closeModalButton}
								onClick={this.closeModal}
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true"></span>
							</button>
							<h2>{mobileFilteringModalProperties.modalText}</h2>
						</div>
						<div css={styles.modalBody}>{buttons}</div>
					</div>
				</div>
			</ReactModal>
		) : null;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MobileFilteringModal);

MobileFilteringModal.propTypes = {
	appElement: PropTypes.string.isRequired,
	vertical: PropTypes.string.isRequired,
	isMobileModalUrlParamMatched: PropTypes.bool.isRequired,
	mobileFilteringModalProperties: PropTypes.object,
	setModalFilteringState: PropTypes.func.isRequired,
	categoryName: PropTypes.string,
};
