export const PROJECT_MANAGEMENT = "project-management";
export const HOME_SECURITY_SYSTEMS_PATH = "home-security-systems";
export const ACCOUNTING_SOFTWARE = "accounting-software";
export const PASSWORD_MANAGERS = "password-managers";
export const ANTIVIRUS_SOFTWARE = "antivirus-software";
export const CRM = "crm";
export const EMAIL_MARKETING = "email-marketing";
export const PAYROLL_SERVICES = "payroll-services";
export const AI_VIDEO_GENERATORS = "ai-video";

export const FTS_SITE_CONFIGURATION = {
	[PROJECT_MANAGEMENT]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[HOME_SECURITY_SYSTEMS_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[ACCOUNTING_SOFTWARE]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[PASSWORD_MANAGERS]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[ANTIVIRUS_SOFTWARE]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[CRM]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[EMAIL_MARKETING]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[PAYROLL_SERVICES]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[AI_VIDEO_GENERATORS]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
};
