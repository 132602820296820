import withOutboundPaths from "@/links/withOutboundPaths";
import { getPromotionText } from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import OutboundLink from "./OutboundLink";

const Promotion = ({ company, companyIndex, companyVisitPath }) => {
	const promotionText = useSelector((state) =>
		getPromotionText(state, { company }),
	);
	if (promotionText === null) {
		return null;
	}

	return (
		<OutboundLink company={company} to={companyVisitPath}>
			<div
				css={css`
					${tw`lg:w-[150px] lg:h-[108px] relative max-w-[296px] h-[56px] mt-4 lg:mt-0`}

					:before {
						position: absolute;
						display: unset;
						content: "";
						background-image: url("/react-images/common/purpleScissors.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						top: calc(100% - 12px);
						right: 10px;
					}

					@media (min-width: 1023px) {
						:hover {
							:before {
								animation-name: scissors;
								animation-duration: 5s;
								transform: translate(0, 0) rotate(5deg);
								animation-timing-function: linear;
								animation-iteration-count: infinite;
							}

							@keyframes scissors {
								0% {
									right: 10px;
									top: calc(100% - 12px);
								}
								50% {
									right: calc(100% - 30px);
									top: calc(100% - 12px);
									transform: translate(0, 0) rotate(0deg);
								}
								70% {
									right: calc(100% - 20px);
									top: calc(100% - 12px);
									transform: translate(0, 0) rotate(47deg);
								}
								80% {
									right: calc(100% - 14px);
									top: calc(100% - 12px);
									transform: translate(0, 0) rotate(95deg);
								}
								100% {
									right: calc(100% - 14px);
									top: 30px;
									transform: translate(0, 0) rotate(95deg);
								}
							}
						}
					}
				`}
			>
				<div
					css={tw`flex flex-row lg:flex-col lg:w-[150px] lg:h-[108px] max-w-[296px] h-[56px] bg-[#f4f4f9] rounded-lg`}
				>
					<div
						css={tw`lg:w-full h-full lg:h-[24px] flex items-center justify-center content-center rounded-bl-lg rounded-tl-lg lg:rounded-tr-lg lg:rounded-bl-none bg-[#6366f1]`}
					>
						<div
							css={tw`font-bold text-xs text-white ml-[10px] lg:ml-0 font-aktivGrotesk`}
						>
							Special Offer
						</div>
					</div>
					<div
						css={tw`h-full w-full flex items-center justify-center border-l-0 rounded-bl-none rounded-tr-lg lg:rounded-bl-lg rounded-br-lg border-dashed border-[#6366f1] border-t border-r border-b lg:border-l lg:rounded-tr-none lg:border-t-0`}
					>
						<div
							css={tw`w-[141px] text-sm text-center font-bold text-center font-aktivGrotesk lg:w-[126px] text-[#6366f1]`}
						>
							{promotionText}
						</div>
					</div>
				</div>
			</div>
		</OutboundLink>
	);
};

export default withOutboundPaths(Promotion);

Promotion.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
};
