import { Icon } from "../../../../components/BaseComponents/Icon";

export const GiftBoxIcon = (props) => (
	<Icon viewBox="0 0 26 26" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M24.25 6.4h-4.17a2.86 2.86 0 0 0-.46-3.54A3.03 3.03 0 0 0 17.5 2c-2.35 0-3.75 1.75-4.5 3.17C12.25 3.75 10.85 2 8.5 2c-.8 0-1.56.3-2.12.86a2.85 2.85 0 0 0-.46 3.54H1.75c-.2 0-.39.08-.53.21a.73.73 0 0 0-.22.52v2.94c0 .2.08.38.22.52.14.13.33.21.53.21h22.5c.2 0 .39-.08.53-.21a.72.72 0 0 0 .22-.52V7.13c0-.2-.08-.38-.22-.52a.76.76 0 0 0-.53-.21ZM17.5 3.47c.4 0 .78.15 1.06.43a1.45 1.45 0 0 1 0 2.07c-.28.28-.66.43-1.06.43h-3.44c.5-1.14 1.58-2.93 3.44-2.93ZM7 4.93a1.49 1.49 0 0 1 1.5-1.46c1.85 0 2.94 1.79 3.44 2.93H8.5c-.4 0-.78-.15-1.06-.43A1.45 1.45 0 0 1 7 4.93ZM14.5 24h5.25a2.98 2.98 0 0 0 3-2.93v-8.8H14.5V24Zm-10.37-.86a2.9 2.9 0 0 1-.88-2.07v-8.8h8.25V24H6.25c-.8 0-1.56-.3-2.12-.86Z"
			clipRule="evenodd"
		/>
	</Icon>
);

export const GiftBoxIconSecondary = (props) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M7.55 3.82c0-.45.37-.82.82-.82.85 0 1.5.56 1.99 1.35l.17.29h-2.2a.82.82 0 0 1-.78-.82Zm-1.88.82A2.82 2.82 0 0 1 8.37 1c1.82 0 2.98 1.2 3.63 2.2.65-1 1.81-2.2 3.64-2.2a2.82 2.82 0 0 1 2.7 3.64H22a1 1 0 0 1 1 1v3.63a1 1 0 0 1-1 1h-.82V20a3 3 0 0 1-3 3H5.82a3 3 0 0 1-3-3v-9.73H2a1 1 0 0 1-1-1V5.64a1 1 0 0 1 1-1h3.67Zm2.64 2H3v1.63h18V6.64H8.31Zm-3.5 3.63V20a1 1 0 0 0 1 1h12.37a1 1 0 0 0 1-1v-9.73H4.82Zm10.86-5.63a.82.82 0 0 0 .78-.82.82.82 0 0 0-.81-.82c-.86 0-1.5.56-2 1.35l-.16.29h2.19Z"
			clipRule="evenodd"
		/>
	</Icon>
);
