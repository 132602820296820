import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseCoupon = (props) => {
	const { company } = useContext(CompanyContext);

	const couponDescriptionText = company?.fields?.couponDescription;
	if (!couponDescriptionText) return null;

	return (
		<div className={props?.wrapper}>
			<div className={props?.contentWrapper}>
				{props?.icon && (
					<span className={props?.contentIcon}>{props.icon}</span>
				)}
				<p className={props?.content}>{couponDescriptionText}</p>
			</div>
		</div>
	);
};

BaseCoupon.propTypes = {
	icon: PropTypes.element,
	wrapper: PropTypes.string,
	contentWrapper: PropTypes.string,
	contentIcon: PropTypes.string,
	content: PropTypes.string,
};
