import { useContext } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";

import { GiftBoxIcon } from "../../../client/assets/icons/common/GiftBoxIcon";
import { BaseAmazonDiscount } from "../../BaseComponents/BaseAmazonDiscount";
import { BaseAmazonLabel } from "../../BaseComponents/BaseAmazonLabel";
import { BaseBenefitsList } from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseCoupon } from "../../BaseComponents/BaseCoupon";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../BaseComponents/BaseProductDescription";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import getStyles from "./styles";

export const AmazonProduct = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const styles = getStyles({ isFeatured: isFeaturedCompany, companyIndex });

	return (
		<BaseLinkWrapper css={styles.layoutWrapper}>
			<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
			<BaseCoupon {...styles.coupon} icon={<GiftBoxIcon />} />

			<div css={styles.rootGrid}>
				{/* Column 1 */}
				<div css={styles.col1}>
					<BaseServiceFlag style={styles.serviceFlag} />
					<div css={styles.logoWrapper}>
						<BaseCompanyLogo style={styles.companyLogo} />
					</div>
				</div>

				{/* Column 2 */}
				<div css={styles.col2}>
					<div>
						<BaseProductDescription style={styles.productDescription} />
					</div>
					<div>
						<BaseGridRatingHorizontal
							style={styles.rating}
							starSize={"small"}
						/>
					</div>
					<div>
						<BaseBenefitsList {...styles.benefitsList} />
					</div>
				</div>

				{/* Column 3 */}
				<div css={styles.col3}>
					<div>
						<BaseAmazonDiscount {...styles.discount} />
						<BaseVisitLink {...styles.visitLink}>Check Price</BaseVisitLink>
						<BaseAmazonLabel {...styles.amazonLabel} />
					</div>
				</div>
			</div>
		</BaseLinkWrapper>
	);
};
