import PropTypes from "prop-types";
import { TextField } from "react-aria-components";
import { Controller, useFormContext } from "react-hook-form";

export const AriaFormController = ({
	Component = TextField,
	children,
	name,
	rules,
	...rest
}) => {
	const { control } = useFormContext();

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field }) => (
				<Component name={name} isRequired {...field} {...rest}>
					{children}
				</Component>
			)}
		/>
	);
};

AriaFormController.propTypes = {
	Component: PropTypes.any,
	children: PropTypes.any.isRequired,
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
};
