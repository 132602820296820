// import constants
import { SITE, TCR_DOMAIN } from "@/config/constants";
// import selectors
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import { css } from "twin.macro";

import FilterButtons from "./FilterButtons";

const styles = {
	mobileFilters: css`
		transition: all 0.25s ease-in-out 0s;
		display: flex;
		justify-content: left;
		margin: 4px 0 15px 0;

		@media (max-width: 640px) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			margin-bottom: 0;
		}
	`,
	containerBlock: {
		baseStyles: css`
			border-radius: 10px 10px 0 0;
			background-color: #fff;
			z-index: 2;

			div {
				text-align: center;
			}

			p {
				font-size: 16px;
				color: #111111;
				font-weight: 600;
				margin: 10px 0;
				display: inline-flex;
				align-items: center;
				justify-content: center;
			}

			button:focus,
			button:hover,
			button:active:focus {
				outline: none;
				color: #1774ff;
			}
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				allVerticals: css`
					padding: 10px;
				`,
			},
		},
	},
	containerContents: css`
		div:last-child {
			margin-bottom: 0;
		}
	`,
	grayOut: css`
		background-color: gray;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		opacity: 0.3;
		height: calc(100vh - 50px);
		z-index: 1;
	`,
	clearFiltersButton: css`
		padding: 12px 0;
		text-transform: none;
		font-size: 13px;
		color: #1774ff;
		background-color: white;
		position: absolute;
		right: 10px;
	`,
	filterCount: css`
		background-color: #ff5161;
		color: white;
		font-size: 12px;
		border-radius: 50%;
		margin-top: -4px;
		margin-left: 6px;
		padding: 3px 7px 0px 7px;
	`,
};

const MobileFilters = ({
	filterCount,
	clearMobileFilters,
	toggleMobileFilters,
	applyFilters,
	disabled,
	children,
}) => {
	const vertical = useSelector(selectors.getVertical);
	const count =
		filterCount > 0 ? (
			<span css={styles.filterCount}>{filterCount}</span>
		) : null;

	return (
		<div css={styles.mobileFilters}>
			<div css={styles.grayOut} onClick={toggleMobileFilters}></div>
			<div
				css={[
					styles.containerBlock.baseStyles,
					path(
						["containerBlock", "domainLevelOverrides", SITE, "allVerticals"],
						styles,
					),
					path(
						["containerBlock", "domainLevelOverrides", SITE, vertical],
						styles,
					),
				]}
				className="col-xs-12 col-md-12 no-padding-right-sm no-padding-left-sm"
			>
				<div>
					<p>
						Filter Results
						{count}
					</p>
				</div>
				<div css={styles.containerContents}>{children}</div>
				<FilterButtons
					clearMobileFilters={clearMobileFilters}
					applyFilters={applyFilters}
					disabled={disabled}
					isMobile={true}
					text={"Apply Filters"}
				/>
			</div>
		</div>
	);
};

export default MobileFilters;

MobileFilters.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	applyFilters: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	toggleMobileFilters: PropTypes.func.isRequired,
	clearMobileFilters: PropTypes.func.isRequired,
	filterCount: PropTypes.number.isRequired,
};
