import { useContext, useRef } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import { CheckIcon } from "../../../client/assets/icons/common/CheckIcon";
import {
	GiftBoxIcon,
	GiftBoxIconSecondary,
} from "../../../client/assets/icons/common/GiftBoxIcon";
import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import { ServiceFlagTrophyIcon } from "../../../client/assets/icons/favy/ServiceFlagTrophyIcon";
import { BaseBenefitsList } from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../BaseComponents/BaseProductDescription";
import { BasePromotionText } from "../../BaseComponents/BasePromotionText";
import BaseReviewLink from "../../BaseComponents/BaseReviewLink";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import { getStyles } from "./styles";

export const IncorporationServicesCompany = () => {
	const { company, companyIndex, isFeaturedCompany } =
		useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeatured: isFeaturedCompany, companyIndex });
	const nodeRef = useRef(null);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<BaseLinkWrapper
				className={`tw-mb-6 tw-block tw-rounded-lg tw-bg-white tw-shadow-[0px_4px_24px_0px_rgba(88,102,126,0.06),0px_1px_2px_0px_rgba(88,102,126,0.12)] tw-transition hover:tw-shadow-[0px_8px_32px_0px_rgba(0,0,0,0.08),0px_2px_20px_0px_rgba(0,0,0,0.04)] ${
					isFeaturedCompany ? "tw-ring-1 tw-ring-[#359492]" : ""
				}`}
			>
				<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />

				<div className="tw-relative">
					<BaseServiceFlag
						classNames={styles.serviceFlag}
						icon={
							<ServiceFlagTrophyIcon className="tw-h-3 tw-w-3.5 lg:tw-size-4" />
						}
					/>

					<div className="tw-mx-auto tw-flex tw-max-w-[400px] tw-flex-col tw-gap-4 tw-p-4 tw-pb-4 tw-pt-14 lg:tw-max-w-none lg:tw-flex-row lg:tw-gap-0 lg:tw-pl-16 lg:tw-pt-4 xl:tw-py-6 xl:tw-pr-6">
						<div className="tw-flex tw-shrink-0 tw-justify-center lg:tw-w-[204px] lg:tw-pr-3 xl:tw-w-[260px]">
							<div className="tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-4 lg:tw-flex-col">
								<BaseCompanyLogo classNames={styles.companyLogo} />
								<BaseGridRatingHorizontal classNames={styles.rating} />
							</div>
						</div>

						<div className="tw-flex tw-grow tw-flex-col lg:tw-max-w-none lg:tw-px-3">
							<div className="tw-flex tw-w-full tw-flex-col">
								<BaseProductDescription className={styles.productDescription} />
								<BaseBenefitsList
									{...styles.benefitsList}
									icon={<CheckIcon className="tw-text-base sm:tw-mt-1" />}
								/>
							</div>
						</div>

						<div className="tw-flex tw-flex-col tw-justify-center lg:tw-max-w-none lg:tw-px-3">
							<div className="tw-flex tw-h-[132px] tw-w-full tw-flex-col lg:tw-h-[160px] lg:tw-w-[160px]">
								<BasePromotionText
									{...styles.coupon}
									icon={
										companyIndex === 1 ? (
											<GiftBoxIcon />
										) : (
											<GiftBoxIconSecondary />
										)
									}
									emphasisText={companyIndex === 1 ? "35% Off" : null}
								>
									promotionText
								</BasePromotionText>
							</div>
						</div>

						<div className="tw-flex tw-shrink-0 lg:tw-w-[180px] lg:tw-pl-3 xl:tw-w-[212px]">
							<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center lg:tw-flex-col-reverse">
								{companyIndex === 1 ? (
									<BaseSocialProof {...styles.socialProof} />
								) : null}
								<BaseVisitLink {...styles.visitLink} icon={<RightArrowIcon />}>
									{`Visit ${company.fields.companyName}`}
								</BaseVisitLink>
								<BaseReviewLink
									style={styles.reviewLink}
									linkText={"Read Review"}
								/>
							</div>
						</div>
					</div>
				</div>
			</BaseLinkWrapper>
		</Transition>
	);
};
