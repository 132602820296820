import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

const BaseProductDescription = ({ style, className }) => {
	const { company } = useContext(CompanyContext);
	const productDescription = company.fields.productDescription;
	if (!productDescription) return null;

	return (
		<div css={style?.text} className={className}>
			{productDescription}
		</div>
	);
};

export default BaseProductDescription;

BaseProductDescription.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
};
