import { forwardRef } from "react";

/**
 * The same as `React.forwardRef` but passes the `ref` as a prop and returns a
 * component with the same generic type.
 */
export const forwardRefComponent = (render) => {
	const Component = forwardRef((props, ref) => render({ ...props, ref }));
	Component.displayName = render.displayName || render.name;

	return Component;
};
