// import components
import OutboundLink from "@/components/general/OutboundLink";
import withOutboundPaths from "@/links/withOutboundPaths";
// import selectors
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const styles = {
	serviceLeftLink: css`
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		text-align: center;
		position: relative;
		align-content: center;
		align-items: center;

		@media (max-width: 767px) {
			position: static;
			transform: none;
		}
	`,
};

export const ServiceLeftLink = ({
	company,
	companyIndex,
	companyVisitPath,
	companyReviewPath,
	isCompanyReviewLinkable,
	children,
	isAllLinkingProhibited,
}) => {
	const isTargetedPath = useSelector(selectors.getIsTargetedPath);
	const isPhoneLinkUsedOnTargeted = useSelector((state) =>
		selectors.getUsePhoneLinkInsteadOfVisitLink(state, { company }),
	);
	const phoneNumberHref = useSelector((state) =>
		selectors.getCompanyPhoneNumberHref(state, { company }),
	);

	const targetedVisitLink = isAllLinkingProhibited ? (
		<a css={styles.serviceLeftLink}>{children}</a>
	) : (
		<OutboundLink
			css={styles.serviceLeftLink}
			to={companyVisitPath}
			company={company}
		>
			{children}
		</OutboundLink>
	);

	const targetedPhoneLink = isAllLinkingProhibited ? (
		<a css={styles.serviceLeftLink}>{children}</a>
	) : (
		<OutboundLink
			css={styles.serviceLeftLink}
			to={phoneNumberHref}
			company={company}
			openInNewTab={false}
		>
			{children}
		</OutboundLink>
	);

	const targetedLink = isPhoneLinkUsedOnTargeted
		? targetedPhoneLink
		: targetedVisitLink;
	const nonTargetedLink = (
		<a
			{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
			css={styles.serviceLeftLink}
		>
			{children}
		</a>
	);

	return isTargetedPath ? targetedLink : nonTargetedLink;
};

ServiceLeftLink.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
	isAllLinkingProhibited: PropTypes.bool.isRequired,
};

export default withOutboundPaths(ServiceLeftLink);
