import { useContext } from "react";
import OutboundLink from "@/components/general/OutboundLink";
import {
	CELL_PHONE_PLANS_PATH,
	DIET_MEAL_PLANS_PATH,
	DNA_TESTING_PATH,
	FLOWER_DELIVERY_PATH,
	HOME_WARRANTIES_PATH,
	MEAL_DELIVERY_PATH,
	WINE_CLUBS_PATH,
} from "@/config/site/favy";
import withOutboundPaths from "@/links/withOutboundPaths";
import {
	getCouponCode,
	getCouponDescription,
	getCouponReplacesReviewCta,
	getIsTargetedPath,
	getPreventRenderingSoftwareColumns,
	getUsesNonSoftwareCoupon,
	getVertical,
} from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import { CompanyContext } from "../CompanyList/CompanyContext";

const CouponLink = ({
	companyVisitPath,
	companyReviewPath,
	isCompanyReviewLinkable,
	children,
}) => {
	const isTargetedPath = useSelector(getIsTargetedPath);
	const { company } = useContext(CompanyContext);

	if (isTargetedPath)
		return (
			<OutboundLink company={company} to={companyVisitPath}>
				{children}
			</OutboundLink>
		);

	if (isCompanyReviewLinkable)
		return <a href={companyReviewPath}>{children}</a>;
	else return children;
};

CouponLink.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	companyReviewPath: PropTypes.string,
	companyVisitPath: PropTypes.string,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
};

const NonSoftwareCoupon = ({ companyIndex, ...props }) => {
	const { company } = useContext(CompanyContext);
	const couponCode = useSelector((state) => getCouponCode(state, { company }));
	const couponDescription = useSelector((state) =>
		getCouponDescription(state, { company }),
	);
	const vertical = useSelector(getVertical);
	const couponReplacesReviewCta = useSelector(getCouponReplacesReviewCta);
	const hasCodeAndDescription = couponCode && couponDescription;

	return (couponDescription && couponReplacesReviewCta) ||
		hasCodeAndDescription ? (
		<CouponLink companyIndex={companyIndex} {...props}>
			<div
				css={[
					tw`sm:absolute top-[1.5rem] sm:right-[0] md:right-[1rem] lg:right-[3rem] flex flex-col items-center rounded border-4 border-dashed border-fvy-home-pink p-2`,
					couponReplacesReviewCta &&
						css`
							@media (max-width: 580px) {
								margin-top: 1rem;
							}
							@media (min-width: 992px) {
								margin-top: 1rem;
							}
							:before {
								position: absolute;
								display: unset;
								content: "";
								background-image: url("/react-images/common/pinkScissors.svg");
								background-repeat: no-repeat;
								background-size: cover;
								width: 24px;
								height: 24px;
								top: -12px;
								right: 10px;
							}
							${tw`!relative !right-[0] !top-[0] !border-[1px] !bg-[#FDF2F5]`}
						`,
				]}
			>
				{couponDescription && (
					<div
						css={[
							tw`text-fvy-home-pink flex text-base items-center font-medium`,
							[
								WINE_CLUBS_PATH,
								FLOWER_DELIVERY_PATH,
								CELL_PHONE_PLANS_PATH,
							].includes(vertical) && tw`text-center`,
						]}
					>
						{couponDescription}
					</div>
				)}
				{couponCode && (
					<div
						css={tw`text-fvy-home-pink flex text-base items-center font-medium`}
					>
						{couponCode}
					</div>
				)}
			</div>
		</CouponLink>
	) : null;
};

NonSoftwareCoupon.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
};

const SoftwareCoupon = ({ companyIndex, ...props }) => {
	const { company } = useContext(CompanyContext);
	const couponCode = useSelector((state) => getCouponCode(state, { company }));
	const couponDescription = useSelector((state) =>
		getCouponDescription(state, { company }),
	);
	const preventRenderingSoftwareColumns = useSelector(
		getPreventRenderingSoftwareColumns,
	);
	const hasCodeAndDescription = couponCode && couponDescription;
	const couponText = hasCodeAndDescription
		? `${couponCode} ${couponDescription}`
		: couponCode || couponDescription;
	return couponText ? (
		<CouponLink companyIndex={companyIndex} {...props}>
			<div css={preventRenderingSoftwareColumns && tw`mb-4`}>
				<span css={tw`text-[#EA3323] flex text-base items-center font-medium`}>
					<img
						css={tw`mr-[8px]`}
						src="/react-images/favy/percent.svg"
						alt="percentage symbol"
					/>
					{couponText}
				</span>
			</div>
		</CouponLink>
	) : null;
};

SoftwareCoupon.propTypes = {
	companyIndex: PropTypes.number.isRequired,
};

const Coupon = ({ companyIndex, ...props }) => {
	const { company } = useContext(CompanyContext);
	const usesNonSoftwareCoupon = useSelector((state) =>
		getUsesNonSoftwareCoupon(state, { company }),
	);
	const vertical = useSelector(getVertical);

	return usesNonSoftwareCoupon ||
		[
			DIET_MEAL_PLANS_PATH,
			MEAL_DELIVERY_PATH,
			HOME_WARRANTIES_PATH,
			DNA_TESTING_PATH,
		].includes(vertical) ? (
		<NonSoftwareCoupon companyIndex={companyIndex} {...props} />
	) : (
		<SoftwareCoupon companyIndex={companyIndex} {...props} />
	);
};

export default withOutboundPaths(Coupon);

Coupon.propTypes = {
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string,
	companyVisitPath: PropTypes.string,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
};
