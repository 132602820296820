/**
 * Application-level error handler
 * @param error
 */
export const errorHandler = (error) => {
	// If Sentry is not available, log the error to the console
	if (window.Sentry === undefined) {
		console.error(error);

		return;
	}

	Sentry.captureException(error);
};
