import {
	BATCH_SET_COMPANY_SPECIFIC_QUERY_PARAMS,
	CLEAR_COMPANY_SPECIFIC_QUERY_PARAMS,
	COMPANY_SPECIFIC_QUERY_PARAMETERS_SET,
} from "@/config/constants";
import deepmerge from "deepmerge";
import { fromPairs, mergeAll } from "ramda";

import { logError } from "../../helpers/logError";

const mergeQueryParams = (arr1, arr2) => {
	const mergedParamsObject = deepmerge(mergeAll(arr1), mergeAll(arr2));
	const paramsArray = Object.entries(mergedParamsObject);
	return paramsArray.map((params) => fromPairs([params]));
};

const initialState = [];

const companyQueryParamsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case COMPANY_SPECIFIC_QUERY_PARAMETERS_SET:
			if (
				Array.isArray(payload.params) ||
				!(payload.params === Object(payload.params))
			)
				logError(
					`${COMPANY_SPECIFIC_QUERY_PARAMETERS_SET} payload is not an object`,
					"warning",
				);
			return mergeQueryParams(state, payload.params);

		case BATCH_SET_COMPANY_SPECIFIC_QUERY_PARAMS:
			if (!Array.isArray(payload.params))
				logError(
					`${BATCH_SET_COMPANY_SPECIFIC_QUERY_PARAMS} payload is not an array. Did you mean to use ${COMPANY_SPECIFIC_QUERY_PARAMETERS_SET}?`,
					"warning",
				);
			return mergeQueryParams(state, payload.params);

		case CLEAR_COMPANY_SPECIFIC_QUERY_PARAMS:
			/* pass array of company names in payload that you'd like to remove query params for e.g. [Bestow, Ladder]*/
			return state.filter(
				(obj) => !payload.companies.includes(Object.keys(obj)[0]),
			);
		default:
			return state;
	}
};

export default companyQueryParamsReducer;
