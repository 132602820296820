import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { COMPANY_SPECIFIC_QUERY_PARAMETERS_SET } from "../../config/constants";
import { getVariations } from "../store/variations/selectors";

export const useVariations = () => {
	const variations = useSelector(getVariations);
	const dispatch = useDispatch();

	useEffect(() => {
		Object.entries(variations).forEach(([companySlug, experiment]) => {
			const variationId = Object.entries(experiment)[0][1];

			dispatch({
				type: COMPANY_SPECIFIC_QUERY_PARAMETERS_SET,
				payload: { params: { [companySlug]: { variationId } } },
			});
		});
	}, [variations, dispatch]);
};
