import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const withOutboundPaths = (WrappedComponent) => {
	const OutboundPaths = (props) => {
		const { companyIndex } = props;
		const isCompanyReviewLinkable = useSelector((state) =>
			selectors.getIsCompanyReviewLinkable(state, props),
		);
		const isAllLinkingProhibited = useSelector((state) =>
			selectors.getIsAllLinkingProhibited(state, props),
		);
		const isVisitLinkingProhibited = useSelector((state) =>
			selectors.getIsVisitLinkingProhibited(state, props),
		);

		const companyReviewPath = useSelector((state) =>
			selectors.getCompanyReviewPath(state, props),
		);
		const companyVisitPath = useSelector((state) =>
			selectors.getCompanyVisitPath(state, { ...props, companyIndex }),
		);

		const returnedProps = {
			...props,
			companyReviewPath,
			companyVisitPath,
			isCompanyReviewLinkable,
			isAllLinkingProhibited,
			isVisitLinkingProhibited,
		};

		return <WrappedComponent {...returnedProps} />;
	};
	OutboundPaths.displayName = `OutboundPaths(${
		WrappedComponent.displayName || WrappedComponent.name || "Component"
	})`;
	OutboundPaths.propTypes = {
		company: PropTypes.object.isRequired,
		companyIndex: PropTypes.number.isRequired,
	};
	return OutboundPaths;
};

export default withOutboundPaths;
