import PropTypes from "prop-types";

export const BaseAmazonLabel = (props) => (
	<div className={props?.wrapper}>
		<p className={props?.text}>View Offer On</p>
		<img
			width={125}
			height={32}
			src="/react-images/favy/amazonLogo.svg"
			className={props?.image}
		/>
	</div>
);

BaseAmazonLabel.propTypes = {
	wrapper: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
};
