import { css } from "@emotion/react";
import PropTypes from "prop-types";

const styles = {
	toggleContainer: css`
		z-index: 1;
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 36px;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		div > svg {
			height: 15px;
			width: 16px;
		}

		p {
			font-size: 15px;
			line-height: 15px;
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 600;
			color: white;
			display: flex;
		}
	`,
	filterCount: css`
		background-color: white;
		color: #ff5161;
		font-size: 12px;
		border-radius: 50%;
		margin-top: -4px;
		margin-left: 6px;
		padding: 3px 7px 0px 7px;
	`,
};

const ExpandMobileFilters = ({ filterCount, toggleMobileFilters }) => {
	const count =
		filterCount > 0 ? (
			<span css={styles.filterCount}>{filterCount}</span>
		) : null;
	const filterLabel = filterCount > 1 ? "Filters" : "Filter";

	return (
		<div
			role="button"
			aria-haspopup="true"
			aria-label="Filter Results"
			css={styles.toggleContainer}
		>
			<div onClick={toggleMobileFilters}>
				<svg>
					<line x1="0" y1="0" x2="12" y2="0" stroke="white" strokeWidth="4" />
					<line x1="2" y1="6" x2="10.5" y2="6" stroke="white" strokeWidth="2" />
					<line
						x1="4.5"
						y1="10.5"
						x2="8.5"
						y2="10.5"
						stroke="white"
						strokeWidth="2"
					/>
				</svg>
				<p>
					{filterLabel} {count}
				</p>
			</div>
		</div>
	);
};

export default ExpandMobileFilters;

ExpandMobileFilters.propTypes = {
	toggleMobileFilters: PropTypes.func.isRequired,
	filterCount: PropTypes.number.isRequired,
};
