// import constants
import { CURRENT_YEAR } from "@/config/constants";
// import selectors
import { categoryInfoSelector } from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

const styles = {
	reviewLink: css`
		position: absolute;
		bottom: 10px;

		@media (max-width: 991px) {
			position: static;
		}

		@media (max-width: 767px) {
			display: none;
		}
	`,

	reviewTextSpan: css`
		margin-right: 3px;
		color: #333742;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.024em;
		font-family: AvenirNextLTPro-Demi;
	`,
};

export const LegacyReviewLink = ({
	isCompanyReviewLinkable,
	companyReviewPath,
	companyName,
}) => {
	const categoryInfo = useSelector(categoryInfoSelector);
	const reviewLinkWithDateText = path(
		["0", "fields", "reviewLinkWithDateText"],
		categoryInfo,
	).replace("{year}", CURRENT_YEAR);

	return (
		<a
			{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
			css={styles.reviewLink}
			aria-label={companyName + " Review"}
		>
			<span css={styles.reviewTextSpan}>{reviewLinkWithDateText}</span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="6.031"
				height="10.031"
				viewBox="0 0 6.031 10.031"
				focusable="false"
			>
				<path
					d="M387.113,622.49l-3.964-3.637a0.477,0.477,0,0,1,0-.71,0.582,0.582,0,0,1,.782,0l4.9,4.5a0.466,0.466,0,0,1,0,.71l-4.9,4.5a0.582,0.582,0,0,1-.782,0,0.485,0.485,0,0,1,0-.717l3.964-3.63,0.521-.51Z"
					transform="translate(-383 -618)"
				/>
			</svg>
		</a>
	);
};

LegacyReviewLink.propTypes = {
	companyReviewPath: PropTypes.string.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
	companyName: PropTypes.string.isRequired,
};

export default LegacyReviewLink;
