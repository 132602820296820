import PropTypes from "prop-types";

export const BaseVisitLink = ({ children, ...props }) => {
	if (!children) return null;

	return (
		<div className={props?.wrapper} data-cy="visit-link">
			<span className={props?.contentWrapper}>{children}</span>
			{props?.icon && <span className={props?.iconClass}>{props.icon}</span>}
		</div>
	);
};

BaseVisitLink.propTypes = {
	children: PropTypes.node,
	wrapper: PropTypes.string,
	contentWrapper: PropTypes.string,
	icon: PropTypes.element,
	iconClass: PropTypes.string,
};
