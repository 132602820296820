import {
	CURRENT_YEAR,
	FVY_DOMAIN,
	IR_DOMAIN,
	PC_DOMAIN,
	SITE,
	TCR_DOMAIN,
	THR_DOMAIN,
} from "@/config/constants";
import { INCORPORATION_SERVICES_PATH } from "@/config/site/favy";
import {
	getCategoryName,
	getFeaturedCompany,
	getShowFeaturedCompany,
	getVertical,
} from "@/selectors";
import PropTypes from "prop-types";
import { clone, path } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import ServiceBlockLayout from "../ServiceBlock/ServiceBlockLayout";

const styles = {
	parent: {
		domainLevelOverrides: {
			[FVY_DOMAIN]: css`
				@media (max-width: 767px) {
					max-width: 500px;
				}
				${tw`mx-auto mb-8 mt-8 border-purple-500 rounded`}
			`,
			[IR_DOMAIN]: tw`mt-8 rounded`,
			[THR_DOMAIN]: tw`mt-8 border-blue-500 rounded`,
			[TCR_DOMAIN]: tw`mt-8 border-blue-500 rounded`,
			[PC_DOMAIN]: tw`mt-8 border-blue-500 rounded`,
		},
		verticalOverrides: {},
	},
	textWrapper: {
		domainLevelOverrides: {
			[FVY_DOMAIN]: tw`bg-purple-100`,
		},
	},
	text: {
		domainLevelOverrides: {},
	},
	serviceBlockWrapper: {
		verticalOverrides: {
			[INCORPORATION_SERVICES_PATH]: css`
				> div {
					margin-bottom: 0px !important;
				}
				${tw`!p-0`}
			`,
		},
		domainLevelOverrides: {
			[FVY_DOMAIN]: tw`pl-2 pr-2`,
			[IR_DOMAIN]: tw`pl-2 pr-2`,
			[TCR_DOMAIN]: tw`pl-2 pr-2 bg-white`,
		},
	},
};

export const LegacyFeaturedCompany = ({ innerWidth, companies }) => {
	const vertical = useSelector(getVertical);
	const defaultCompany = companies[0];
	const featuredCompany = useSelector(getFeaturedCompany);
	const showFeaturedCompany = useSelector(getShowFeaturedCompany);
	const categoryName = useSelector(getCategoryName);
	const company = clone(featuredCompany || defaultCompany);
	// HACK: the below legacy code is an anti-pattern
	if (company) {
		company.fields.isFeatured = true;
	}

	return showFeaturedCompany && company ? (
		<div
			css={[
				tw`border-solid border-indigo-400`,
				path(["parent", "domainLevelOverrides", SITE], styles),
				path(["parent", "verticalOverrides", vertical], styles),
			]}
		>
			<div
				css={[
					tw`text-center bg-indigo-100`,
					path(["textWrapper", "domainLevelOverrides", SITE], styles),
				]}
			>
				<span
					css={[
						tw`leading-8 font-bold text-black`,
						path(["text", "domainLevelOverrides", SITE], styles),
					]}
				>
					{`Our #1 ${categoryName} Choice for ${CURRENT_YEAR}`}
				</span>
			</div>
			<div
				css={[
					css`
						> div {
							margin: 0;
							border: none;
							border-radius: 0;
						}
					`,
					path(["serviceBlockWrapper", "verticalOverrides", vertical], styles),
					path(["serviceBlockWrapper", "domainLevelOverrides", SITE], styles),
				]}
			>
				<ServiceBlockLayout
					isFeatured
					companyIndex={0}
					innerWidth={innerWidth}
					company={company}
				/>
			</div>
		</div>
	) : null;
};

LegacyFeaturedCompany.propTypes = {
	innerWidth: PropTypes.number.isRequired,
	companies: PropTypes.array.isRequired,
};
