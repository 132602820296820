import { useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import { getFilterPlaceholder, getMobileFilteringLabel } from "../../helpers";
import { getModalSelection } from "../../selectors";

const styles = {
	container: css`
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		padding: 13px 15px;
		border: 1px solid rgb(214, 219, 222);
		border-radius: 2px;

		p {
			text-align: left;
			font-weight: 600;
			font-size: 14px;
			display: block;
			margin: 4px 0;
		}

		button:hover {
			background-color: #1d8ce0;
			color: white;
		}
	`,
	title: css`
		flex-grow: 1;
	`,
	buttonContainer: css`
		background-color: white;
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 25px;
		width: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 10px 10px 0 0;

		p {
			font-size: 15px;
			text-align: center;
		}
	`,
	buttonOptions: css`
		padding: 8px;
		margin: 5px 5px 5px 0;
		border-radius: 5px;
		border: none;
		font-size: 13px;
		font-weight: 600;
		background-color: #e4e4e4;
		color: #111;
		min-width: 75px;
	`,
	selectArrow: css`
		width: 11px;
		height: 7px;
		margin-left: 30px;
	`,
	activeFilterMarker: css`
		display: inline-block;
		width: 7px;
		height: 7px;
		background-color: #1d8ce0;
		margin-left: 15px;
		border-radius: 50%;
	`,
};

export const MobileFilteringButtonSelect = ({
	vertical,
	filteringKey,
	title,
	options,
	buildFilteringState,
	parentFilteringValue,
	isMobile,
	filter,
	isNotUserFiltered,
}) => {
	const modalSelectedOption = useSelector((state) =>
		getModalSelection(state, { filteringKey }),
	);

	const [selectedOption, setSelectedOption] = useState("default");
	const parentFiltering =
		path(["value"], parentFilteringValue) ||
		parentFilteringValue ||
		path(["value"], modalSelectedOption) ||
		modalSelectedOption;
	const [displayOptionContainer, setDisplayOptionContainer] = useState(false);

	const handleClick = (event) => {
		const ranges = options.map((option) => option.range);
		const rangeIndex = ranges.findIndex(
			(range) =>
				range &&
				range[0] <= Number(event.target.value) &&
				range[1] >= Number(event.target.value),
		);
		const filteringStateValue =
			rangeIndex !== -1
				? { value: event.target.value, range: ranges[rangeIndex] }
				: event.target.value;
		const selectOptionValue = event.target.value;

		setSelectedOption(selectOptionValue);
		const filteringStateObj = {
			[filteringKey]: filteringStateValue,
		};
		buildFilteringState(filteringStateObj, isMobile);
	};

	const toggleOptions = () => {
		setDisplayOptionContainer((prev) => !prev);
	};

	const hasUserSelected = selectedOption !== "default";
	const placeholderLabel =
		filter && !hasUserSelected && isNotUserFiltered
			? getFilterPlaceholder(vertical, filter, filteringKey)
			: null;
	const inheritedLabelExists =
		!placeholderLabel && !hasUserSelected && parentFiltering !== undefined;

	const modalOrParentLabel =
		inheritedLabelExists &&
		getMobileFilteringLabel(vertical, title, parentFiltering);
	const userSelectedLabel =
		hasUserSelected && getMobileFilteringLabel(vertical, title, selectedOption);
	const calculatedDisplayValue =
		placeholderLabel || userSelectedLabel || modalOrParentLabel || null;

	const optionContainer = displayOptionContainer ? (
		<div
			id="optionsContainer"
			role="listbox"
			aria-label={title}
			aria-expanded="true"
			css={styles.buttonContainer}
		>
			<p>{title}</p>
			{options.map((option, idx) => (
				<button
					css={styles.buttonOptions}
					key={idx}
					role="option"
					value={option.value}
					onClick={handleClick}
				>
					{option.label}
				</button>
			))}
		</div>
	) : null;

	const activeFilterMarker =
		placeholderLabel || inheritedLabelExists ? (
			<span css={styles.activeFilterMarker}></span>
		) : null;

	return (
		<div
			css={styles.container}
			onClick={toggleOptions}
			data-testid="select-container"
		>
			<div>
				<span css={tw`inline-flex items-center`}>
					<p css={styles.title}>{title}</p>
					{activeFilterMarker}
				</span>
			</div>
			<div css={tw`inline-flex items-center`}>
				<p role="button" aria-haspopup="true">
					{calculatedDisplayValue}
				</p>
				{optionContainer}
				<svg css={styles.selectArrow}>
					<line
						x1="0"
						y1="0"
						x2="5"
						y2="5"
						strokeWidth="2px"
						stroke="#606E79"
					/>
					<line
						x1="5"
						y1="5"
						x2="10"
						y2="0"
						strokeWidth="2px"
						stroke="#606E79"
					/>
				</svg>
			</div>
		</div>
	);
};

MobileFilteringButtonSelect.propTypes = {
	vertical: PropTypes.string.isRequired,
	filteringKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	buildFilteringState: PropTypes.func.isRequired,
	parentFilteringValue: PropTypes.any,
	isMobile: PropTypes.bool,
	filter: PropTypes.string,
	isNotUserFiltered: PropTypes.bool,
};

export default MobileFilteringButtonSelect;
