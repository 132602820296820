// import selectors
import {
	getPreventRenderingSoftwareColumns,
	getProductDescription,
	getProductDescriptionHeadline,
	getUsesNewSoftwareDesign,
	getUsesSoftwareDesign,
} from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw from "twin.macro";

export const ProductDescription = ({ company }) => {
	const productDescription = useSelector((state) =>
		getProductDescription(state, { company }),
	);
	const softwareDesignWithoutColumns = useSelector(
		getPreventRenderingSoftwareColumns,
	);
	const usesSoftwareDesign = useSelector(getUsesSoftwareDesign);
	const productDescriptionHeadline = useSelector((state) =>
		getProductDescriptionHeadline(state, { company }),
	);
	const usesNewSoftwareDesign = useSelector(getUsesNewSoftwareDesign);

	return productDescription ? (
		<div
			css={[
				softwareDesignWithoutColumns || !usesSoftwareDesign
					? tw`mt-0 mb-4`
					: tw`mt-4 sm:mt-6 mb-4 sm:mb-0`,
				usesNewSoftwareDesign && tw`!mt-2 !mb-2`,
			]}
		>
			<div
				css={[
					softwareDesignWithoutColumns
						? tw`hidden`
						: tw`sm:hidden text-base font-medium mb-2`,
					productDescription && !usesSoftwareDesign ? tw`!block` : null,
				]}
			>
				{productDescriptionHeadline}
			</div>
			<div css={tw`text-sm sm:text-base leading-6 max-w-3xl`}>
				{productDescription}
			</div>
		</div>
	) : null;
};

export default ProductDescription;

ProductDescription.propTypes = {
	company: PropTypes.object.isRequired,
};
