import PropTypes from "prop-types";

import { MainLayout } from "./components/general/MainLayout";
import { AppProviders } from "./components/UIComponents/AppProviders";

export const App = ({ deviceType }) => (
	<AppProviders>
		<MainLayout deviceType={deviceType} />
	</AppProviders>
);

App.propTypes = {
	deviceType: PropTypes.string,
};
