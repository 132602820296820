/**
 * Application-level error message handler
 * @param errorMessage
 * @param level
 */
export const logError = (errorMessage, level = "error") => {
	// If Sentry is not available, log the error to the console
	if (window.Sentry === undefined) {
		console.error(errorMessage);

		return;
	}

	Sentry.captureMessage(errorMessage, { level });
};
