import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import ReduxThunk from "redux-thunk";
import useSSR from "use-ssr";

import { rootReducer } from "./rootreducer";

const { isServer } = useSSR();

// Below instantiates both a development and production redux store
const logger = createLogger();

export const configureStore = (preloadState) => {
	const devStore = createStore(
		rootReducer,
		preloadState,
		isServer
			? applyMiddleware(ReduxThunk)
			: composeWithDevTools(applyMiddleware(ReduxThunk, logger)),
	);

	const prodStore = createStore(
		rootReducer,
		preloadState,
		applyMiddleware(ReduxThunk),
	);

	return process.env.NODE_ENV === "development" ? devStore : prodStore;
};
