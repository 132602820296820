import { useContext } from "react";
import { getCompanyPhoneNumber } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import BasePhoneLink from "../../BaseComponents/BasePhoneLink";
import { CompanyContext } from "../../CompanyList/CompanyContext";
import { ThemeContext } from "./ThemeContext";

const DEFAULT_CTA_TEXT = "Call Now";
const LONG_TEXT_LENGTH = 20;

export const LeftIconPhoneLink = ({ className }) => {
	const { company } = useContext(CompanyContext);
	const { phoneCtaText = DEFAULT_CTA_TEXT } = useContext(ThemeContext);
	const useSmallFont = phoneCtaText.length > LONG_TEXT_LENGTH;

	const phoneCtaTextStyling = useSmallFont
		? "tw-mb-[5px] tw-text-sm tw-font-semibold tw-tracking-[-0.616px]"
		: "tw-text-base tw-font-semibold";

	const phoneNumber = useSelector((state) =>
		getCompanyPhoneNumber(state, { company }),
	);

	return (
		<BasePhoneLink company={company} className={className}>
			<div className="tw-relative tw-w-full tw-rounded-lg tw-py-3 tw-ring-2 tw-ring-inset tw-ring-[#16A34A] lg:tw-flex lg:tw-py-0 lg:tw-ring-0">
				<div className="tw-flex tw-items-center tw-justify-center">
					<svg
						height="34"
						viewBox="0 0 34 34"
						width="34"
						xmlns="http://www.w3.org/2000/svg"
						className="tw-mr-1.5 tw-inline-block tw-h-[1em] tw-w-[1em] tw-shrink-0 tw-align-middle tw-text-base tw-leading-[1em] tw-text-[#16A34A] lg:tw-mr-2 lg:tw-text-4xl"
					>
						<path
							d="m937.111 914.611a21.407 21.407 0 0 1 -6.8-1.133 2.325 2.325 0 0 0 -1.889.378l-4.155 4.155a29.4 29.4 0 0 1 -12.467-12.467l4.156-4.155a1.5 1.5 0 0 0 .377-1.889 25.43 25.43 0 0 1 -.944-6.611 1.785 1.785 0 0 0 -1.889-1.889h-6.611a1.785 1.785 0 0 0 -1.889 1.889 32.085 32.085 0 0 0 32.111 32.111 1.785 1.785 0 0 0 1.889-1.889v-6.611a1.785 1.785 0 0 0 -1.889-1.889zm-1.889-6.611h3.778a16.93 16.93 0 0 0 -17-17v3.778a13.14 13.14 0 0 1 13.222 13.222zm-7.555 0h3.777a9.352 9.352 0 0 0 -9.444-9.444v3.777a5.566 5.566 0 0 1 5.667 5.667z"
							fill="currentColor"
							fillRule="evenodd"
							transform="translate(-905 -891)"
						/>
					</svg>
					<span className="tw-text-base tw-font-bold tw-leading-6 tw-tracking-[-0.16px] tw-text-[#16A34A] lg:tw-hidden">
						Call Now
					</span>
				</div>
				<div className="tw-hidden tw-flex-col lg:tw-flex">
					<div className={`${phoneCtaTextStyling} tw-text-[#5A616B]`}>
						{phoneCtaText}
					</div>
					<div className="tw-tex-lg tw-font-bold tw-text-[#16A34A] xl:tw-text-xl">
						{phoneNumber}
					</div>
				</div>
			</div>
		</BasePhoneLink>
	);
};

LeftIconPhoneLink.propTypes = {
	className: PropTypes.string,
};
