import * as filters from "../src/helpers/filters";
import * as constants from "./constants";
import * as fvyConstants from "./site/favy";
import * as irConstants from "./site/insuranceranked";
import * as tcrConstants from "./site/thecreditreview";

const formatQueryString = (parameter, value) => {
	if (!parameter) {
		throw new Error("parameter arg not passed to formatQueryString function");
	}
	if (!value) {
		throw new Error("value argument not passed to formatQueryString function");
	}
	const formattedValue = value.toLowerCase();
	return `&${parameter}=${formattedValue}`;
};

export const customBehaviorByPathsWithFilter = {
	[constants.TCR_DOMAIN]: {
		[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: {
			[constants.CUSTOM_MAIN_BENEFITS_FIELD_BY_UI_FILTERING]: {
				[tcrConstants.BAD_CREDIT_RATING]: "poorCreditCompanyMainBenefits",
			},
			[constants.CUSTOM_RATING_KEY_BY_UI_FILTERING]: {
				[tcrConstants.BAD_CREDIT_RATING]: "badCreditRating",
				[tcrConstants.FAIR_CREDIT_RATING]: "fairCreditRating",
				[tcrConstants.GOOD_CREDIT_RATING]: "goodCreditRating",
				[tcrConstants.EXCELLENT_CREDIT_RATING]: "excellentCreditRating",
			},
			[tcrConstants.FAST_LOANS_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByBadCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.BAD_CREDIT_RATING,
				),
				[constants.CUSTOM_MAIN_BENEFITS_FIELD]: "poorCreditCompanyMainBenefits",
				[constants.CUSTOM_RATING_KEY]: "badCreditRating",
			},
			[tcrConstants.QUICK_LOANS_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByBadCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.BAD_CREDIT_RATING,
				),
				[constants.CUSTOM_MAIN_BENEFITS_FIELD]: "poorCreditCompanyMainBenefits",
				[constants.CUSTOM_RATING_KEY]: "badCreditRating",
			},
			[tcrConstants.EMERGENCY_LOANS_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByBadCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.BAD_CREDIT_RATING,
				),
				[constants.CUSTOM_MAIN_BENEFITS_FIELD]: "poorCreditCompanyMainBenefits",
				[constants.CUSTOM_RATING_KEY]: "badCreditRating",
			},
			[tcrConstants.BAD_CREDIT_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByBadCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.BAD_CREDIT_RATING,
				),
				[constants.CUSTOM_MAIN_BENEFITS_FIELD]: "poorCreditCompanyMainBenefits",
				[constants.CUSTOM_RATING_KEY]: "badCreditRating",
			},
			[tcrConstants.FAIR_CREDIT_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByFairCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.FAIR_CREDIT_RATING,
				),
				[constants.CUSTOM_RATING_KEY]: "fairCreditRating",
			},
			[tcrConstants.GOOD_CREDIT_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByGoodCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.GOOD_CREDIT_RATING,
				),
				[constants.CUSTOM_RATING_KEY]: "goodCreditRating",
			},
			[tcrConstants.EXCELLENT_CREDIT_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [filters.filterByExcellentCreditQuality],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.EXCELLENT_CREDIT_RATING,
				),
				[constants.CUSTOM_RATING_KEY]: "excellentCreditRating",
			},
			[tcrConstants.ONE_THOUSAND_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByLoanAmount(tcrConstants.ONE_THOUSAND_FILTER),
					filters.filterByBadCreditQuality,
				],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.BAD_CREDIT_RATING,
				),
				[constants.CUSTOM_MAIN_BENEFITS_FIELD]: "poorCreditCompanyMainBenefits",
				[constants.CUSTOM_RATING_KEY]: "badCreditRating",
			},
			[tcrConstants.FIVE_THOUSAND_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByLoanAmount(tcrConstants.FIVE_THOUSAND_FILTER),
					filters.filterByGoodCreditQuality,
				],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.GOOD_CREDIT_RATING,
				),
				[constants.CUSTOM_RATING_KEY]: "goodCreditRating",
			},
			[tcrConstants.TEN_THOUSAND_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByLoanAmount(tcrConstants.TEN_THOUSAND_FILTER),
					filters.filterByGoodCreditQuality,
				],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.GOOD_CREDIT_RATING,
				),
				[constants.CUSTOM_RATING_KEY]: "goodCreditRating",
			},
			[tcrConstants.TWENTY_FIVE_THOUSAND_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByLoanAmount(tcrConstants.TWENTY_FIVE_THOUSAND_FILTER),
					filters.filterByGoodCreditQuality,
				],
				[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
					tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
					tcrConstants.GOOD_CREDIT_RATING,
				),
				[constants.CUSTOM_RATING_KEY]: "goodCreditRating",
			},
		},
		[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: {
			[tcrConstants.WORKING_CAPITAL_LOANS_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByFundingType(
						tcrConstants.WORKING_CAPITAL_LOANS_FILTER,
					),
				],
			},
			[tcrConstants.LINE_OF_CREDIT_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByFundingType(tcrConstants.LINE_OF_CREDIT_FILTER),
				],
			},
			[tcrConstants.INVOICE_FACTORING_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByFundingType(tcrConstants.INVOICE_FACTORING_FILTER),
				],
			},
			[tcrConstants.TERM_LOAN_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByFundingType(tcrConstants.TERM_LOAN_FILTER),
				],
			},
			[tcrConstants.SBA_LOAN_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByFundingType(tcrConstants.SBA_LOANS_FILTER),
				],
			},
			[tcrConstants.FUNDING_MARKETPLACE_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByFundingType(tcrConstants.FUNDING_MARKETPLACE_FILTER),
				],
			},
		},
	},
	[constants.IR_DOMAIN]: {
		[irConstants.IR_LIFE_INSURANCE_DEFAULT_PATH]: {
			[constants.CUSTOM_RATING_KEY_BY_UI_FILTERING]: {
				[irConstants.AGED_FILTERING_KEY]: "overAge50Rating",
			},
		},
		[irConstants.IR_PET_INSURANCE_DEFAULT_PATH]: {
			"dog-insurance": {
				[constants.CUSTOM_RATING_KEY]: "dogInsuranceRating",
			},
		},
		[irConstants.DISABILITY_INSURANCE_DEFAULT_PATH]: {
			[irConstants.SHORT_TERM_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByPolicyType(irConstants.SHORT_TERM_URL_PARAMETER),
				],
			},
			[irConstants.LONG_TERM_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByPolicyType(irConstants.LONG_TERM_URL_PARAMETER),
				],
			},
			[irConstants.CRITICAL_ILLNESS_URL_PARAMETER]: {
				[constants.CUSTOM_FILTER]: [
					filters.filterByPolicyType(
						irConstants.CRITICAL_ILLNESS_URL_PARAMETER,
					),
				],
			},
		},
	},
	[constants.FVY_DOMAIN]: {
		[fvyConstants.MORTGAGE_PATH]: {
			[constants.CUSTOM_RATING_KEY_BY_UI_FILTERING]: {
				purchase: "purchaseRating",
				refinance: "refinanceRating",
			},
		},
	},
};

export const customBehaviorByBestServicesPath = {
	[constants.TCR_DOMAIN]: {
		[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: {
			[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: formatQueryString(
				tcrConstants.CREDIT_QUALITY_FILTERING_KEY,
				tcrConstants.GOOD_CREDIT_RATING,
			),
		},
	},
};

export const customBehaviorByMobileTargetedPath = {
	[constants.IR_DOMAIN]: {
		[irConstants.IR_LIFE_INSURANCE_DEFAULT_PATH]: {
			[constants.CUSTOM_MAIN_BENEFITS_FIELD]: "mobileCompanyMainBenefits",
		},
	},
};

export const customVisitQueryParamsRemovedPostModalFiltering = {
	[constants.TCR_DOMAIN]: {
		[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: {
			[constants.CUSTOM_VISIT_PATH_QUERY_STRING]: `&${
				tcrConstants.TCR_SITE_CONFIGURATION[
					tcrConstants.PERSONAL_LOANS_DEFAULT_PATH
				].modalQueryParam
			}=0`,
		},
	},
};
