import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { pathOr, prop } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

const iconsMap = {
	"Year Founded": "/react-images/favy/calendar.svg",
	"Starting Price (monthly)": "/react-images/favy/money.svg",
	"Minimum Users": "/react-images/favy/person.svg",
	Entertainment: "/react-images/favy/controller.svg",
	Privacy: "/react-images/favy/lock.svg",
	Pricing: "/react-images/favy/money.svg",
	"Trial Period": "/react-images/favy/clock.svg",
	"Great For": "/react-images/favy/checkBadge.svg",
	"Starting Price": "/react-images/favy/money.svg",
	"BBB Rating": "/react-images/favy/person.svg",
	"Free Domain?": "/react-images/favy/web.svg",
	"Free Trial?": "/react-images/favy/clock.svg",
	"Free Version?": "/react-images/favy/web.svg",
	"Users Per Account": "/react-images/favy/person.svg",
	"Annual Pricing": "/react-images/favy/money.svg",
	"Best For": "/react-images/favy/checkBadge.svg",
};

export const SoftwareColumns = (props) => {
	const columnInformation = useSelector((state) =>
		selectors.getColumnInformation(state, props),
	);
	const columnLength = columnInformation.length;
	// eslint-disable-next-line no-magic-numbers
	const usesTwoColumnLayout = columnLength === 2;
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const usesNewSoftwareDesign = useSelector(selectors.getUsesNewSoftwareDesign);
	const vertical = useSelector(selectors.getVertical);

	return usesSoftwareDesign ? (
		<div
			css={[
				usesTwoColumnLayout ? tw`justify-around` : tw`justify-between`,
				tw`block sm:flex max-w-xl pt-2 pb-2 sm:pt-8 sm:pb-8`,
				usesNewSoftwareDesign && tw`!min-w-full !max-w-full !p-0 mt-2`,
			]}
		>
			{columnInformation.map((column, idx) => {
				const { label, value } = column;
				const isStringColumnValue = typeof value === "string";
				const isMultiLine = isStringColumnValue ? value.includes("\\n") : false;
				const columnValue = isMultiLine ? value.replace(" \\n ", " ") : value;
				return (
					<div
						key={idx}
						css={[
							tw`pt-2 pb-2 sm:pt-0 sm:pb-0`,
							columnLength !== idx + 1 &&
								tw`border-solid border-b border-l-0 border-r-0 border-t-0 border-gray-200 sm:border-0`,
							usesNewSoftwareDesign &&
								tw`md:!p-2 md:!rounded-full md:shadow-md md:!border md:!border-solid md:!border-gray-200`,
						]}
					>
						<div css={tw`flex flex-row-reverse sm:flex-row justify-between`}>
							<div
								css={[
									tw`my-auto sm:mr-2 ml-2 sm:ml-0 flex sm:block`,
									usesNewSoftwareDesign && tw`md:!flex md:!mr-0`,
								]}
							>
								<img
									css={[
										tw`h-4 w-4 sm:h-8 sm:w-8`,
										usesNewSoftwareDesign && tw`!w-4 !h-4 md:!mr-2`,
									]}
									src={pathOr(
										prop(label, iconsMap),
										[vertical, label],
										iconsMap,
									)}
									alt={`${label} image`}
								/>
							</div>
							<div
								css={[
									tw`inline-flex sm:block items-center justify-between w-full`,
									usesNewSoftwareDesign && tw`md:!inline-flex`,
								]}
							>
								<div
									css={[
										css`
											color: #747481;
											font-size: 14px;
											line-height: 18px;
										`,
										tw`max-w-110px sm:max-w-full font-normal text-sm`,
										usesNewSoftwareDesign && tw`md:!mr-2`,
									]}
								>
									{label}
								</div>
								<div
									css={[
										css`
											color: #22293a;
											font-size: 14px;
											line-height: 18px;
										`,
										tw`max-w-110px text-right sm:text-left sm:max-w-full font-bold text-sm`,
									]}
								>
									{columnValue}
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	) : null;
};

SoftwareColumns.propTypes = {
	company: PropTypes.object.isRequired,
};

export default SoftwareColumns;
