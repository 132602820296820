import tw from "twin.macro";

export const FallbackComponent = () => (
	<div className="container">
		<div
			css={tw`block relative mt-1.125 rounded bg-white shadow-sm border border-solid border-gray-400 font-sans`}
		>
			<h2 css={tw`block w-full text-center text-noComponentGray-text`}>
				An error occurred loading the page &#9785;
			</h2>
			<h3 css={tw`block w-full text-center text-noComponentGray-text`}>
				Please try again
			</h3>
		</div>
	</div>
);
