// import selectors
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Import review widget components
import TrustpilotReviewWidget from "./Widgets/TrustpilotReviewWidget";
import { WebReviewWidget } from "./Widgets/WebReviewWidget";

export const ReviewWidgetSection = (props) => {
	const { company } = props;
	const usesAllReviewWidgets = useSelector(
		selectors.getAreAllReviewWidgetsRendered,
	);

	/* Preserves default functionality when not using a combo of review widgets. Return both widgets as they're responsible for their own rendering */
	if (!usesAllReviewWidgets) {
		return (
			<>
				<TrustpilotReviewWidget company={company} />
				<WebReviewWidget company={company} />
			</>
		);
	}

	if (usesAllReviewWidgets) {
		const trustpilotScore = useSelector((state) =>
			selectors.getTrustpilotScore(state, props),
		);
		const trustpilotWhitelistThreshold = useSelector(
			selectors.getTrustpilotWhitelistThreshold,
		);
		const webReviewScore = useSelector((state) =>
			selectors.getWebReviewScore(state, props),
		);
		const isTrustpilotScoreHigher = trustpilotScore > webReviewScore;

		// Return TrustpilotReviewWidget if it's score is higher than the whitelist threshold even if it's score may be lower than that of the web review score
		if (
			trustpilotWhitelistThreshold &&
			trustpilotScore > trustpilotWhitelistThreshold
		) {
			return <TrustpilotReviewWidget company={company} />;
		}

		// Return the widget that has the higher score associated
		return isTrustpilotScoreHigher ? (
			<TrustpilotReviewWidget company={company} />
		) : (
			<WebReviewWidget company={company} />
		);
	}
};

ReviewWidgetSection.propTypes = {
	company: PropTypes.object.isRequired,
};
export default ReviewWidgetSection;
