import { pathOr } from "ramda";

import {
	CUSTOM_VISIT_PATH_QUERY_STRING,
	DEFAULT_VISIT_PATH,
	SITE,
	SITE_INFORMATION,
} from "../../config/constants";
import {
	customBehaviorByBestServicesPath,
	customBehaviorByPathsWithFilter,
	customVisitQueryParamsRemovedPostModalFiltering,
} from "../../config/customBehaviorByPath";

const buildVisitPath = (baseVisitPath, queryParams) => {
	const searchParams = new URLSearchParams();

	queryParams.forEach((param) => {
		searchParams.set(param.name, param.value);
	});

	return `${baseVisitPath}?${searchParams}`;
};

export const generateCompanyVisitPath = ({
	vertical,
	isTargeted,
	isModalOrFilteringUiFiltered,
	visitLinkQueryString,
	filter,
	companySlug,
	rank,
	isBestServicesPath,
	isNotModalFiltered,
}) => {
	let visitPathParam =
		SITE_INFORMATION?.[vertical]?.visitPathParam ??
		SITE_INFORMATION.criticalOperationProperties?.visitPathParam ??
		DEFAULT_VISIT_PATH;

	if (filter === "demo") {
		visitPathParam = "demo";
	}

	/* The logic below allows us to add a custom query string parameter for targeted paths that are removed once a user applies filtering via a filtering modal */
	const preModalFilteredCustomVisitPathQueryParamsForTargetedRoutes =
		isTargeted && isNotModalFiltered
			? pathOr(
					"",
					[SITE, vertical, CUSTOM_VISIT_PATH_QUERY_STRING],
					customVisitQueryParamsRemovedPostModalFiltering,
				)
			: "";

	const baseVisitPath = buildVisitPath(`/${visitPathParam}/${companySlug}`, [
		{ name: "isTargeted", value: isTargeted ? "1" : "0" },
		{ name: "rank", value: rank.toString() },
	]).concat(preModalFilteredCustomVisitPathQueryParamsForTargetedRoutes);

	/* Custom query strings for best services route*/
	const customVisitPathQueryStringForBestServicesRoute = pathOr(
		null,
		[SITE, vertical, CUSTOM_VISIT_PATH_QUERY_STRING],
		customBehaviorByBestServicesPath,
	);

	/* Custom query strings for filtering routes*/
	const customVisitPathQueryStringForRoutesWithFilter = pathOr(
		null,
		[SITE, vertical, filter, CUSTOM_VISIT_PATH_QUERY_STRING],
		customBehaviorByPathsWithFilter,
	);

	/* Ternary to define custom query strings based on route */
	const customVisitPathQueryString = isBestServicesPath
		? customVisitPathQueryStringForBestServicesRoute
		: customVisitPathQueryStringForRoutesWithFilter;

	/* Boolean to determine if a custom query string should be appended. True only when filtering hasn't been applied by a user and a custom query string is defined for the site, vertical, and filter */
	const isApplicableForCustomQueryString =
		!isModalOrFilteringUiFiltered && customVisitPathQueryString !== null;

	if (isApplicableForCustomQueryString) {
		return `${baseVisitPath}${customVisitPathQueryString}`;
	} else {
		return `${baseVisitPath}${visitLinkQueryString}`;
	}
};
