import PropTypes from "prop-types";
import tw, { css } from "twin.macro";

import { ModalButton } from "../../ModalButton";

const CreditScore = (props) => {
	const { setMediaAlphaState, isLastStep } = props;

	const updateState = (val) => {
		setMediaAlphaState({ credit_rating: val }, isLastStep);
	};

	return (
		<div css={tw`mt-4 mb-8`}>
			<div css={tw`mb-8 text-center font-semibold text-black leading-5`}>
				Please estimate your credit score:
			</div>
			<div css={tw`flex justify-between mb-4`}>
				<div css={tw`mr-4 w-full`}>
					<ModalButton
						size="large"
						variant="contained"
						fullWidth
						css={tw`shadow-md text-black font-semibold bg-white hover:bg-ir-multiStep-modal-button hover:text-white`}
						onClick={() => updateState("Poor")}
					>
						Poor
					</ModalButton>
				</div>
				<div css={tw`w-full`}>
					<ModalButton
						size="large"
						variant="contained"
						fullWidth
						css={tw`shadow-md text-black font-semibold bg-white hover:bg-ir-multiStep-modal-button hover:text-white`}
						onClick={() => updateState("Average")}
					>
						Average
					</ModalButton>
				</div>
			</div>
			<div css={tw`flex justify-between`}>
				<div css={tw`mr-4 w-full`}>
					<ModalButton
						size="large"
						variant="contained"
						fullWidth
						css={[
							tw`shadow-md text-black font-semibold bg-white hover:bg-ir-multiStep-modal-button hover:text-white`,
							css`
								border: 2px solid #1774ff;
							`,
						]}
						onClick={() => updateState("Good")}
					>
						Good
					</ModalButton>
				</div>
				<div css={tw`w-full`}>
					<ModalButton
						size="large"
						variant="contained"
						fullWidth
						css={tw`shadow-md text-black font-semibold bg-white hover:bg-ir-multiStep-modal-button hover:text-white`}
						onClick={() => updateState("Excellent")}
					>
						Excellent
					</ModalButton>
				</div>
			</div>
		</div>
	);
};

CreditScore.propTypes = {
	setMediaAlphaState: PropTypes.func.isRequired,
	isLastStep: PropTypes.bool.isRequired,
};

export default CreditScore;
