import * as constants from "@/config/constants";

export const setFilteringState = (filteringParameters) => (dispatch) => {
	dispatch({ type: constants.SHOW_FILTERING_TRANSITION });

	const hideTransitionAndApplyFilters = () => {
		dispatch({
			type: constants.FILTERING_PARAMETERS_SET,
			payload: filteringParameters,
		});
		dispatch({ type: constants.HIDE_FILTERING_TRANSITION });
	};

	setTimeout(() => {
		hideTransitionAndApplyFilters();
	}, constants.FILTERING_TRANSITION_DURATION);
};

export const resetFilteringState = () => (dispatch) => {
	dispatch({ type: constants.SHOW_FILTERING_TRANSITION });

	const hideTransitionAndApplyFilters = () => {
		dispatch({ type: constants.FILTERING_PARAMETERS_RESET });
		dispatch({ type: constants.HIDE_FILTERING_TRANSITION });
	};

	setTimeout(() => {
		hideTransitionAndApplyFilters();
	}, constants.FILTERING_TRANSITION_DURATION);
};

export const setModalFilteringState =
	(filteringParameters, vertical) => (dispatch) => {
		dispatch({ type: constants.SHOW_FILTERING_TRANSITION });

		const hideTransitionAndApplyFilters = () => {
			dispatch({
				type: constants.MODAL_FILTERING_PARAMETERS_SET,
				payload: {
					filteringParameters: filteringParameters,
					vertical: vertical,
				},
			});
			dispatch({ type: constants.HIDE_FILTERING_TRANSITION });
		};

		setTimeout(() => {
			hideTransitionAndApplyFilters();
		}, constants.FILTERING_TRANSITION_DURATION);
	};

export const setFilteringStateWithoutTransition =
	(filteringParameters) => (dispatch) => {
		dispatch({
			type: constants.FILTERING_PARAMETERS_SET,
			payload: filteringParameters,
		});
	};

export const deleteFilteringProperty = (key) => (dispatch) => {
	dispatch({ type: constants.DELETE_FILTERING_PROPERTY, payload: key });
};
