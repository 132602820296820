import { useContext } from "react";
import {
	getCompanyReviewPath,
	getCompanyVisitPath,
	getIsCompanyReviewLinkable,
} from "@/selectors";
import { useSelector } from "react-redux";

import { CompanyContext } from "../components/CompanyList/CompanyContext";

export const useOutboundPaths = () => {
	const { company, companyIndex } = useContext(CompanyContext);

	const isCompanyReviewLinkable = useSelector((state) =>
		getIsCompanyReviewLinkable(state, { company }),
	);

	const companyReviewPath = useSelector((state) =>
		getCompanyReviewPath(state, { company }),
	);

	const companyVisitPath = useSelector((state) =>
		getCompanyVisitPath(state, { company, companyIndex }),
	);

	return { isCompanyReviewLinkable, companyReviewPath, companyVisitPath };
};
