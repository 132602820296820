import { Component } from "react";
import { MORTGAGE_TYPE_FILTERING_KEY } from "@/config/site/favy";
import {
	getMultiStepModalConfig,
	getUsesMultiStepModal,
	getVertical,
} from "@/selectors";
import { setModalFilteringState } from "@/store/filtering/actions";
import { styled } from "@mui/material/styles";
import PropTypes, { oneOfType } from "prop-types";
import { default as ReactModal } from "react-modal2";
import { connect } from "react-redux";
import ScrollLock from "react-scrolllock";
import smoothscroll from "smoothscroll-polyfill";
import tw, { css } from "twin.macro";
import useSSR from "use-ssr";

import { ModalButton } from "./ModalButton";

const { isServer } = useSSR();
if (!isServer) {
	smoothscroll.polyfill();
}

/* global NOT_PROD TESTING */
const shouldFirePageViews = !NOT_PROD || TESTING;

const styles = {
	button: tw`shadow-md bg-[#359492] hover:bg-[#30b1ae] max-w-[110px] md:max-w-[170px] w-[46%] h-[42px] md:w-[170px] md:h-[85px] normal-case text-base md:text-xl font-semibold text-white`,
	icon: {
		purchase: css`
			:before {
				content: url("/react-images/favy/purchase.svg");
				position: absolute;
				width: 48px;
				height: 48px;
				top: -24px;
			}

			@media (max-width: 767px) {
				:before {
					width: 30px;
					height: 30px;
					top: -18px;
				}
			}
		`,
		refinance: css`
			:before {
				content: url("/react-images/favy/refinance.svg");
				position: absolute;
				width: 48px;
				height: 48px;
				top: -24px;
			}

			@media (max-width: 767px) {
				:before {
					width: 30px;
					height: 30px;
					top: -18px;
				}
			}
		`,
	},
};

const PREFIX = "MartgageModalButton";

const classes = {
	root: `${PREFIX}-root`,
};

const MortgageButton = styled(ModalButton)({
	[`&.${classes.root}`]: {
		"&.Mui-focusVisible": {
			boxShadow:
				"0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06) !important",
		},
	},
});

const mapDispatchToProps = {
	setModalFilteringState,
};

const mapStateToProps = (state) => ({
	vertical: getVertical(state),
	usesMultiStepModal: getUsesMultiStepModal(state),
	multiStepConfig: getMultiStepModalConfig(state),
});

export class MortgageModal extends Component {
	state = {
		isClosed: false,
		mortgageType: null,
	};

	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};

	closeModal = (mortgageType) => {
		if (shouldFirePageViews) {
			window.gtag("event", "click", {
				event_category: "mortgageModalInteraction",
				event_label: mortgageType,
			});
		}
		this.setState({ mortgageType }, () => {
			setTimeout(() => {
				this.props.setModalFilteringState(
					{ [MORTGAGE_TYPE_FILTERING_KEY]: mortgageType },
					this.props.vertical,
				);
				this.setState({ isClosed: true });
				// eslint-disable-next-line no-magic-numbers
			}, 500);
		});
		this.scrollToTop();
	};

	render() {
		const { isClosed, mortgageType } = this.state;
		const isOpen = !isClosed;
		const isPurchase = mortgageType === "purchase";
		const isRefinance = mortgageType === "refinance";

		return isOpen ? (
			<>
				<ScrollLock isActive />
				<ReactModal
					css={[
						tw`border-none rounded-lg max-w-xs sm:max-w-md m-auto flex items-center`,
						css`
							inset: 40px;
						`,
					]}
					onClose={() => null}
					closeOnBackdropClick={false}
					backdropStyles={{
						backgroundColor: "rgba(0, 0, 0, 0.6)",
						transition: "opacity .15s ease-out",
						zIndex: 100,
						display: "flex",
						position: "fixed",
						width: "100%",
						height: "100%",
						top: 0,
						left: 0,
					}}
					modalStyles={{
						outlineStyle: "none !important",
						outlineColor: "transparent !important",
					}}
					closeOnEsc={false}
				>
					<div
						id="multiStepModal"
						css={[
							tw`relative bg-white w-full rounded-lg max-w-[320px] md:max-w-[540px] px-4 py-6`,
							css`
								background: no-repeat
									url("/react-images/favy/mortgageModalBackgroundDesktop.svg");
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								overflow: hidden;
								:focus {
									outline-style: none !important;
									outline-color: transparent !important;
								}

								@media (max-width: 767px) {
									background: no-repeat
										url("/react-images/favy/mortgageModalBackgroundMobile.svg");
								}
							`,
						]}
					>
						<div css={tw`flex flex-col items-center mt-7`}>
							<div
								css={tw`text-center font-bold text-xs text-[#E1255B] uppercase mb-3`}
							>
								Mortgage Type
							</div>
							<div
								css={tw`mb-4 text-center font-bold w-[75%] md:w-7/12 text-black md:text-2xl`}
							>
								What type of mortgage do you need?
							</div>
						</div>
						<div
							css={tw`flex flex-col content-center items-center relative rounded-lg mt-2 md:mt-6`}
						>
							<div css={tw`w-full m-auto`}>
								<div css={tw`mt-3 mb-8`}>
									<div css={tw`flex justify-evenly`}>
										{mortgageType !== "refinance" && (
											<MortgageButton
												variant="contained"
												css={[
													styles.button,
													isPurchase && tw`bg-[#30b1ae] text-white`,
													styles.icon.purchase,
												]}
												onClick={() => this.closeModal("purchase")}
												classes={{
													root: classes.root,
												}}
											>
												Purchase
											</MortgageButton>
										)}
										{mortgageType !== "purchase" && (
											<MortgageButton
												variant="contained"
												css={[
													styles.button,
													isRefinance && tw`bg-[#30b1ae] text-white`,
													styles.icon.refinance,
												]}
												onClick={() => this.closeModal("refinance")}
												classes={{
													root: classes.root,
												}}
											>
												Refinance
											</MortgageButton>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</ReactModal>
			</>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MortgageModal);

MortgageModal.propTypes = {
	vertical: PropTypes.string.isRequired,
	appElement: PropTypes.string.isRequired,
	setModalFilteringState: PropTypes.func.isRequired,
	multiStepConfig: oneOfType([PropTypes.array, PropTypes.bool]),
	usesMultiStepModal: PropTypes.bool.isRequired,
};
