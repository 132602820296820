import { equals, flatten, keys, map, pipe, reject, uniq, values } from "ramda";

import { BB_SITE_CONFIGURATION } from "./site/bestbanks";
import { BCR_SITE_CONFIGURATION } from "./site/bestcreditreviews";
import { BE_SITE_CONFIGURATION } from "./site/besteducation";
import { BI_SITE_CONFIGURATION } from "./site/bestiras";
import { BR_SITE_CONFIGURATION } from "./site/bestrelocation";
import { BTR_SITE_CONFIGURATION } from "./site/besttaxreviews";
import { BW_SITE_CONFIGURATION } from "./site/bestwarranty";
import { FAVY_SITE_CONFIGURATION } from "./site/favy";
import { FTS_SITE_CONFIGURATION } from "./site/features";
import { HTSL_SITE_CONFIGURATION } from "./site/howtostartanllc";
import { IR_SITE_CONFIGURATION } from "./site/insuranceranked";
import { PC_SITE_CONFIGURATION } from "./site/professorcredit";
import { PR_SITE_CONFIGURATION } from "./site/psychicsreviews";
import { RC_SITE_CONFIGURATION } from "./site/ratecompare";
import { TS_SITE_CONFIGURATION } from "./site/taxhelpreviews";
import { TCR_SITE_CONFIGURATION } from "./site/thecreditreview";

// When refactoring and/or adding new routes, please first reference https://applieddev.atlassian.net/wiki/spaces/FUD/pages/542015489/Updating+React+App+Routes
// Route logic is handled in resources/src/store/routingParams/actions.js for Backward Compatibility as the old (a) or (m) is not supported by matchRoutes
export const ROUTE_PATHS = [
	{
		// path: "/:vertical/:targeted(a)/:filter/:mobile(mobile)/:mobileModal(modal)",
		// path: "/:vertical/:targeted(a)/:bestServices(best-services)/:mobile(mobile)/:mobileModal(modal)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:filter/:mobile(mobile)/:mobileModal(modal)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:bestServices(best-services)/:mobile(mobile)/:mobileModal(modal)",
		path: "/:vertical/:targeted/:filter/:mobile/:mobileModal",
	},
	{
		// path: "/:vertical/:targeted(a)/:filter/:modal(modal)",
		// path: "/:vertical/:targeted(a)/:bestServices(best-services)/:modal(modal)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:filter/:modal(modal)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:bestServices(best-services)/:modal(modal)",
		// path: "/:vertical/:targeted(a)/:filter/:mobile(mobile)",
		// path: "/:vertical/:targeted(a)/:bestServices(best-services)/:mobile(mobile)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:filter/:mobile(mobile)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:bestServices(best-services)/:mobile(mobile)",
		path: "/:vertical/:targeted/:filter/:mobile",
	},
	{
		// path: "/:vertical/:targeted(a)/:filter",
		// path: "/:vertical/:targeted(a)/:bestServices(best-services)",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:filter",
		// path: "/:vertical/:mediaAlphaTargeted(m)/:bestServices(best-services)",
		path: "/:vertical/:targeted/:filter",
	},
	{
		// path: "/:vertical/:targeted(a)",
		// path: "/:vertical/:mediaAlphaTargeted(m)",
		path: "/:vertical/:targeted",
	},
	{
		// path: "/:vertical",
		path: "/:vertical",
	},
];

export const TABLET_WIDTH = 767;
export const MOBILE_WIDTH = 640;

export const ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT = 2;

export const TCR_DOMAIN = "thecreditreview";
export const IR_DOMAIN = "insuranceranked";
export const THR_DOMAIN = "taxhelpreviews";
export const PC_DOMAIN = "professorcredit";
export const FVY_DOMAIN = "favy";
export const BW_DOMAIN = "bestwarranty";
export const BR_DOMAIN = "bestrelocation";
export const HTSL_DOMAIN = "howtostartanllc";
export const BI_DOMAIN = "bestiras";
export const BCR_DOMAIN = "bestcreditreviews";
export const BTR_DOMAIN = "besttaxreviews";
export const BB_DOMAIN = "bestbanks";
export const BE_DOMAIN = "besteducation";
export const PR_DOMAIN = "psychicsreviews";
export const FTS_DOMAIN = "features";
export const RC_DOMAIN = "ratecompare";

export const MEDIA_ALPHA_REQUEST = "MEDIA_ALPHA_REQUEST";
export const MEDIA_ALPHA_SUCCESS = "MEDIA_ALPHA_SUCCESS";
export const MEDIA_ALPHA_FAILURE = "MEDIA_ALPHA_FAILURE";

export const SHOW_FILTERING_TRANSITION = "SHOW_FILTERING_TRANSITION";
export const HIDE_FILTERING_TRANSITION = "HIDE_FILTERING_TRANSITION";
export const FILTERING_TRANSITION_DURATION = 250;

export const FILTERING_PARAMETERS_SET = "FILTERING_PARAMETERS_SET";
export const FILTERING_PARAMETERS_RESET = "FILTERING_PARAMETERS_RESET";
export const MODAL_FILTERING_PARAMETERS_SET = "MODAL_FILTERING_PARAMETERS_SET";
export const COMPANY_SPECIFIC_QUERY_PARAMETERS_SET =
	"COMPANY_SPECIFIC_QUERY_PARAMETERS_SET";
export const BATCH_SET_COMPANY_SPECIFIC_QUERY_PARAMS =
	"BATCH_SET_COMPANY_SPECIFIC_QUERY_PARAMS";
export const CLEAR_COMPANY_SPECIFIC_QUERY_PARAMS =
	"CLEAR_COMPANY_SPECIFIC_QUERY_PARAMS";

export const SET_MULTISTEP_MODAL_STATE = "SET_MULTISTEP_MODAL_STATE";

export const FEATURED_COMPANY = "featuredCompany";

export const VARIATIONS = "variations";
export const SET_FEATURED_COMPANY = "SET_FEATURED_COMPANY";
export const COMPANY_RANKING = "companyRanking";

export const MULTISTEP_MODAL = "multistepModal";

export const FILTERING_PARAMETERS_COUNT = "FILTERING_PARAMETERS_COUNT";

export const UPDATE_DERIVED_ROUTING_PARAMS = "UPDATE_DERIVED_ROUTING_PARAMS";

export const DELETE_FILTERING_PROPERTY = "DELETE_FILTERING_PROPERTY";

export const AGE_THRESHOLD = "ageThreshold";

export const ROUTING_PARAMS = "routingParams";
export const VERTICAL_INFORMATION = "verticalInformation";
export const MEDIA_ALPHA_FEED = "mediaAlphaFeed";
export const CUSTOM_FILTER = "customFilter";
export const CUSTOM_VISIT_PATH_QUERY_STRING = "visitPathQueryString";
export const CUSTOM_MAIN_BENEFITS_FIELD = "customMainBenefitsField";
export const CUSTOM_MAIN_BENEFITS_FIELD_BY_UI_FILTERING =
	"customMainBenefitsFieldByUiFiltering";
export const CUSTOM_RATING_KEY = "customRatingKey";
export const CUSTOM_RATING_KEY_BY_UI_FILTERING = "customRatingKeyByUiFiltering";
export const FILTERING = "filtering";
export const GEO_TARGETING = "geoTargeting";
export const IS_NOT_MODAL_FILTERED = "isNotModalFiltered";
export const IS_NOT_USER_FILTERED = "isNotUserFiltered";
export const IS_TRANSITION_APPLIED = "isTransitionApplied";
export const VARIATION_ID = "variationId";
export const COMPANY_QUERY_PARAMS = "companyQueryParams";

export const LAZY_LOADER_OFFSET = 350;

const dateObject = new Date();
export const CURRENT_YEAR = dateObject.getFullYear();

export const MINIMUM_QUERY_STRING_COUNT_VALUE_FOR_PAGINATION = 5;

export const DEFAULT_SERVICE_BLOCK_COLUMNS = [
	{ fields: "BBB Rating", label: "BBB Rating" },
	{ fields: "yearFounded", label: "Year Founded" },
];

export const DEFAULT_COMPANY_LIST_HEADER_PARTS = [
	"Provider",
	"BBB Rating",
	"Year Founded",
	"Why we recommend",
];

export const ALL_SITE_CONFIGURATIONS = {
	[TCR_DOMAIN]: TCR_SITE_CONFIGURATION,
	[IR_DOMAIN]: IR_SITE_CONFIGURATION,
	[THR_DOMAIN]: TS_SITE_CONFIGURATION,
	[PC_DOMAIN]: PC_SITE_CONFIGURATION,
	[FVY_DOMAIN]: FAVY_SITE_CONFIGURATION,
	[BW_DOMAIN]: BW_SITE_CONFIGURATION,
	[BR_DOMAIN]: BR_SITE_CONFIGURATION,
	[HTSL_DOMAIN]: HTSL_SITE_CONFIGURATION,
	[BI_DOMAIN]: BI_SITE_CONFIGURATION,
	[BCR_DOMAIN]: BCR_SITE_CONFIGURATION,
	[BTR_DOMAIN]: BTR_SITE_CONFIGURATION,
	[BB_DOMAIN]: BB_SITE_CONFIGURATION,
	[PR_DOMAIN]: PR_SITE_CONFIGURATION,
	[FTS_DOMAIN]: FTS_SITE_CONFIGURATION,
	[BE_DOMAIN]: BE_SITE_CONFIGURATION,
	[RC_DOMAIN]: RC_SITE_CONFIGURATION,
};

export const ALL_CONFIG_VERTICALS = pipe(
	values,
	map(keys),
	flatten,
	uniq,
	reject(equals("criticalOperationProperties")),
)(ALL_SITE_CONFIGURATIONS);

/* SITE constant set using DOMAIN value injected during bundle build via webpack-define-plugin */
export const SITE = DOMAIN;
export const SITE_INFORMATION = ALL_SITE_CONFIGURATIONS[SITE];
export const DEFAULT_NUMBER_OF_COMPANIES_TO_RENDER = 5;

export const DEFAULT_REVIEWS_PATH = "reviews";
export const DEFAULT_VISIT_PATH = "visit";

export const DEFAULT_PAGINATION_LABEL_PREVIOUS = "previous";
export const DEFAULT_PAGINATION_LABEL_NEXT = "next";
