import PropTypes from "prop-types";
import { Input, Label } from "react-aria-components";
import { useFormState } from "react-hook-form";

import { forwardRefComponent } from "../../../helpers/forwardRefComponent";
import { twMerge } from "../../../helpers/twMerge";
import { AriaFormController } from "../../BaseComponents/AriaFormController";

export const TextFieldControllerComponent = ({
	name,
	label,
	ref,
	className,
	...rest
}) => {
	const { errors } = useFormState({ name });
	const error = errors[name];

	return (
		<AriaFormController
			className={twMerge(
				"tw-relative tw-flex tw-w-full tw-max-w-[50%] tw-flex-col max-lg:tw-max-w-full",
				className,
			)}
			name={name}
			{...rest}
		>
			<Label className="tw-sr-only">{label}</Label>
			<Input
				className={`tw-w-full tw-rounded-lg tw-border-2 tw-border-solid tw-border-[#E4E4E5] tw-bg-white tw-px-3.5 tw-py-2.5 tw-text-base tw-font-semibold tw-leading-6 tw-arrow-hide placeholder:tw-text-[#747481] invalid:tw-ring invalid:tw-ring-red-500/50 focus:tw-ring focus:tw-ring-blue-500/50 focus-visible:tw-ring focus-visible:tw-ring-blue-500/50 focus-visible:invalid:tw-ring focus-visible:invalid:tw-ring-blue-500/50 ${
					error ? "tw-ring tw-ring-red-500/50" : ""
				}`}
				ref={ref}
			/>
		</AriaFormController>
	);
};

TextFieldControllerComponent.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	error: PropTypes.object,
	ref: PropTypes.object,
	className: PropTypes.string,
};

export const TextFieldController = forwardRefComponent(
	TextFieldControllerComponent,
);
