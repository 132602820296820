import * as constants from "@/config/constants";
import { omit, pathOr } from "ramda";

/* Whenever a property is added to the initial state, it needs to be added to the restrictedQueryParameters array in the selector
visitLinkQueryParamsSelector or it will be appended to visit links */

const initialState = {
	[constants.IS_NOT_MODAL_FILTERED]: true,
	[constants.IS_NOT_USER_FILTERED]: true,
	[constants.IS_TRANSITION_APPLIED]: false,
	[constants.FILTERING_PARAMETERS_COUNT]: 0,
};

const filteringReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case constants.FILTERING_PARAMETERS_SET:
			return {
				...state,
				...payload,
				[constants.IS_NOT_USER_FILTERED]: false,
				[constants.FILTERING_PARAMETERS_COUNT]: Object.keys(payload).length - 1,
			};
		case constants.FILTERING_PARAMETERS_RESET:
			return {
				...initialState,
				[constants.IS_NOT_USER_FILTERED]: false,
			};
		case constants.MODAL_FILTERING_PARAMETERS_SET:
			return {
				...state,
				...payload.filteringParameters,
				[constants.IS_NOT_MODAL_FILTERED]: false,
				[pathOr(
					"fromModal",
					[payload.vertical, "modalQueryParam"],
					constants.SITE_INFORMATION,
				)]: 1,
				[constants.FILTERING_PARAMETERS_COUNT]: 1,
			};
		case constants.SHOW_FILTERING_TRANSITION:
			return {
				...state,
				[constants.IS_TRANSITION_APPLIED]: true,
			};
		case constants.HIDE_FILTERING_TRANSITION:
			return {
				...state,
				[constants.IS_TRANSITION_APPLIED]: false,
			};
		case constants.DELETE_FILTERING_PROPERTY:
			return omit([payload], state);
		default:
			return state;
	}
};

export default filteringReducer;
