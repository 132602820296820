export const CREDIT_ASSISTANCE_PATH = "credit-assistance";

export const BCR_SITE_CONFIGURATION = {
	[CREDIT_ASSISTANCE_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		preventRenderingCompanyListHeader: true,
	},
};
