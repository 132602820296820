import * as constants from "@/config/constants";

import { errorHandler } from "../../helpers/errorHandler";

const initialState = {
	loading: false,
	feed: [],
};

const mediaAlphaReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case constants.MEDIA_ALPHA_REQUEST:
			return state;
		case constants.MEDIA_ALPHA_SUCCESS:
			return {
				...state,
				loading: false,
				feed: payload[constants.MEDIA_ALPHA_FEED],
			};
		case constants.MEDIA_ALPHA_FAILURE:
			errorHandler(payload.error);
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default mediaAlphaReducer;
