import { FVY_DOMAIN, IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import * as irConstants from "@/config/site/insuranceranked";
import * as tcrConstants from "@/config/site/thecreditreview";
import * as selectors from "@/selectors";
import { getProductDescription, getProductImages } from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import ProductDescription from "../ProductDescription";
import ColumnLayout from "./ColumnLayout";
import CompanyBenefitsList from "./CompanyBenefitsList";
import ExpandMobileServiceBenefits from "./ExpandMobileServiceBenefits";
import ImageGallery from "./ImageGallery";
import LegacyCallOut from "./LegacyCallOut";
import MediaAlphaHeadline from "./MediaAlphaHeadline";
import NewSoftwareLayout from "./NewSoftwareLayout";
import ServiceRightFooter from "./ServiceRightFooter";
// import components
import ServiceRightHeader from "./ServiceRightHeader";
import SoftwareLayout from "./SoftwareLayout";

const styles = {
	serviceRight: {
		revamped: css`
			width: 80%;
			padding: 1.5rem;
			align-items: center;
			float: right;
			display: flex;
			justify-content: space-between;

			@media (max-width: 1199px) {
				padding: 15px 0 15px 15px;
			}

			@media (max-width: 991px) {
				width: 100%;
				padding: 10px 2.5rem 1.25rem;
				align-items: flex-start;
				flex-wrap: wrap;
				${SITE === FVY_DOMAIN && tw`justify-center`}
			}

			@media (max-width: 767px) {
				padding: 1.25rem 2.5rem;
			}

			@media (max-width: 580px) {
				padding: 1.25rem 15px;
			}
		`,

		legacy: css`
			width: 60%;
			padding: 20px;
			align-items: center;
			float: right;
			display: flex;
			justify-content: space-between;

			@media (max-width: 1199px) {
				padding: 20px;
				width: 60%;
			}

			@media (max-width: 991px) {
				width: 100%;
				padding: 0 20px;
			}

			@media (max-width: 767px) {
				padding: 20px;
				width: 100%;
				flex-wrap: wrap;
				max-width: 100%;
			}

			@media (max-width: 500px) {
				padding: 10px;
				border-left: 0;
			}
		`,
		usesSoftwareDesign: css`
			flex-direction: column;
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: css`
					@media (max-width: 1199px) {
						padding: 15px 17px 15px 15px;
					}

					@media (max-width: 991px) {
						padding: 10px 2.5rem 1.25rem;
					}

					@media (max-width: 580px) {
						padding: 1.25rem 15px;
					}
				`,
			},

			[IR_DOMAIN]: {
				[irConstants.IR_LIFE_INSURANCE_DEFAULT_PATH]: css`
					@media (min-width: 991px) {
						padding-right: 0;
					}
				`,
				[irConstants.MEDICARE_ADVANTAGE_PATH]: css`
					@media (min-width: 992px) {
						padding-right: 0px;
					}
				`,
				[irConstants.MEDICARE_SUPPLEMENT_PATH]: css`
					@media (min-width: 992px) {
						padding-right: 0px;
					}
				`,
				[irConstants.MEDICARE_DEFAULT_PATH]: css`
					@media (min-width: 992px) {
						padding-right: 0px;
					}
				`,
				[irConstants.FINAL_EXPENSE_INSURANCE_PATH]: css`
					@media (min-width: 992px) {
						padding-right: 0px;
					}
				`,
				[irConstants.WHOLE_LIFE_INSURANCE_PATH]: css`
					@media (min-width: 992px) {
						padding-right: 0px;
					}
				`,
			},
		},
	},
	serviceRightContent: {
		legacy: css`
			max-width: 380px;
			transition: none;
			width: 100%;

			@media (max-width: 1199px) {
				max-width: 280px;
			}

			@media (max-width: 991px) {
				margin-bottom: 0;
				max-width: 380px;
			}

			@media (max-width: 767px) {
				margin-left: auto;
				margin-right: auto;
			}

			@media (max-width: 500px) {
				margin-bottom: 0;
				max-width: 100%;
			}
		`,
		revamped: css`
			max-width: 690px;
			transition: none;
			width: 100%;

			@media (max-width: 991px) {
				flex-direction: row-revers;
				margin-bottom: 0;
			}

			@media (max-width: 767px) {
				margin-left: auto;
				margin-right: auto;
			}

			@media (max-width: 500px) {
				margin-bottom: 0;
			}
		`,
		usesSoftwareDesign: css`
			max-width: unset;
		`,
	},

	keyInformation: {
		revamped: css`
			display: flex;

			@media (max-width: 991px) {
				flex-wrap: wrap;
			}

			@media (max-width: 580px) {
				justify-content: space-between;
			}
		`,

		legacy: css`
			display: block;
		`,
		[IR_DOMAIN]: {
			[irConstants.IR_AUTO_INSURANCE_DEFAULT_PATH]: tw`flex flex-col-reverse`,
		},
		[FVY_DOMAIN]: {
			allVerticals: css`
				@media (max-width: 991px) {
					justify-content: space-around;
				}

				@media (max-width: 580px) {
					justify-content: center;
				}
			`,
		},
	},
};

const ExpandBenefitsSection = ({
	// eslint-disable-next-line react/prop-types
	shouldRender,
	// eslint-disable-next-line react/prop-types
	expandServiceContent,
	// eslint-disable-next-line react/prop-types
	serviceContentSelectorStyle,
}) =>
	shouldRender ? (
		<ExpandMobileServiceBenefits
			expandServiceContent={expandServiceContent}
			serviceContentSelectorStyle={serviceContentSelectorStyle}
		/>
	) : null;

export const ServiceRightLayout = ({
	company,
	companyIndex,
	serviceContentDisplayStyle,
	expandServiceContent,
	serviceContentSelectorStyle,
	isFeatured,
}) => {
	const vertical = useSelector(selectors.getVertical);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const isMediaAlphaPublisherVertical = useSelector(
		selectors.getIsMediaAlphaPublisherVertical,
	);
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const productDescription = useSelector((state) =>
		getProductDescription(state, { company }),
	);
	const usesNewSoftwareDesign = useSelector(selectors.getUsesNewSoftwareDesign);
	const isProductListingPage = useSelector(selectors.getIsProductListingPage);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);

	const BenefitsList = () => (
		<CompanyBenefitsList
			company={company}
			companyIndex={companyIndex}
			serviceContentDisplayStyle={serviceContentDisplayStyle}
		>
			<MediaAlphaHeadline company={company} />
		</CompanyBenefitsList>
	);

	const DefaultLayout = ({ site, usesSoftwareDesign }) =>
		![FVY_DOMAIN].includes(site) && !usesSoftwareDesign ? (
			<>
				<ColumnLayout company={company} companyIndex={companyIndex} />
				<BenefitsList />
			</>
		) : null;
	DefaultLayout.propTypes = {
		site: PropTypes.string.isRequired,
		usesSoftwareDesign: PropTypes.bool.isRequired,
	};

	const FavyLayout = ({ site, usesSoftwareDesign, company }) => {
		const productImages = useSelector((state) =>
			getProductImages(state, { company }),
		);
		if (site === FVY_DOMAIN && !usesSoftwareDesign) {
			return (
				<>
					<BenefitsList />
					<ExpandBenefitsSection
						shouldRender={
							isMediaAlphaPublisherVertical
								? false
								: companyIndex !== 1 && serviceContentSelectorStyle !== "none"
						}
						serviceContentSelectorStyle={serviceContentSelectorStyle}
						expandServiceContent={expandServiceContent}
					/>
					{productImages && !isProductListingPage ? (
						<ImageGallery company={company} isFeatured={isFeatured} />
					) : (
						<ColumnLayout company={company} companyIndex={companyIndex} />
					)}
				</>
			);
		} else return null;
	};

	FavyLayout.propTypes = {
		site: PropTypes.string.isRequired,
		usesSoftwareDesign: PropTypes.bool.isRequired,
		company: PropTypes.object.isRequired,
	};

	return (
		<div
			css={[
				isServiceBlockLegacyStyled
					? styles.serviceRight.legacy
					: styles.serviceRight.revamped,
				path(["serviceRight", "domainLevelOverrides", SITE, vertical], styles),
				usesSoftwareDesign && styles.serviceRight.usesSoftwareDesign,
				isProductListingPage && tw`!p-0`,
				usesSimplifiedDesign &&
					css`
						@media (min-width: 1023px) {
							padding: 15px 15px 15px 15px;
						}
						@media (max-width: 580px) {
							padding: 0.75rem 15px 1.25rem 15px;
						}
					`,
			]}
		>
			<div
				css={[
					isServiceBlockLegacyStyled
						? styles.serviceRightContent.legacy
						: styles.serviceRightContent.revamped,
					path(
						["serviceRightContent", "domainLevelOverrides", SITE, vertical],
						styles,
					),
					usesSoftwareDesign && styles.serviceRightContent.usesSoftwareDesign,
					isProductListingPage && tw`pt-6 pb-6`,
				]}
			>
				<ServiceRightHeader companyIndex={companyIndex} company={company} />
				{!usesSoftwareDesign && productDescription && (
					<ProductDescription company={company} />
				)}
				<LegacyCallOut company={company} />
				<div
					css={[
						isServiceBlockLegacyStyled
							? styles.keyInformation.legacy
							: styles.keyInformation.revamped,
						path(["keyInformation", SITE, vertical], styles),
						path(["keyInformation", SITE, "allVerticals"], styles),
					]}
				>
					<FavyLayout
						site={SITE}
						usesSoftwareDesign={usesSoftwareDesign}
						company={company}
					/>
					<DefaultLayout site={SITE} usesSoftwareDesign={usesSoftwareDesign} />
					{usesNewSoftwareDesign ? (
						<NewSoftwareLayout
							company={company}
							companyIndex={companyIndex}
							renderAllBenefits={
								serviceContentDisplayStyle === "flex" ||
								("block" && serviceContentSelectorStyle === "none")
							}
							expandBenefits={() => (
								<ExpandBenefitsSection
									shouldRender={
										isMediaAlphaPublisherVertical
											? false
											: companyIndex !== 1 &&
												(SITE !== FVY_DOMAIN || usesSoftwareDesign) &&
												serviceContentSelectorStyle !== "none"
									}
									serviceContentSelectorStyle={serviceContentSelectorStyle}
									expandServiceContent={expandServiceContent}
								/>
							)}
						/>
					) : (
						<SoftwareLayout
							company={company}
							companyIndex={companyIndex}
							renderAllBenefits={
								serviceContentDisplayStyle === "flex" ||
								("block" && serviceContentSelectorStyle === "none")
							}
						/>
					)}
				</div>
				{!usesNewSoftwareDesign && (
					<ExpandBenefitsSection
						shouldRender={
							isMediaAlphaPublisherVertical
								? false
								: companyIndex !== 1 &&
									(SITE !== FVY_DOMAIN || usesSoftwareDesign) &&
									serviceContentSelectorStyle !== "none"
						}
						serviceContentSelectorStyle={serviceContentSelectorStyle}
						expandServiceContent={expandServiceContent}
					/>
				)}
			</div>
			{!usesNewSoftwareDesign && (
				<ServiceRightFooter company={company} companyIndex={companyIndex} />
			)}
		</div>
	);
};

export default ServiceRightLayout;

ServiceRightLayout.propTypes = {
	company: PropTypes.object.isRequired,
	serviceContentDisplayStyle: PropTypes.any.isRequired,
	expandServiceContent: PropTypes.func.isRequired,
	serviceContentSelectorStyle: PropTypes.any,
	companyIndex: PropTypes.number.isRequired,
	isFeatured: PropTypes.bool,
};
