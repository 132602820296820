import PropTypes from "prop-types";

import { Icon } from "../../../../components/BaseComponents/Icon";

export const DuoToneSearchIcon = ({
	curveColor = "currentColor",
	...props
}) => (
	<Icon fill="none" viewBox="0 0 32 32" {...props}>
		<path
			fill={curveColor}
			fillRule="evenodd"
			d="M13 7a6 6 0 0 0-6 6 1 1 0 1 1-2 0 8 8 0 0 1 8-8 1 1 0 1 1 0 2Z"
			clipRule="evenodd"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M13 1a12 12 0 1 0 7.75 21.16l7.54 7.55a1 1 0 0 0 1.42-1.42l-7.55-7.54A12 12 0 0 0 13 1ZM3 13a10 10 0 1 1 20 0 10 10 0 0 1-20 0Z"
			clipRule="evenodd"
		/>
	</Icon>
);

DuoToneSearchIcon.propTypes = {
	curveColor: PropTypes.string,
};
