import PropTypes from "prop-types";

import { Icon } from "../../../../components/BaseComponents/Icon";

export const DollarIcon = ({ bgColor = "tw-text-white", ...props }) => (
	<Icon fill="none" viewBox="0 0 16 16" {...props}>
		<path
			fill="currentColor"
			className={bgColor}
			d="M8 16A8 8 0 108 0a8 8 0 000 16z"
		/>
		<path
			fill="currentColor"
			d="M10.616 9.552c0 1.164-.924 2.124-2.328 2.208v1.02H7.52v-1.056C6.2 11.52 5.36 10.536 5.372 9.48h1.224c0 .648.552 1.248 1.464 1.248.852 0 1.32-.516 1.32-1.128 0-.516-.372-.888-1.128-1.092l-.96-.24C5.684 7.86 5.42 6.924 5.42 6.3c0-1.152.936-1.956 2.1-2.112v-.972h.768v.972c1.248.132 2.1 1.008 2.088 2.208H9.152c.012-.684-.492-1.176-1.236-1.176-.732 0-1.26.456-1.26 1.068 0 .24.072.732.96.936l.936.228c1.284.324 2.064 1.032 2.064 2.1z"
		/>
	</Icon>
);

DollarIcon.propTypes = {
	bgColor: PropTypes.string,
};
