import { Icon } from "../../../../components/BaseComponents/Icon";

export const RightArrowIcon = (props) => (
	<Icon fill="none" {...props}>
		<path
			d="M19 13H5M14.182 8L19 13l-4.818 5"
			stroke="currentColor"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Icon>
);
