import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseAmazonDiscount = (props) => {
	const { company } = useContext(CompanyContext);

	const discount = company?.fields?.discount;
	if (!discount) return null;

	return (
		<div className={props?.wrapper}>
			<p className={props?.text}>{Math.floor(discount)}% Off</p>
		</div>
	);
};

BaseAmazonDiscount.propTypes = {
	wrapper: PropTypes.string,
	text: PropTypes.string,
};
