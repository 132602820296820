// import selectors
import { getPrice } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw from "twin.macro";

export const Price = ({ company }) => {
	const price = useSelector((state) => getPrice(state, { company }));

	return price ? (
		<div css={tw`w-full text-center font-bold text-2xl`}>{price}</div>
	) : null;
};

export default Price;

Price.propTypes = {
	company: PropTypes.object.isRequired,
};
