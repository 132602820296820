import { useContext } from "react";
// import constants
import { FVY_DOMAIN, IR_DOMAIN, SITE } from "@/config/constants";
import { IR_AUTO_INSURANCE_DEFAULT_PATH } from "@/config/site/insuranceranked";
import { FINANCIAL_ADVISORS_PATH } from "@/config/site/thecreditreview";
// import selectors
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path, pathOr } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import { CompanyContext } from "../../CompanyList/CompanyContext";
import ReviewWidgetSection from "./ReviewWidgetSection";
// import components
import ServiceLeftLink from "./ServiceLeftLink";

const styles = {
	linkContents: {
		baseStyles: css`
			display: flex;
			width: 100%;
			justify-content: center;
			flex-wrap: wrap;

			@media (max-width: 991px) {
				justify-content: flex-start;
			}

			@media (max-width: 580px) {
				flex-wrap: unset;
			}
		`,
		usesReviewWidget: {
			base: css`
				@media (max-width: 991px) {
					align-items: center;
					justify-content: space-between;
				}
			`,
			[FVY_DOMAIN]: {
				base: css`
					@media (max-width: 991px) {
						width: 100% !important;
						justify-content: space-around !important;
					}

					@media (max-width: 580px) {
						flex-wrap: wrap;
					}
				`,
				software: css`
					@media (max-width: 580px) {
						flex-wrap: nowrap;
					}
				`,
			},
		},
		noReviewWidget: css`
			@media (max-width: 991px) {
				justify-content: flex-start;
			}

			@media (max-width: 767px) {
				justify-content: center;
			}
		`,

		domainLevelOverrides: {
			[IR_DOMAIN]: {
				[IR_AUTO_INSURANCE_DEFAULT_PATH]: css`
					@media (max-width: 991px) {
						justify-content: center;
					}
				`,
			},
			[FVY_DOMAIN]: {
				allVerticals: css`
					width: 160px;
				`,
			},
		},
	},
	logoWrapper: {
		baseStyles: css`
			width: 100%;
			max-width: 300px;

			@media (min-width: 992px) {
				text-align: center;
			}

			@media (max-width: 991px) {
				text-align: left;
			}

			@media (max-width: 767px) {
				text-align: center;
			}

			@media (max-width: 580px) {
				max-width: 100%;
				text-align: center;
			}
		`,
		domainLevelOverrides: {
			[IR_DOMAIN]: {
				[IR_AUTO_INSURANCE_DEFAULT_PATH]: css`
					@media (max-width: 991px) {
						text-align: center;
					}
				`,
			},
			[FVY_DOMAIN]: {
				software: css`
					@media (max-width: 991px) {
						width: unset;
					}
				`,
			},
		},
	},
};

export const UpdatedLogoLink = ({ companyIndex, children }) => {
	const { company } = useContext(CompanyContext);

	const vertical = useSelector(selectors.getVertical);
	const isAnyReviewWidgetRendered = useSelector(
		selectors.getIsAnyReviewWidgetRendered,
	);
	const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const isTrustpilotWidgetRendered = useSelector(
		selectors.getIsTrustpilotWidgetRendered,
	);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);

	return (
		<ServiceLeftLink company={company} companyIndex={companyIndex}>
			<div
				css={[
					styles.linkContents.baseStyles,
					isAnyReviewWidgetRendered
						? [
								styles.linkContents.usesReviewWidget.base,
								path(
									["linkContents", "usesReviewWidget", SITE, "base"],
									styles,
								),
							]
						: styles.linkContents.noReviewWidget,
					usesSoftwareDesign &&
						isTrustpilotWidgetRendered &&
						path(
							["linkContents", "usesReviewWidget", SITE, "software"],
							styles,
						),
					path(
						["linkContents", "domainLevelOverrides", SITE, vertical],
						styles,
					),
					path(
						["linkContents", "domainLevelOverrides", SITE, "allVerticals"],
						styles,
					),
					usesSimplifiedDesign && [
						styles.linkContents.usesReviewWidget.base,
						path(["linkContents", "usesReviewWidget", SITE, "base"], styles),
						css`
							@media (max-width: 580px) {
								flex-wrap: nowrap !important;
							}
							width: 100% !important;
						`,
					],
				]}
			>
				<div
					css={[
						styles.logoWrapper.baseStyles,
						pathOr(
							path(
								["logoWrapper", "domainLevelOverrides", SITE, "allVerticals"],
								styles,
							),
							["logoWrapper", "domainLevelOverrides", SITE, vertical],
							styles,
						),
						isTrustpilotWidgetRendered &&
							usesSoftwareDesign &&
							path(
								["logoWrapper", "domainLevelOverrides", SITE, "software"],
								styles,
							),
						vertical === FINANCIAL_ADVISORS_PATH && tw`pt-[32px]`,
					]}
				>
					{children}
				</div>
				{(usesSoftwareDesign && isTrustpilotWidgetRendered) ||
				!usesSoftwareDesign ? (
					<ReviewWidgetSection company={company} />
				) : null}
				{usesSimplifiedDesign && <ReviewWidgetSection company={company} />}
			</div>
		</ServiceLeftLink>
	);
};

UpdatedLogoLink.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
export default UpdatedLogoLink;
