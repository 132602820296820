import { Rating } from "@mui/material";
import PropTypes from "prop-types";

import { useWebReviewScore } from "../../hooks/useWebReviewScore";

const BaseGridRatingHorizontal = ({ style, starSize, classNames }) => {
	const { score, ratingLabel } = useWebReviewScore();
	if (score === 0) return null;

	return (
		<div css={style?.wrapper} className={classNames?.wrapper}>
			<p css={style?.score} className={classNames?.score}>
				{score.toFixed(1)}
			</p>
			<div css={style?.stars} className={classNames?.stars}>
				<Rating
					css={style?.starColor}
					className={classNames?.starColor}
					precision={0.1}
					readOnly
					defaultValue={score}
					size={starSize}
				/>
				<p css={style?.count} className={classNames?.count}>
					{ratingLabel}
				</p>
			</div>
		</div>
	);
};

export default BaseGridRatingHorizontal;

BaseGridRatingHorizontal.propTypes = {
	style: PropTypes.object,
	classNames: PropTypes.object,
	starSize: PropTypes.string,
};
