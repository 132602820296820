import { UPDATE_DERIVED_ROUTING_PARAMS } from "@/config/constants";

const initialState = {};

const paramReducer = (state = initialState, { type, payload }) => {
	if (type === UPDATE_DERIVED_ROUTING_PARAMS) {
		return payload.derivedRouteParams;
	}
	return state;
};

export default paramReducer;
