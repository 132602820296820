import { useContext } from "react";
// import selectors
import { getIsServiceBlockLegacyStyled } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CompanyContext } from "../../CompanyList/CompanyContext";
import CompanyLogo from "./CompanyLogo";
// import components
import LegacyLogoLink from "./LegacyLogoLink";
import UpdatedLogoLink from "./UpdatedLogoLink";

export const LogoSection = ({ companyIndex }) => {
	const { company } = useContext(CompanyContext);
	const isServiceBlockLegacyStyled = useSelector(getIsServiceBlockLegacyStyled);

	return isServiceBlockLegacyStyled ? (
		<LegacyLogoLink company={company} companyIndex={companyIndex}>
			<CompanyLogo company={company} />
		</LegacyLogoLink>
	) : (
		<UpdatedLogoLink company={company} companyIndex={companyIndex}>
			<CompanyLogo company={company} />
		</UpdatedLogoLink>
	);
};

LogoSection.propTypes = {
	companyIndex: PropTypes.number.isRequired,
};
export default LogoSection;
