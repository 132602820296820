import PropTypes from "prop-types";
import tw from "twin.macro";

import { ModalButton } from "../../ModalButton";

/* eslint-disable no-magic-numbers */

const Age = (props) => {
	const { setMediaAlphaState, isLastStep } = props;

	const updateState = (val) => {
		setMediaAlphaState({ age: val }, isLastStep);
	};

	return (
		<div css={tw`mt-4 mb-8`}>
			<div css={tw`mb-8 text-center font-semibold text-black leading-5`}>
				What&apos;s your current age?
			</div>
			<div css={tw`mb-4 flex justify-between`}>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(18)}
				>
					&#60; 18 &nbsp;
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(21)}
				>
					18-24
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(30)}
				>
					25-34
				</ModalButton>
			</div>
			<div css={tw`mb-4 flex justify-between`}>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(40)}
				>
					35-44
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(50)}
				>
					45-54
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(60)}
				>
					55-64
				</ModalButton>
			</div>
			<div css={tw`mb-4 flex justify-center`}>
				<ModalButton
					size="large"
					variant="contained"
					fullWidth
					css={tw`shadow-md bg-white text-black sm:pl-30px sm:pr-30px font-semibold hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(70)}
				>
					65+
				</ModalButton>
			</div>
		</div>
	);
};

Age.propTypes = {
	setMediaAlphaState: PropTypes.func.isRequired,
	isLastStep: PropTypes.bool.isRequired,
};

export default Age;
