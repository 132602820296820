import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { MOBILE_WIDTH } from "../../../config/constants";
import {
	BAD_CREDIT_RATING,
	BAD_CREDIT_URL_PARAMETER,
	CREDIT_QUALITY_FILTERING_KEY,
	EMERGENCY_LOANS_URL_PARAMETER,
	EXCELLENT_CREDIT_RATING,
	EXCELLENT_CREDIT_URL_PARAMETER,
	FAIR_CREDIT_RATING,
	FAIR_CREDIT_URL_PARAMETER,
	FAST_LOANS_URL_PARAMETER,
	FIVE_THOUSAND_URL_PARAMETER,
	GOOD_CREDIT_RATING,
	GOOD_CREDIT_URL_PARAMETER,
	ONE_THOUSAND_URL_PARAMETER,
	QUICK_LOANS_URL_PARAMETER,
	TEN_THOUSAND_URL_PARAMETER,
	TWENTY_FIVE_THOUSAND_URL_PARAMETER,
} from "../../../config/site/thecreditreview";
import { NativeSelectDropdownIcon } from "../../client/assets/icons/insuranceranked/NativeSelectDropdownIcon";
import { getCreditQuality, getTargetedSlug } from "../../selectors";
import { setFilteringState } from "../../store/filtering/actions";

const DEFAULT_CREDIT_QUALITY = "default";

const PERSONAL_LOANS_TARGETED_PAGE_SLUGS = {
	[BAD_CREDIT_URL_PARAMETER]: BAD_CREDIT_RATING,
	[FAST_LOANS_URL_PARAMETER]: BAD_CREDIT_RATING,
	[QUICK_LOANS_URL_PARAMETER]: BAD_CREDIT_RATING,
	[EMERGENCY_LOANS_URL_PARAMETER]: BAD_CREDIT_RATING,
	[FAIR_CREDIT_URL_PARAMETER]: FAIR_CREDIT_RATING,
	[GOOD_CREDIT_URL_PARAMETER]: GOOD_CREDIT_RATING,
	[EXCELLENT_CREDIT_URL_PARAMETER]: EXCELLENT_CREDIT_RATING,
	[ONE_THOUSAND_URL_PARAMETER]: BAD_CREDIT_RATING,
	[FIVE_THOUSAND_URL_PARAMETER]: GOOD_CREDIT_RATING,
	[TEN_THOUSAND_URL_PARAMETER]: GOOD_CREDIT_RATING,
	[TWENTY_FIVE_THOUSAND_URL_PARAMETER]: GOOD_CREDIT_RATING,
};

const options = [
	{ value: BAD_CREDIT_RATING, label: `Poor (350 - 629)` },
	{ value: FAIR_CREDIT_RATING, label: `Fair (630 - 689)` },
	{ value: GOOD_CREDIT_RATING, label: `Good (690 - 719)` },
	{ value: EXCELLENT_CREDIT_RATING, label: `Excellent (720 - 850)` },
];

export const PersonalLoansFilter = ({ innerWidth }) => {
	const targetedSlug = useSelector(getTargetedSlug);
	const dispatch = useDispatch();

	const targetedCreditQuality = useMemo(() => {
		if (targetedSlug && PERSONAL_LOANS_TARGETED_PAGE_SLUGS[targetedSlug]) {
			return PERSONAL_LOANS_TARGETED_PAGE_SLUGS[targetedSlug];
		}

		return DEFAULT_CREDIT_QUALITY;
	}, [targetedSlug]);

	const selectedCreditQuality =
		useSelector(getCreditQuality) || targetedCreditQuality;

	useEffect(() => {
		if (targetedCreditQuality === DEFAULT_CREDIT_QUALITY) return;

		dispatch(
			setFilteringState({
				[CREDIT_QUALITY_FILTERING_KEY]: targetedCreditQuality,
				isNotUserFiltered: false,
			}),
		);
	}, [dispatch, targetedCreditQuality]);

	const handleChange = (event) => {
		const value = event.target.value;
		dispatch(
			setFilteringState({
				[CREDIT_QUALITY_FILTERING_KEY]: value,
				isNotUserFiltered: false,
			}),
		);
	};

	if (innerWidth <= MOBILE_WIDTH) return null;

	return (
		<div>
			<div className="tw-relative tw-mt-[-41px] tw-w-full tw-bg-white tw-text-[100%] tw-shadow-[0_4px_4px_0_rgba(0,0,0,0.07)] md:tw-mt-[-1px]">
				<div className="tw-relative tw-top-[24px] tw-mx-auto tw-mb-[40px] tw-flex tw-w-full tw-max-w-[500px] tw-items-center tw-rounded-[5px] tw-bg-white tw-p-[15px] tw-shadow-[2px_1px_20px_#d3d3d3] md:tw-top-[30px] md:tw-mb-0 md:tw-max-w-[690px] desktop:tw-max-w-[910px] lg2:tw-max-w-[1110px]">
					<div className="tw-flex tw-h-[40px] desktop:tw-w-[325px] lg2:tw-w-[350px]">
						<div className="tw-relative tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-[3px] tw-border tw-border-solid tw-border-[#d6dbde] tw-pb-[6px] tw-pl-[48px] tw-pr-[6px] tw-pt-[5px]">
							<label
								htmlFor={`${CREDIT_QUALITY_FILTERING_KEY}_Input`}
								className="tw-mb-0 tw-mr-[15px] tw-whitespace-nowrap tw-font-[AvenirNextLTPro-Demi] tw-text-[14px] tw-font-semibold tw-leading-[1.4375em] tw-tracking-[0.00938em] tw-text-black before:tw-absolute before:tw-left-[12px] before:tw-top-[6px] before:tw-h-[26px] before:tw-w-[26px] before:tw-bg-[url('/public/react-images/thecreditreview/creditScoreIcon.svg')] before:tw-bg-no-repeat"
							>
								Credit Score
							</label>
							<div className="tw-relative tw-inline-flex">
								<select
									className="tw-box-content tw-h-[1.4375em] tw-w-full tw-cursor-pointer tw-appearance-none tw-border-none tw-bg-none tw-pb-[5px] tw-pl-0 tw-pr-[24px] tw-pt-[4px] tw-font-[AvenirNextLTPro-Demi] tw-text-[14px] tw-tracking-[0.00938em] tw-text-black"
									onChange={handleChange}
									value={selectedCreditQuality}
									id={`${CREDIT_QUALITY_FILTERING_KEY}_Input`}
								>
									<option value={DEFAULT_CREDIT_QUALITY} disabled>
										Good (690 - 719)
									</option>
									{options.map((option, idx) => (
										<option key={idx} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
								<NativeSelectDropdownIcon className="tw-pointer-events-none tw-absolute tw-right-0 tw-top-[calc(50%-0.5em)] tw-text-2xl tw-text-[#1d8ce0]" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

PersonalLoansFilter.propTypes = {
	innerWidth: PropTypes.number.isRequired,
};
