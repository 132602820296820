/* eslint-disable no-magic-numbers */
import PropTypes from "prop-types";
import tw from "twin.macro";

import { ModalButton } from "../../ModalButton";

const Vehicles = (props) => {
	const { setMediaAlphaState, isLastStep } = props;

	const updateState = (val) => {
		const vehiclesArray = Array(val).fill([]);
		setMediaAlphaState({ vehicles: vehiclesArray }, isLastStep);
	};

	return (
		<div css={tw`mt-4 mb-8`}>
			<div css={tw`mb-8 text-center font-semibold text-black leading-5`}>
				How many vehicles will you be insuring?
			</div>
			<div css={tw`flex justify-between`}>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md text-black font-semibold bg-white pl-35px pr-35px sm:pl-40px sm:pr-40px hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(1)}
				>
					1
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md text-black font-semibold bg-white pl-35px pr-35px sm:pl-40px sm:pr-40px hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(2)}
				>
					2
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md text-black font-semibold bg-white pl-35px pr-35px sm:pl-40px sm:pr-40px hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState(3)}
				>
					3+
				</ModalButton>
			</div>
		</div>
	);
};

Vehicles.propTypes = {
	setMediaAlphaState: PropTypes.func.isRequired,
	isLastStep: PropTypes.bool.isRequired,
};

export default Vehicles;
