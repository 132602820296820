import PropTypes from "prop-types";

import { SaleHighlightStarIcon } from "../../client/assets/icons/favy/SaleHighlightStarIcon";
import { twMerge } from "../../helpers/twMerge";
import { BaseSaleHighlight } from "../BaseComponents/BaseSaleHighlight";

export const AmazonSaleHighlight = ({ className, ...props }) => {
	const currentDate = new Date();
	const formattedDate = currentDate.toLocaleString("default", {
		month: "long",
		year: "numeric",
	});

	return (
		<BaseSaleHighlight
			wrapper={twMerge(
				"tw-w-full tw-bg-[#E1255B] tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mb-2 tw-rounded-lg",
				className,
			)}
			contentWrapper="tw-text-white tw-font-semibold tw-text-lg tw-leading-6"
			icon={<SaleHighlightStarIcon className="tw-text-2xl tw-text-white" />}
			{...props}
		>
			{formattedDate} Sales
		</BaseSaleHighlight>
	);
};

AmazonSaleHighlight.propTypes = {
	className: PropTypes.string,
};
