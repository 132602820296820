import { useLayoutEffect } from "react";
import { ROUTE_PATHS, UPDATE_DERIVED_ROUTING_PARAMS } from "@/config/constants";
import { useDispatch } from "react-redux";
import { matchRoutes, useLocation } from "react-router-dom";

const updateParamsActionCreator = (derivedRouteParams) => ({
	type: UPDATE_DERIVED_ROUTING_PARAMS,
	payload: { derivedRouteParams },
});

const deriveRouteParams = (location) => {
	const matchedRoutesArray = matchRoutes(ROUTE_PATHS, location.pathname);
	/* Checking length as matchRoutes returns an array with a single matched route, or [] if no route was matched */
	const params =
		matchedRoutesArray.length === 1 ? matchedRoutesArray[0].params : {};

	/* Destructure params from the matched route */
	const { vertical, filter, targeted, mobileModal, mobile } = params;
	/* Derive params if needed */
	const isTargetedPath = targeted === "a";
	const isMediaAlphaTargetedPath = targeted === "m";
	const isBestServicesPath = filter === "best-services";
	const isModalUrlParamMatched = mobile === "modal";
	const isMobileTargetedPath = mobile === "mobile";
	const isMobileModalUrlParamMatched = !!mobileModal;

	/* Add params and/or derived params to the payload being dispatched */
	return {
		vertical,
		isTargetedPath,
		isMediaAlphaTargetedPath,
		isBestServicesPath,
		filter: isBestServicesPath ? undefined : filter,
		isModalUrlParamMatched,
		isMobileTargetedPath,
		isMobileModalUrlParamMatched,
	};
};

/* More information in regards to routing params and Redux: https://applieddev.atlassian.net/wiki/spaces/FUD/pages/542015489/Updating+React+App+Routes */
export const useSyncDerivedRoutingParamsToStore = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	// State will be updated useing `useLocation` hook
	// Everytime URL changes state gets updated like `history.listen`
	useLayoutEffect(() => {
		dispatch(updateParamsActionCreator(deriveRouteParams(location)));
	}, [location]);
};
