// import constants
import { FVY_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
// import selectors
import * as selectors from "@/selectors";
import { Rating } from "@mui/material";
import PropTypes from "prop-types";
import { path, prop } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

const styles = {
	reviewContainer: {
		base: css`
			max-width: 160px;
			color: #232227;
			font-size: 14px;

			margin-top: 20px;

			@media (max-width: 991px) {
				margin-top: 0px;
			}

			@media (max-width: 640px) {
				margin-right: 10px;
			}
		`,
		usesSoftwareDesign: css`
			margin-top: 0px;
			max-width: unset;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			div:nth-of-type(1) {
				flex-direction: row-reverse;

				div:nth-of-type(1) {
					margin-left: 5px;
				}
			}
			div {
				display: inline-flex;
			}
		`,
		borrowsSoftwareDesign: css`
			flex-direction: column;
			div:nth-of-type(1) {
				flex-direction: column;
				align-items: center;
			}
		`,
		domainLevelOverrides: {
			[FVY_DOMAIN]: {
				allVerticals: css`
					align-self: end;

					@media (max-width: 991px) and (min-width: 581px) {
						margin-right: 5rem;
					}
				`,
			},
		},
	},
	reviewDiv: {
		base: css`
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
			margin-bottom: 3px;
		`,
		usesSoftwareDesign: tw`mb-0`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: css`
				align-items: center;
			`,
		},
	},
	reviewCountSpan: {
		base: css`
			font-weight: 700;
			font-size: 14px;
			letter-spacing: unset;
			font-family: AvenirNextLTPro-Bold;
		`,
		usesSoftwareDesign: css`
			color: #359492;
			font-size: 14px;
			margin-left: 5px;
			font-family: aktiv-grotesk;
			font-weight: normal;
		`,
	},
	ratingWrapper: css`
		display: flex;
		justify-content: center;
		align-items: center;
		height: fit-content;
	`,
	reviewScore: {
		base: css`
			margin-right: 5px;
			font-size: 22px;
			line-height: 1;
			font-weight: 700;
			width: auto;
			text-align: center;
			color: #14a82a;
			font-family: AvenirNextLTPro-Bold;
		`,
		usesSoftwareDesign: css`
			color: #747481;
			font-size: 14px;
		`,
	},

	reviewCountWrapper: css`
		font-size: 13px;
		font-weight: 700;
		font-family: AvenirNextLTPro-Bold;
		text-align: left;
	`,
};

const softwareWebReviewOverride = {
	"promotional-products": true,
};

export const WebReviewWidget = ({ company }) => {
	const isWebReviewWidgetRendered = useSelector(
		selectors.getIsWebReviewWidgetRendered,
	);
	const isTcrReviewWidgetRendered = useSelector(
		selectors.getIsTcrReviewWidgetRendered,
	);
	const reviewScore = useSelector((state) =>
		selectors.getWebReviewScore(state, { company }),
	);
	const count = useSelector((state) =>
		selectors.getWebReviewCount(state, { company }),
	);
	const vertical = useSelector(selectors.getVertical);
	const borrowsSoftwareWebReview = prop(vertical, softwareWebReviewOverride);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);
	const usesSoftwareDesign = usesSimplifiedDesign
		? false
		: useSelector(selectors.getUsesSoftwareDesign) || borrowsSoftwareWebReview;
	const reviewCount = usesSoftwareDesign
		? count
			? `(${count.toLocaleString()})`
			: 0
		: count
			? count.toLocaleString()
			: count;
	const usesNewSoftwareDesign = usesSimplifiedDesign
		? false
		: useSelector(selectors.getUsesNewSoftwareDesign);
	const usesNewDesign = useSelector(selectors.getUsesNewWebReviewWidget);
	const starSize = usesNewSoftwareDesign ? "large" : "small";

	const ReviewWidgetWrapper = ({ children }) => (
		<div
			css={[
				styles.reviewContainer.base,
				path(
					["reviewContainer", "domainLevelOverrides", SITE, "allVerticals"],
					styles,
				),
				path(
					["reviewContainer", "domainLevelOverrides", SITE, vertical],
					styles,
				),
				usesSoftwareDesign &&
					path(["reviewContainer", "usesSoftwareDesign"], styles),
				borrowsSoftwareWebReview &&
					path(["reviewContainer", "borrowsSoftwareDesign"], styles),
				usesNewSoftwareDesign && tw`!self-auto !mr-0`,
				usesNewDesign &&
					!usesSimplifiedDesign &&
					css`
						box-shadow:
							0px 5px 32px rgba(0, 0, 0, 0.04),
							0px 10px 64px rgba(50, 50, 93, 0.06);
						${tw`rounded-full p-3`}
					`,
				usesSimplifiedDesign &&
					css`
						@media (max-width: 640px) {
							max-width: unset;
							width: 100%;
						}
						@media (min-width: 380px) {
							margin-left: 1rem !important;
						}
						${tw`!mr-0 self-auto md:!ml-0 !shadow-none`}
					`,
			]}
		>
			<div
				css={[
					styles.reviewDiv.base,
					usesSoftwareDesign && styles.reviewDiv.usesSoftwareDesign,
					isTcrReviewWidgetRendered &&
						path(["reviewDiv", "domainLevelOverrides", SITE], styles),
					usesNewDesign && tw`items-center !m-0`,
					usesSimplifiedDesign &&
						css`
							${tw`justify-end`}
						`,
				]}
			>
				{!usesNewSoftwareDesign && (
					<div
						css={[
							usesSoftwareDesign
								? styles.reviewScore.usesSoftwareDesign
								: styles.reviewScore.base,
							usesNewDesign && tw`!text-black font-bold text-2xl`,
							usesSimplifiedDesign && tw`!text-[#0DBE54]`,
						]}
					>
						{usesSoftwareDesign ? `${reviewScore} out of 5` : reviewScore}
					</div>
				)}
				<div
					css={[
						styles.ratingWrapper,
						usesNewSoftwareDesign && tw`!flex !flex-col !m-0`,
						usesNewDesign && tw`!flex !flex-col !m-0`,
					]}
				>
					<Rating
						css={[
							usesNewDesign &&
								css`
									.MuiRating-iconFilled {
										${tw`text-tcr-personal-loans-green`}
									}
								`,
							usesSimplifiedDesign &&
								css`
									.MuiRating-iconFilled {
										${tw`text-[#FFD452]`}
									}
								`,
						]}
						classes={
							usesNewDesign
								? { "MuiRating-iconFilled": "MuiRating-iconFilled" }
								: {}
						}
						precision={0.1}
						readOnly
						defaultValue={reviewScore}
						size={starSize}
					/>
					{usesNewSoftwareDesign && (
						<div css={tw`!flex-row !m-0`}>
							<div
								css={[
									usesSoftwareDesign
										? styles.reviewScore.usesSoftwareDesign
										: styles.reviewScore.base,
									tw`!text-base !font-semibold !m-0`,
								]}
							>
								{usesSoftwareDesign ? `${reviewScore} out of 5` : reviewScore}
							</div>
							{children}
						</div>
					)}
					{usesNewDesign && !usesSimplifiedDesign && (
						<div
							css={[
								tw`text-xs leading-[0.9rem] font-semibold text-[#4A4B4B] self-start`,
							]}
						>
							Overall Score
						</div>
					)}
					{usesSimplifiedDesign && (
						<div
							css={tw`text-xs leading-[0.9rem] self-start font-semibold text-[#747481]`}
						>{`${reviewCount} Reviews`}</div>
					)}
				</div>
			</div>
			{!usesNewSoftwareDesign && !usesNewDesign && children}
		</div>
	);

	ReviewWidgetWrapper.propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]),
	};

	const WebReviewCount = () => {
		const getReviewString = () => {
			if (usesSoftwareDesign) return "";
			else return "Reviews";
		};
		return (
			<div css={styles.reviewCountWrapper}>
				<span
					css={[
						usesSoftwareDesign
							? styles.reviewCountSpan.usesSoftwareDesign
							: styles.reviewCountSpan.base,
						usesNewSoftwareDesign && tw`!text-base !font-semibold`,
					]}
				>
					{reviewCount}
				</span>{" "}
				{getReviewString()}
			</div>
		);
	};

	if (isWebReviewWidgetRendered && reviewScore && reviewCount) {
		return (
			<ReviewWidgetWrapper>
				<WebReviewCount />
			</ReviewWidgetWrapper>
		);
	} else if (isTcrReviewWidgetRendered && reviewScore) {
		return <ReviewWidgetWrapper />;
	} else return null;
};

WebReviewWidget.propTypes = {
	company: PropTypes.object.isRequired,
};
