import { useContext } from "react";
import PropTypes from "prop-types";

import { CheckMarkIcon } from "../../client/assets/icons/common/CheckMarkIcon";
import { CompanyContext } from "../CompanyList/CompanyContext";

const BaseCompanyBadge = ({ style, iconColor, classNames }) => {
	const { company } = useContext(CompanyContext);
	const badgeText = company.fields.companyBadgeText;

	return badgeText ? (
		<div css={style?.wrapper} className={classNames?.wrapper}>
			<CheckMarkIcon color={iconColor} />
			<p css={style?.text} className={classNames?.text}>
				{badgeText}
			</p>
		</div>
	) : null;
};

export default BaseCompanyBadge;

BaseCompanyBadge.propTypes = {
	style: PropTypes.object,
	iconColor: PropTypes.string,
	classNames: PropTypes.object,
};
