import { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import selectors
import * as selectors from "../../selectors/index";
import BasePhoneLink from "../BaseComponents/BasePhoneLink";
import { CompanyContext } from "../CompanyList/CompanyContext";

const PhoneImageWrapper = ({ style }) => (
	<div css={style.imageWrapper}>
		<span css={style.phone}>
			<svg
				height="34"
				viewBox="0 0 34 34"
				width="34"
				xmlns="http://www.w3.org/2000/svg"
				className="tw-inline-block tw-h-[1em] tw-w-[1em] tw-shrink-0 tw-align-middle tw-leading-[1em]"
			>
				<path
					d="m937.111 914.611a21.407 21.407 0 0 1 -6.8-1.133 2.325 2.325 0 0 0 -1.889.378l-4.155 4.155a29.4 29.4 0 0 1 -12.467-12.467l4.156-4.155a1.5 1.5 0 0 0 .377-1.889 25.43 25.43 0 0 1 -.944-6.611 1.785 1.785 0 0 0 -1.889-1.889h-6.611a1.785 1.785 0 0 0 -1.889 1.889 32.085 32.085 0 0 0 32.111 32.111 1.785 1.785 0 0 0 1.889-1.889v-6.611a1.785 1.785 0 0 0 -1.889-1.889zm-1.889-6.611h3.778a16.93 16.93 0 0 0 -17-17v3.778a13.14 13.14 0 0 1 13.222 13.222zm-7.555 0h3.777a9.352 9.352 0 0 0 -9.444-9.444v3.777a5.566 5.566 0 0 1 5.667 5.667z"
					fill="currentColor"
					fillRule="evenodd"
					transform="translate(-905 -891)"
				/>
			</svg>
		</span>
		<p css={style.text}>Call Now</p>
	</div>
);

PhoneImageWrapper.propTypes = {
	style: PropTypes.object.isRequired,
};

const TwoRowsPhoneLink = ({ style, className }) => {
	const { company } = useContext(CompanyContext);
	const phoneNumber = useSelector((state) =>
		selectors.getCompanyPhoneNumber(state, { company }),
	);
	const companySlug = useSelector((state) =>
		selectors.getCompanySlug(state, { company }),
	);

	if (!phoneNumber) {
		return null;
	}

	return (
		<div>
			<BasePhoneLink company={company} className={className}>
				<div css={style.wrapper}>
					<PhoneImageWrapper style={style} />
					<span css={style.phoneNumber} data-company-slug={companySlug}>
						{phoneNumber}
					</span>
				</div>
			</BasePhoneLink>
		</div>
	);
};

export default TwoRowsPhoneLink;

TwoRowsPhoneLink.propTypes = {
	style: PropTypes.object.isRequired,
	className: PropTypes.string,
};
