import { useContext } from "react";

import { DiscountIcon } from "../../../client/assets/icons/bestbanks/DiscountIcon";
import { DollarIcon } from "../../../client/assets/icons/bestbanks/DollarIcon";
import { CompanyContext } from "../../CompanyList/CompanyContext";

const CompanyApy = () => {
	const { company } = useContext(CompanyContext);
	const companyApy = company.fields.apy;
	const minimumBalanceToEarnApy = company.fields.minimumBalanceToEarnApy;
	if (!companyApy && !minimumBalanceToEarnApy) return null;

	return (
		<div className="tw-mb-4 lg:tw-mb-0 lg:tw-mt-4">
			<div className="tw-flex tw-flex-col tw-gap-2 lg:tw-flex-row">
				{companyApy ? (
					<div className="tw-flex tw-w-fit tw-gap-2 tw-rounded-full tw-bg-white tw-py-1 tw-pl-1 tw-pr-3.5 tw-shadow-[0px_10px_64px_0px_rgba(50,50,93,0.06),0px_5px_32px_0px_rgba(0,0,0,0.04),0px_0px_1px_0px_rgba(0,0,0,0.48)]">
						<DiscountIcon className="tw-text-2xl tw-text-[#ED7B51]" />
						<span className="tw-text-sm tw-font-bold tw-leading-6 tw-text-[#213132]">
							APY
						</span>
						<span className="tw-text-sm tw-font-bold tw-leading-6 tw-text-[#213132]">
							{companyApy}
						</span>
					</div>
				) : null}
				{minimumBalanceToEarnApy ? (
					<div className="tw-flex tw-w-fit tw-gap-2 tw-rounded-full tw-bg-white tw-py-1 tw-pl-1 tw-pr-3.5 tw-shadow-[0px_10px_64px_0px_rgba(50,50,93,0.06),0px_5px_32px_0px_rgba(0,0,0,0.04),0px_0px_1px_0px_rgba(0,0,0,0.48)]">
						<DollarIcon
							className="tw-text-2xl tw-text-white"
							bgColor="tw-text-[#ED7B51]"
						/>
						<span className="tw-text-sm tw-font-bold tw-leading-6 tw-text-[#213132]">
							Min. Balance to Earn APY
						</span>
						<span className="tw-text-sm tw-font-bold tw-leading-6 tw-text-[#213132]">
							${minimumBalanceToEarnApy}
						</span>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default CompanyApy;
