// import selectors
import * as selectors from "@/selectors";
import { useSelector } from "react-redux";
import useSSR from "use-ssr";

// import constants
const { isServer } = useSSR();

const withMediaAlphaData = (WrappedComponent) => {
	const MediaAlphaData = (props) => {
		const isMediaAlphaPublisherVertical = useSelector(
			selectors.getIsMediaAlphaPublisherVertical,
		);
		const targetedSlug = useSelector(selectors.getTargetedSlug);

		const defaultData = {
			data: {
				zip: "auto",
			},
			local_hour: new Date().getHours(),
			url: isServer ? undefined : window.location.href,
			sub_2: targetedSlug,
		};

		// eslint-disable-next-line
		const data = TEST_IP ? { ...defaultData, ip: TEST_IP } : defaultData;

		const mediaAlphaData = isMediaAlphaPublisherVertical ? data : {};
		const returnedProps = {
			...props,
			mediaAlphaData,
		};

		return <WrappedComponent {...returnedProps} />;
	};
	MediaAlphaData.displayName = `OutboundPaths(${
		WrappedComponent.displayName || WrappedComponent.name || "Component"
	})`;
	return MediaAlphaData;
};

export default withMediaAlphaData;
