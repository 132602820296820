export const SAVINGS_ACCOUNTS_PATH = "savings-accounts";
export const CDS_PATH = "cds";
export const BUSINESS_BANK_ACCOUNTS = "business-bank-accounts";
export const CHECKING_ACCOUNTS_PATH = "checking-accounts";

export const BB_SITE_CONFIGURATION = {
	[SAVINGS_ACCOUNTS_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		preventRenderingCompanyListHeader: true,
		usesProductDescription: true,
	},
	[CDS_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		preventRenderingCompanyListHeader: true,
		usesProductDescription: true,
	},
	[BUSINESS_BANK_ACCOUNTS]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		preventRenderingCompanyListHeader: true,
		usesProductDescription: true,
	},
	[CHECKING_ACCOUNTS_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		preventRenderingCompanyListHeader: true,
		usesProductDescription: true,
	},
};
