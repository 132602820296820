import PropTypes from "prop-types";
import tw from "twin.macro";

import { ModalButton } from "../../ModalButton";

const CurrentlyInsured = (props) => {
	const { setMediaAlphaState, isLastStep } = props;

	const updateState = (val) => {
		const value = val === "true" ? 1 : 0;
		setMediaAlphaState({ currently_insured: value }, isLastStep);
	};

	return (
		<div css={tw`mt-4 mb-8`}>
			<div css={tw`mb-8 text-center font-semibold text-black leading-5`}>
				Do you currently have auto insurance?
			</div>
			<div css={tw`flex justify-evenly`}>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md font-semibold text-black bg-white pr-40px pl-40px hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState("true")}
				>
					Yes
				</ModalButton>
				<ModalButton
					size="large"
					variant="contained"
					css={tw`shadow-md font-semibold text-black bg-white pr-40px pl-40px hover:bg-ir-multiStep-modal-button hover:text-white`}
					onClick={() => updateState("false")}
				>
					No
				</ModalButton>
			</div>
		</div>
	);
};

CurrentlyInsured.propTypes = {
	setMediaAlphaState: PropTypes.func.isRequired,
	isLastStep: PropTypes.bool.isRequired,
};

export default CurrentlyInsured;
