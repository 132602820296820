import GridLayoutWithPhoneCta from "../CreditAssistance/GridLayoutWithPhoneCta";
import { ThemeContext } from "../CreditAssistance/ThemeContext";

export const AutoWarrantyCompany = (props) => (
	<ThemeContext.Provider
		value={{
			phoneCtaText: "Get A Quote",
		}}
	>
		<GridLayoutWithPhoneCta {...props} />
	</ThemeContext.Provider>
);
