import PropTypes from "prop-types";
import tw from "twin.macro";

export const NoResults = (props) => (
	<div
		css={tw`block relative mt-1.125 rounded bg-white shadow-sm border border-solid border-gray-400 font-semibold font-sans`}
	>
		<h2 css={tw`block w-full text-center text-noComponentGray-text`}>
			Sorry &#9785;
		</h2>
		<h3 css={tw`block w-full text-center text-noComponentGray-text`}>
			No {props.categoryName} Services Available.
		</h3>
	</div>
);

NoResults.propTypes = {
	categoryName: PropTypes.string,
};
