import { useContext } from "react";

import { CompanyContext } from "../components/CompanyList/CompanyContext";

const EXCELLENT_THRESHOLD = 4.6;
const GREAT_THRESHOLD = 4.4;
const GOOD_THRESHOLD = 4.0;
const AVERAGE_THRESHOLD = 3.9;

export const useWebReviewScore = () => {
	const { company } = useContext(CompanyContext);

	const score = company.fields.webReviewScore ?? 0;

	let ratingLabel;

	if (score >= EXCELLENT_THRESHOLD) {
		ratingLabel = "Excellent";
	} else if (score >= GREAT_THRESHOLD) {
		ratingLabel = "Great";
	} else if (score >= GOOD_THRESHOLD) {
		ratingLabel = "Good";
	} else if (score <= AVERAGE_THRESHOLD) {
		ratingLabel = "Average";
	} else {
		ratingLabel = "N/A";
	}

	return { score, ratingLabel };
};
