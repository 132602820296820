import PropTypes from "prop-types";
import { Button } from "react-aria-components";
import { useFormState, useWatch } from "react-hook-form";

export const SubmitLink = ({ children, ...props }) => {
	const { isDirty, errors } = useFormState();
	const { firstName, lastName } = useWatch();
	const isLink =
		isDirty &&
		Object.keys(errors).length === 0 &&
		firstName !== "" &&
		lastName !== "";

	const Component = isLink ? "a" : Button;
	const componentProps = isLink
		? {
				href: `/visit/beenverified?fn=${firstName}&ln=${lastName}`,
				target: "_blank",
				rel: "nofollow",
			}
		: { type: "submit" };

	return (
		<Component
			className={`tw-inline-flex tw-w-[144px] tw-cursor-pointer tw-justify-center tw-whitespace-nowrap tw-rounded-lg tw-border-none tw-bg-[#359492] tw-px-6 tw-py-3 tw-font-[inherit] tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-500/50 max-lg:tw-w-full max-md:tw-px-4 max-md:tw-py-2 ${!isLink ? "tw-cursor-not-allowed tw-opacity-70" : ""}`}
			isDisabled={!isLink}
			{...componentProps}
			{...props}
		>
			<span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-white">
				{children}
			</span>
		</Component>
	);
};

SubmitLink.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	isLink: PropTypes.bool,
	data: PropTypes.object,
};
