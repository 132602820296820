import { getMediaAlphaBanner } from "@/selectors";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";

const styles = {
	wrapper: css`
		background-color: #283341;
	`,
	container: css`
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #283341;
		padding: 12px 0;

		@media (max-width: 767px) {
			padding: 8px;
		}

		p {
			color: white;
			margin: 0;
			font-weight: 500;

			@media (max-width: 767px) {
				font-size: 17px;
				text-align: center;
			}

			@media (max-width: 525px) {
				padding: 0 25px;
				font-size: 14px;
			}
		}

		span {
			font-weight: 600;
			font-size: 21px;
			color: rgb(0, 202, 134);
			margin-right: 2px;

			@media (max-width: 991px) {
				display: block;
				text-align: center;
			}

			@media (max-width: 525px) {
				font-size: 18px;
			}
		}
	`,
};

const MediaAlphaBanner = () => {
	const usesMediaAlphaBanner = useSelector(getMediaAlphaBanner);

	return usesMediaAlphaBanner ? (
		<div css={styles.wrapper}>
			<div className="container" css={styles.container}>
				<p>
					<span>Shop Around: </span>Click on 2 or more insurers below to compare
					quotes and maximize your savings
				</p>
			</div>
		</div>
	) : null;
};

export default MediaAlphaBanner;
