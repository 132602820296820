import { getTargetedSlug } from "@/selectors";
import { useSelector } from "react-redux";

const HAPPY_HEAD_SLUG = "happy-head";
const HAPPY_HEAD_WOMEN = "happy-head-women";

const reorderHappyHeadCompanies = (companies) => {
	const happyHeadCompanies = companies.filter((company) =>
		company.fields.companySlug.includes(HAPPY_HEAD_SLUG),
	);
	const otherCompanies = companies.filter(
		(company) => !company.fields.companySlug.includes(HAPPY_HEAD_SLUG),
	);

	// Update awardText for men and women entries
	const modifiedHappyHead = happyHeadCompanies.map((company) => {
		if (company.fields.companySlug === HAPPY_HEAD_SLUG) {
			company.fields.awardText = "Our #1 Pick";
		} else if (company.fields.companySlug === HAPPY_HEAD_WOMEN) {
			company.fields.awardText = "#1 for Women";
		}
		return company;
	});

	// Remove existing awardTexts
	const modifiedCompanies = otherCompanies.map((company) => {
		company.fields.awardText = null;
		return company;
	});

	return modifiedHappyHead.concat(modifiedCompanies);
};

export const useTargetedPageCompanies = (companies) => {
	const targetedSlug = useSelector(getTargetedSlug);
	if (!targetedSlug) return companies;

	return targetedSlug.includes(HAPPY_HEAD_SLUG)
		? reorderHappyHeadCompanies(companies)
		: companies;
};
