import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const PREFIX = "ModalButton";

const classes = {
	root: `${PREFIX}-root`,
};

const StyledButton = styled(Button)({
	[`&.${classes.root}`]: {
		"&:hover ": {
			"@media (hover: none)": {
				backgroundColor: "white !important",
				color: "black !important",
				boxShadow:
					"0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06) !important",
			},
		},
		"&:active ": {
			"@media (hover: none)": {
				boxShadow:
					"0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06) !important",
			},
			boxShadow:
				"0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06) !important",
		},
		"&.Mui-focusVisible": {
			boxShadow:
				"0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06) !important",
		},
	},
});

export const ModalButton = ({ children, ...props }) => (
	<StyledButton
		disableElevation
		disableRipple
		disableFocusRipple
		className={classes.root}
		classes={{
			root: classes.root,
		}}
		{...props}
	>
		{children}
	</StyledButton>
);

ModalButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default ModalButton;
