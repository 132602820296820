export const CREDIT_HELP_PATH = "credit-help";

export const PC_SITE_CONFIGURATION = {
	[CREDIT_HELP_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		isServiceBlockLegacyStyled: true,
		isOnlyAllowedToRenderVisitLinks: true,
	},
};
