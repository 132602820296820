// import constants
import { TAX_RELIEF_DEFAULT_PATH } from "@/config/site/thecreditreview";
// import selectors
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const styles = {
	cafSpan: css`
		position: absolute;
		display: block;
		bottom: 10px;
		width: 100%;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.024em;
		color: #333742;
		font-family: AvenirNextLTPro-Demi;

		@media (max-width: 991px) {
			position: static;
			margin-top: 10px;
		}
	`,
};

export const TaxCafNumber = ({ company }) => {
	const vertical = useSelector(selectors.getVertical);
	const isOnlyAllowedToRenderVisitLinks = useSelector(
		selectors.getIsOnlyAllowedToRenderVisitLinks,
	);
	const cafNumber = useSelector((state) =>
		selectors.getTaxCafNumber(state, { company }),
	);

	const isRenderable =
		vertical === TAX_RELIEF_DEFAULT_PATH &&
		isOnlyAllowedToRenderVisitLinks &&
		cafNumber;

	return isRenderable ? (
		<span css={styles.cafSpan}>IRS CAF #: {cafNumber}</span>
	) : null;
};

export default TaxCafNumber;

TaxCafNumber.propTypes = {
	company: PropTypes.object.isRequired,
};
