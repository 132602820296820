import { useSelector } from "react-redux";

import { getIsAmazonVertical } from "../../selectors";
import { AmazonSaleHighlight } from "./AmazonSaleHighlight";

export const SaleHighlight = (props) => {
	const isAmazonVertical = useSelector(getIsAmazonVertical);

	if (isAmazonVertical) return <AmazonSaleHighlight {...props} />;

	return null;
};
