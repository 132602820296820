import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ isFeatured }) => ({
	layoutWrapper: css`
		${tw`block bg-white overflow-hidden`}
		${isFeatured
			? tw`mt-8 border-2 border-solid border-[#7f9cf5] rounded-lg`
			: tw`mb-5`}

    ${!isFeatured &&
		`
      & + & {
        border-top: 2px solid #f2f3f4;
      }
    `}
	`,
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#e7ecfb] tw-text-center",
		contentWrapper:
			"tw-leading-6 tw-font-semibold tw-text-xs tw-text-black tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	rootGrid: css`
		${tw`flex flex-col lg:flex-row min-h-[325px] justify-center items-center lg:items-stretch p-2`}
	`,
	col1: css`
		${tw`w-full lg:w-[28%] max-w-none flex flex-col justify-center relative`}
	`,
	col2: css`
		${tw`w-full lg:w-[50%] max-w-none flex flex-col justify-center items-start px-0 lg:px-2 my-2 lg:my-0`}
	`,
	col3: css`
		${tw`w-full lg:w-[22%] max-w-none flex flex-wrap flex-row lg:flex-col justify-evenly lg:justify-center items-center `}
	`,
	logoWrapper: css`
		${tw`pt-16 pb-4 px-0 lg:py-0`}
	`,
	companyLogo: {
		picture: css`
			${tw`flex justify-center`}
		`,
		image: [
			css`
				${tw`max-w-[230px] lg:max-w-[160px] my-2 lg:my-0`}
			`,
		],
	},
	serviceFlag: {
		wrapper: css`
			${tw`absolute top-3 -left-2 flex justify-center items-center`}
		`,
		flag: css`
			${tw`bg-[#1d8ce0] w-[28px] h-[28px] text-white font-extrabold flex justify-center items-center shadow-lightShadow`}
		`,
		number: css`
			${tw`m-0 text-white font-medium text-sm`}
		`,
		text: css`
			${tw`bg-[#1d8ce0] text-white h-[28px] text-base flex justify-center items-center my-0 mx-[2px] py-0 px-2 font-medium text-sm shadow-lightShadow`}
		`,
	},
	productDescription: {
		text: css`
			${tw`text-lg font-bold mb-2 text-[#000a25]`}
		`,
	},
	companyBadge: {
		wrapper: css`
			${tw`w-max flex bg-[#00b679] justify-center items-center p-1 mb-2 rounded shadow-lightShadow`}
		`,
		text: css`
			${tw`text-white font-semibold text-xs mb-0 mx-1`}
		`,
	},
	benefitsList: {
		wrapper: "tw-w-full tw-px-0",
		list: "tw-w-full tw-mb-0 tw-p-0",
		listItem:
			"tw-w-full tw-text-sm tw-text-[#000a25] tw-pl-6 tw-mb-1 tw-bg-[url(/public/react-images/common/favyCheck.svg)] [background-position:left_top_7px] tw-bg-no-repeat",
	},
	visitLink: {
		wrapper:
			"tw-my-2 tw-mx-0 tw-w-[165px] tw-h-[48px] tw-flex tw-bg-[#1d8ce0] tw-rounded-lg tw-shadow-lightShadow tw-cursor-pointer lg:tw-w-[196px]",
		contentWrapper:
			"tw-text-white tw-w-full tw-flex tw-items-center tw-justify-center tw-font-semibold tw-text-xl tw-mb-0",
	},
	reviewLink: {
		wrapper: css`
			${tw`mt-0 lg:mt-5 `}
		`,
		text: css`
			${tw`text-[#0d8ae3] text-base font-semibold`}
		`,
	},
	socialProofSection: css`
		${tw`flex-shrink lg:flex-shrink-0 flex-grow lg:flex-grow-0 mb-0 mt-4 lg:mt-0`}
		flex-basis: 100%;
		@media (min-width: 1024px) {
			flex-basis: 0;
		}
	`,
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-max-w-fit tw-self-center tw-mb-4 tw-mx-auto tw-rounded-lg tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none md:after:tw-w-3 md:after:tw-h-3 after:tw-top-full",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#00b679] tw-text-2xl md:tw-text-[32px]",
		content:
			"tw-min-w-[100px] tw-max-w-[100px] tw-leading-4 tw-font-bold tw-font-aktivGrotesk !tw-m-0 tw-text-[0.6875rem] tw-text-left tw-text-[#22293A]",
	},
	rating: {
		wrapper: css`
			${tw`min-w-[80%] lg:min-w-min max-w-full lg:max-w-none self-center lg:self-auto relative lg:absolute bottom-0 lg:bottom-4 flex flex-row justify-center items-center left-0 lg:left-1/2`}
			transform: none;
			border-bottom: 1px solid #efefef;
			box-shadow: 0px 1px 4px 0px #cecece;
			border-radius: 4px;
			padding: 4px;
			margin-bottom: 8px;

			@media (min-width: 1024px) {
				transform: translateX(-50%);
				border: none;
				box-shadow: 0px 1px 4px 0px #cecece;
				padding: 4px 16px;
				border-radius: 3rem;
				border-bottom: 2px solid #efefef;
			}
		`,
		score: css`
			${tw`font-extrabold text-[#1a1a1a] text-xl mb-0 ml-2 mr-4 lg:mr-2`}
		`,
		stars: css`
			${tw`flex justify-between lg:justify-center flex-row lg:flex-col items-start mr-4 lg:mr-2 w-full`}
		`,
		starColor: css`
			.MuiRating-iconFilled {
				${tw`text-[#00b679]`}
			}
		`,
		count: css`
			${tw`text-xs font-semibold text-[#1a1a1a] mb-0 text-center`}
		`,
	},
});
