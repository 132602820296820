import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

import { getVerticalFilteringConfiguration } from "../../selectors/filters";
import { FilterSelect } from "./FilterSelect";
import FilterWrapper from "./FilterWrapper";
import MobileFilteringButtonSelect from "./MobileFilteringButtonSelect";

const Filters = ({
	disabled,
	buildFilteringState,
	filteringParameters,
	isMobile,
}) => {
	const vertical = useSelector(selectors.getVertical);
	const filter = useSelector(selectors.getFilter);
	const filteringConfiguration = useSelector(getVerticalFilteringConfiguration);
	const { filteringSelects, mobileFilteringButtonSelects } =
		filteringConfiguration;

	const getFilterSelects = () => {
		if (filteringSelects) {
			return filteringSelects.map((select, idx) => (
				<FilterWrapper key={idx}>
					<FilterSelect
						vertical={vertical}
						filter={filter}
						isNotUserFiltered={disabled}
						title={select.title}
						filteringKey={select.filteringKey}
						options={select.options}
						buildFilteringState={buildFilteringState}
						isMobile={isMobile}
					/>
				</FilterWrapper>
			));
		} else return null;
	};

	const selects = !isMobile ? getFilterSelects() : null;

	const getMobileFilteringButtonSelects = () => {
		if (mobileFilteringButtonSelects) {
			return mobileFilteringButtonSelects.map((button, idx) => (
				<FilterWrapper key={idx}>
					<MobileFilteringButtonSelect
						vertical={vertical}
						title={button.title}
						filter={filter}
						filteringKey={button.filteringKey}
						options={button.options}
						buildFilteringState={buildFilteringState}
						parentFilteringValue={path(
							[button.filteringKey],
							filteringParameters,
						)}
						isMobile={isMobile}
						isNotUserFiltered={path(["isNotUserFiltered"], filteringParameters)}
					/>
				</FilterWrapper>
			));
		} else return null;
	};

	const lists = isMobile ? getMobileFilteringButtonSelects() : null;

	return [selects, lists];
};

export default Filters;

Filters.propTypes = {
	disabled: PropTypes.bool.isRequired,
	buildFilteringState: PropTypes.func.isRequired,
};
