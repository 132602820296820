// import constants
import { IR_AUTO_INSURANCE_DEFAULT_PATH } from "@/config/site/insuranceranked";
// import selectors
import { getMediaAlphaHeadline, getVertical } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

const MediaAlphaHeadline = ({ company }) => {
	const mediaAlphaHeadline = useSelector((state) =>
		getMediaAlphaHeadline(state, { company }),
	);
	const vertical = useSelector(getVertical);
	return mediaAlphaHeadline ? (
		<div
			css={
				vertical === IR_AUTO_INSURANCE_DEFAULT_PATH
					? [
							tw`font-semibold mb-2 text-2xl underline`,
							css`
								color: #1a73e8;
							`,
						]
					: tw`font-semibold mb-2 text-lg text-black`
			}
		>
			{mediaAlphaHeadline}
		</div>
	) : null;
};

MediaAlphaHeadline.propTypes = {
	company: PropTypes.object.isRequired,
};

export default MediaAlphaHeadline;
