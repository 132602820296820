import { Icon } from "../../../../components/BaseComponents/Icon";

export const SaleHighlightStarIcon = (props) => (
	<Icon fill="none" {...props}>
		<path
			fill="currentColor"
			d="M19.5 9a1.13 1.13 0 0 1-1.13-1.13 2.25 2.25 0 0 0-2.25-2.25 1.13 1.13 0 0 1 0-2.25 2.25 2.25 0 0 0 2.25-2.25 1.12 1.12 0 1 1 2.25 0 2.25 2.25 0 0 0 2.25 2.25 1.13 1.13 0 0 1 0 2.25 2.25 2.25 0 0 0-2.25 2.25A1.12 1.12 0 0 1 19.5 9ZM9 24a1.5 1.5 0 0 1-1.5-1.5 6 6 0 0 0-6-6 1.5 1.5 0 1 1 0-3 6 6 0 0 0 6-6 1.5 1.5 0 0 1 3 0 6 6 0 0 0 6 6 1.5 1.5 0 1 1 0 3 6 6 0 0 0-6 6A1.5 1.5 0 0 1 9 24Z"
		/>
	</Icon>
);
