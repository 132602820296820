/* constants for verticals  */
export const IR_AUTO_INSURANCE_DEFAULT_PATH = "auto-insurance";
export const IR_LIFE_INSURANCE_DEFAULT_PATH = "life-insurance";
export const IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH = "homeowners-insurance";
export const IR_HEALTH_INSURANCE_DEFAULT_PATH = "health-insurance";
export const IR_PET_INSURANCE_DEFAULT_PATH = "pet-insurance";
export const IR_RENTERS_INSURANCE_DEFAULT_PATH = "renters-insurance";
export const DISABILITY_INSURANCE_DEFAULT_PATH = "disability-insurance";
export const MEDICARE_ADVANTAGE_PATH = "medicare-advantage";
export const MEDICARE_SUPPLEMENT_PATH = "medicare-supplement";
export const BUSINESS_INSURANCE_PATH = "business-insurance";
export const ANNUITIES_PATH = "annuities";
export const FINAL_EXPENSE_INSURANCE_PATH = "final-expense-insurance";
export const TRAVEL_INSURANCE_PATH = "travel-insurance";
export const WHOLE_LIFE_INSURANCE_PATH = "whole-life-insurance";
export const MEDICARE_DEFAULT_PATH = "medicare";
export const VISION_INSURANCE_PATH = "vision-insurance";
export const IR_PHONE_INSURANCE_PATH = "phone-insurance";

/* URL filter parameters */
export const NO_MEDICAL_EXAM_URL_PARAMETER = "no-medical-exam";
export const BEST_LIFE_INSURANCE_URL_PARAMETER = "best-life-insurance";
export const INSTANT_LIFE_INSURANCE_URL_PARAMETER = "instant-life-insurance";
export const LIFE_INSURANCE_EXPANDED_URL_PARAMETER = "life-insurance-expanded";
export const CHEAP_LIFE_INSURANCE_URL_PARAMETER = "cheap-life-insurance";
export const LIFE_INSURANCE_MAIN_URL_PARAMETER = "life-insurance-main";
export const TERM_LIFE_INSURANCE_URL_PARAMETER = "term-life-insurance";

export const AGED_FILTERING_KEY = "aged";
export const POLICY_TYPE_FILTERING_KEY = "policyType";
export const FILTERING_AGE_VALUE = 60;

/* URL filter parameters */
export const SHORT_TERM_URL_PARAMETER = "short-term";
export const LONG_TERM_URL_PARAMETER = "long-term";
export const CRITICAL_ILLNESS_URL_PARAMETER = "critical-illness";

export const IR_SITE_CONFIGURATION = {
	[IR_AUTO_INSURANCE_DEFAULT_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		isMediaAlphaPublisherVertical: true,
		companiesSortedByMediaAlphaBids: true,
		usesMultiStepModal: true,
		mediaAlphaModalSteps: ["zip"],
		serviceRightColumns: [
			{ fields: "companyType", label: "Company Type" },
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "amBestRating", label: "AM Best Rating" },
		],
		usesMediaAlphaBanner: true,
	},
	[IR_LIFE_INSURANCE_DEFAULT_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 5,
			nonTargeted: 8,
		},
		reviewWidgets: {
			widget: "trustpilot",
		},
		serviceRightColumns: [
			{ fields: "minimumCost", label: "Lowest Cost Plan" },
			{ fields: "medicalExamRequired", label: "Medical Exam?" },
			{ fields: "amBestRating", label: "Rating" },
		],
		filteringInformation: {
			mobileFilteringModal: {
				modalText: `Are you ${FILTERING_AGE_VALUE} or older?`,
				buttons: [
					{ label: "Yes", value: true, filteringKey: AGED_FILTERING_KEY },
					{ label: "No", value: false, filteringKey: AGED_FILTERING_KEY },
				],
			},
		},
		companyListHeaderParts: [
			"Provider",
			"Lowest Cost Plan",
			"Medical Exam?",
			"Rating",
			"Why We Suggest",
		],
	},
	[IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		isMediaAlphaPublisherVertical: true,
		companiesSortedByMediaAlphaBids: true,
		companyListHeaderParts: [
			"Provider",
			"AM Best Rating",
			"Marketplace Vs Carrier",
			"Year Founded",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "companyType", label: "Marketplace Vs Carrier" },
			{ fields: "yearFounded", label: "Year Founded" },
		],
	},
	[IR_HEALTH_INSURANCE_DEFAULT_PATH]: {
		isOnlyAllowedToRenderVisitLinks: true,
		companyListHeaderParts: [
			"Provider",
			"Company Type",
			"Year Founded",
			"BBB Rating",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{ fields: "companyType", label: "Company Type" },
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
		reviewWidgets: {
			widget: "trustpilot",
		},
		usesRevampedPhoneLink: true,
	},
	[IR_PET_INSURANCE_DEFAULT_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 8,
			nonTargeted: 8,
		},
		reviewWidgets: {
			widget: "trustpilot",
		},
		serviceRightColumns: [
			{
				fields: ["minimumEligibleAge", "maximumEligibleAge"],
				label: "Pet age range",
				formatter: ([min, max]) => `${min} - ${max}`,
			},
			{ fields: "multiPetDiscount", label: "Multi-Pet Discount" },
			{ fields: "vetExamRequired", label: "Vet Exam Required?" },
		],
		companyListHeaderParts: [
			"Provider",
			"Pet Age Range",
			"Multi-Pet Discount",
			"Vet Exam Required?",
			"Key Features",
		],
	},
	[IR_RENTERS_INSURANCE_DEFAULT_PATH]: {
		companyListHeaderParts: [
			"Provider",
			"Company Type",
			"Year Founded",
			"BBB Rating",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{ fields: "companyType", label: "Company Type" },
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
	},
	[DISABILITY_INSURANCE_DEFAULT_PATH]: {
		companyListHeaderParts: [
			"Provider",
			"Lowest Cost Plan",
			"AM Best Rating",
			"Year Founded",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{
				fields: "minimumCostPerMonth",
				label: "Lowest Cost Plan",
				preFormatterConversions: {
					currency: {
						isConvertedToCurrency: true,
					},
				},
				formatter: (minimumCostPerMonth) => `$${minimumCostPerMonth}/mo`,
			},
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "yearFounded", label: "Year Founded" },
		],
		reviewWidgets: {
			widget: "trustpilot",
		},
	},
	[MEDICARE_ADVANTAGE_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		companiesSortedByMediaAlphaBids: true,
		isMediaAlphaPublisherVertical: true,
		isOnlyAllowedToRenderVisitLinks: true,
		companyListHeaderParts: [
			"Provider",
			"Company Type",
			"AM Best Rating",
			"BBB Rating",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{ fields: "companyType", label: "Company Type" },
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
		usesRevampedPhoneLink: true,
	},
	[MEDICARE_SUPPLEMENT_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		companiesSortedByMediaAlphaBids: true,
		isMediaAlphaPublisherVertical: true,
		isOnlyAllowedToRenderVisitLinks: true,
		companyListHeaderParts: [
			"Provider",
			"Company Type",
			"AM Best Rating",
			"BBB Rating",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{ fields: "companyType", label: "Company Type" },
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
		usesRevampedPhoneLink: true,
	},
	[MEDICARE_DEFAULT_PATH]: {
		isMediaAlphaPublisherVertical: true,
		companiesSortedByMediaAlphaBids: true,
		isOnlyAllowedToRenderVisitLinks: true,
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"Provider",
			"Company Type",
			"AM Best Rating",
			"BBB Rating",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{ fields: "companyType", label: "Company Type" },
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
		usesRevampedPhoneLink: true,
	},
	[BUSINESS_INSURANCE_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[ANNUITIES_PATH]: {
		reviewWidgets: {
			widget: "webReview",
		},
		companyListHeaderParts: [
			"Provider",
			"Starting Monthly Premium",
			"AM Best Rating",
			"Age Payouts Begin",
			"Why We Suggest",
		],
		serviceRightColumns: [
			{
				fields: "startingMonthlyPremium",
				label: "Starting Monthly Premium",
				formatter: (premium) => `$${premium}`,
			},
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "agePayoutsBegin", label: "Age Payouts Begin" },
		],
	},
	[FINAL_EXPENSE_INSURANCE_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		isMediaAlphaPublisherVertical: true,
		companiesSortedByMediaAlphaBids: true,
		isOnlyAllowedToRenderVisitLinks: true,
		companyListHeaderParts: [
			"Provider",
			"Year Founded",
			"AM Best Rating",
			"Medical Exam?",
			"Why We Suggest",
		],
		reviewWidgets: {
			widget: "trustpilot",
		},
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "medicalExamRequired", label: "Medical Exam?" },
		],
	},
	[TRAVEL_INSURANCE_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[WHOLE_LIFE_INSURANCE_PATH]: {
		isMediaAlphaPublisherVertical: true,
		companiesSortedByMediaAlphaBids: true,
		isOnlyAllowedToRenderVisitLinks: true,
		companyListHeaderParts: [
			"Provider",
			"Year Founded",
			"AM Best Rating",
			"Medical Exam?",
			"Why We Suggest",
		],
		reviewWidgets: {
			widget: "trustpilot",
		},
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "amBestRating", label: "AM Best Rating" },
			{ fields: "medicalExamRequired", label: "Medical Exam?" },
		],
	},
	[VISION_INSURANCE_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[IR_PHONE_INSURANCE_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
};
