import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ isFeatured }) => ({
	layoutWrapper: css`
		${tw`block w-full bg-white rounded-lg shadow-lg overflow-hidden py-4 lg:pr-4 xl:pr-0`}
		${isFeatured ? tw`mt-8 border-2 border-solid border-[#000]` : tw`mb-5`}
	`,
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#ffefcf] tw-text-center",
		contentWrapper:
			"tw-leading-6 tw-font-semibold tw-text-xs tw-text-black tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	rootGrid: css`
		${tw`flex flex-col lg:flex-row min-h-[225px] justify-center items-center lg:items-stretch pb-4 lg:pb-0`}
	`,
	col1: css`
		${tw`w-full lg:w-[28%] max-w-none flex flex-col justify-center relative`}
	`,
	col2: css`
		${tw`w-full lg:w-[50%] max-w-none flex flex-col justify-center items-start px-4 lg:pl-2 lg:pr-5 my-2 lg:my-0`}
	`,
	col3: css`
		${tw`w-full lg:w-[22%] max-w-none flex flex-wrap flex-row px-4 lg:px-0 lg:flex-col justify-evenly lg:justify-center items-center `}
	`,
	serviceFlag: {
		wrapper: css`
			${tw`absolute top-[5%] lg:top-[45%] ml-2 flex justify-center items-center`}
		`,
		flag: css`
			${tw`bg-white w-[24px] h-[24px] lg:w-[28px] lg:h-[28px] text-white font-extrabold flex justify-center items-center shadow-lightShadow rounded-full border-2 border-solid border-black`}
		`,
		number: css`
			${tw`m-0 text-black font-medium text-sm`}
		`,
		text: css`
			${tw`bg-[#1fa34a] w-max absolute text-white h-[28px] text-base flex justify-center items-center my-0 mx-[2px] py-0 pl-2 font-medium text-sm shadow-lightShadow`}
			top: 0;
			left: 30px;

			@media (min-width: 1024px) {
				top: -95px;
				left: -10px;
			}

			:after {
				position: relative;
				width: 22px;
				left: 12px;
				content: "";
				border-right: 15px solid white;
				border-top: 14px solid #1fa34a;
				border-bottom: 14px solid #1fa34a;
			}
		`,
	},
	center: css`
		${tw`pb-4 pt-16 lg:p-0`}
	`,
	companyLogo: {
		picture: css`
			${tw`flex justify-center`}
		`,
		image: [
			css`
				${tw`max-w-[230px] lg:max-w-[160px]`}
			`,
		],
	},
	benefitsList: {
		wrapper: "tw-w-full tw-px-0",
		list: "tw-max-w-[360px] lg:tw-max-w-[500px] tw-mx-auto tw-w-full tw-mt-4 tw-mb-4 lg:tw-mb-0 tw-p-0",
		listItem:
			"tw-max-w-[310px] xxs2:tw-max-w-[375px] xs:tw-max-w-[450px] lg:tw-max-w-none tw-mx-auto lg:tw-mx-0 tw-text-sm tw-text-[#000a25] tw-pl-6 tw-mb-3 tw-bg-[url(/public/react-images/common/favyCheck.svg)] [background-position:left_top_7px] tw-bg-no-repeat",
	},
	rating: {
		wrapper: css`
			${tw`flex mb-2`}
		`,
		score: css`
			${tw`font-extrabold text-[#1a1a1a] text-xl my-0 mr-4 lg:mr-2`}
		`,
		stars: css`
			${tw`flex justify-between lg:justify-center flex-row items-center mr-4 lg:mr-2 w-fit`}
		`,
		starColor: css`
			.MuiRating-iconFilled {
				${tw`text-[#f68700]`}
			}
		`,
		count: css`
			${tw`text-sm font-semibold text-[#1a1a1a] mb-0 tracking-tight text-center ml-2`}
		`,
	},
	socialProofSection: css`
		${tw`flex-shrink lg:flex-shrink-0 flex-grow lg:flex-grow-0 mb-2 lg:mt-0`}
		flex-basis: 100%;

		@media (min-width: 1024px) {
			flex-basis: 0;
		}
	`,
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-max-w-fit tw-self-center tw-mx-auto tw-mt-3 tw-rounded-lg tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none md:after:tw-w-3 md:after:tw-h-3 after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#fc8527] tw-text-2xl md:tw-text-[32px]",
		content:
			"tw-min-w-[100px] tw-max-w-[100px] tw-font-bold tw-text-[0.6875rem] !tw-m-0 tw-text-left tw-text-[#22293A]",
	},
	phoneLink: {
		wrapper: css`
			${tw`flex flex-col justify-center items-center my-2 min-w-[150px] lg:min-w-0 gap-1`}
		`,
		imageWrapper: css`
			${tw`flex justify-center gap-1`}
		`,
		phone: css`
			${tw`text-xs mx-1 text-black leading-3`}
		`,
		phoneNumber: css`
			${tw`font-semibold text-[#1a1a1a]`}
		`,
		text: css`
			${tw`font-semibold text-[#1a1a1a] text-xs uppercase m-0`}
		`,
	},
	visitLink: {
		wrapper:
			"tw-my-2 tw-mx-0 tw-w-[165px] tw-h-[48px] tw-flex tw-bg-[#1fa34a] tw-rounded-lg tw-shadow-lightShadow tw-cursor-pointer tw-transition hover:tw-bg-[#30A84A] hover:tw-shadow-[0px_2px_4px_0px_#c0c0c0] lg:tw-w-[196px]",
		contentWrapper:
			"tw-px-2 tw-text-white tw-w-full tw-flex tw-items-center tw-justify-center tw-font-semibold tw-text-base",
	},
	productDescription: {
		text: css`
			${tw`text-xl font-bold mb-2 text-[#000a25] mx-auto lg:mx-0`}
		`,
	},
});
