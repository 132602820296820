import PropTypes from "prop-types";

export const BaseSaleHighlight = ({ children, ...props }) => {
	if (!children) return null;

	return (
		<div className={props?.wrapper}>
			{props?.icon && <span className={props?.iconClass}>{props.icon}</span>}
			<span className={props?.contentWrapper}>{children}</span>
		</div>
	);
};

BaseSaleHighlight.propTypes = {
	wrapper: PropTypes.string,
	contentWrapper: PropTypes.string,
	iconClass: PropTypes.string,
	icon: PropTypes.element,
	children: PropTypes.node.isRequired,
};
