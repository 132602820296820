const initialState = {
	loading: true,
	companies: [],
	assets: [],
	categoryArray: [],
	count: 0,
};

const verticalInformationReducer = (state = initialState) => state;

export default verticalInformationReducer;
