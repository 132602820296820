import { TAX_RELIEF_DEFAULT_PATH } from "./thecreditreview";

export const TS_SITE_CONFIGURATION = {
	[TAX_RELIEF_DEFAULT_PATH]: {
		showFeaturedCompany: true,
		numberOfCompaniesToRender: {
			targeted: 9,
			nonTargeted: 9,
		},
		serviceBlockCallout: {
			field: "minimumDebtAmount",
			preFormatterConversions: {
				isConvertedToCurrency: true,
			},
			formatter: (postConversionValue) =>
				`${postConversionValue}+ Tax Amount Required`,
		},
		isServiceBlockLegacyStyled: true,
		isOnlyAllowedToRenderVisitLinks: true,
	},
};
