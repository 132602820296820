/* eslint-disable no-magic-numbers */
import { useEffect, useLayoutEffect, useState } from "react";
import {
	getCompanyName,
	getCompanySlug,
	getIsProductListingPage,
	getProductImages,
} from "@/selectors";
import { css, Global } from "@emotion/react";
import PropTypes from "prop-types";
import Zoom from "react-medium-image-zoom";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import { BasePictureElement } from "../../BaseComponents/BasePictureElement";

const ImageGallery = ({ company, isFeatured }) => {
	const [portal, setPortal] = useState(null);
	const [scale, setScale] = useState(null);
	const initialWidth = 75;
	const companySlug = useSelector((state) =>
		getCompanySlug(state, { company }),
	);
	const companyName = useSelector((state) =>
		getCompanyName(state, { company }),
	);
	const images = useSelector((state) => getProductImages(state, { company }));
	const isProductListingPage = useSelector(getIsProductListingPage);
	const targetId = isFeatured ? `${companySlug}-featured` : companySlug;

	const imageCount = images ? images.length : null;

	if (!images) return null;

	useLayoutEffect(() => {
		const portalElement = document.getElementById(targetId);
		if (portalElement) {
			setPortal(portalElement);
		}
	}, []);

	useEffect(() => {
		const resizeListener = () => {
			// change width from the state object
			if (portal) {
				const [parentWidth, parentHeight] = [
					portal.offsetWidth,
					portal.offsetHeight,
				];
				const widthMaxScale = Math.floor(parentWidth / initialWidth);
				const heightMaxScale = Math.floor(parentHeight / initialWidth);

				if (heightMaxScale * initialWidth > parentWidth) {
					setScale(widthMaxScale);
				} else setScale(heightMaxScale);
			}
		};

		resizeListener();
		// set resize listener
		window.addEventListener("resize", resizeListener);

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener("resize", resizeListener);
		};
	});

	return (
		<>
			<Global
				styles={css`
					@media (max-width: 640px) {
						[data-rmiz-modal-content] {
							${tw`bottom-[-50%] !top-[unset]`}
						}
					}
					@media (min-width: 641px) {
						#${targetId} > [data-rmiz-overlay] > [data-rmiz-modal-content] {
							${portal &&
							`top: ${portal.offsetHeight / 2 - initialWidth / 2}px !important`}
						}
					}
					#${targetId} > [data-rmiz-overlay] > [data-rmiz-modal-content] {
						${scale && `transform: scale(${scale}) !important;`}
						${tw`relative mx-[auto] !left-[unset]`}
              > span {
							${tw`absolute bg-white top-[8px] right-[8px] rounded bg-contain bg-no-repeat border border-solid border-white`}
							background-image: url("/react-images/common/mobileModalCloseIcon.svg");
							${`width: ${scale <= 5 ? 6 : scale / 1.5}px;`}
							${`height: ${scale <= 5 ? 6 : scale / 1.5}px;`}
						}
					}
					[data-rmiz-wrap="hidden"] {
						${tw`visible`}
					}
					[data-rmiz-wrap] > button {
						${tw`focus:outline-none`}
					}
					[data-rmiz-modal-content] > picture > img {
						${tw`rounded-sm shadow-lg border border-solid border-[5px] border-white`}
					}
					[data-rmiz-overlay] {
						${tw`absolute !bg-[#ffffffa6]`}
						button {
							${tw`focus:outline-none`}
						}
					}
					[data-rmiz-wrap] > picture > img {
						${tw`rounded-lg shadow-md`}
					}
				`}
			/>
			<div
				css={[
					tw`flex flex-wrap justify-around h-fit w-max`,
					imageCount <= 4
						? css`
								@media (max-width: 400px) {
									max-width: 200px;
								}
								${tw`md:max-w-[200px] m-auto`}
							`
						: tw`max-w-[270px] lg:ml-2`,
					isProductListingPage && tw`flex-nowrap`,
				]}
			>
				{images.map((src, idx) => (
					<div key={idx} css={tw`mb-2 mr-2 h-fit`}>
						<Zoom portalEl={portal}>
							<>
								<BasePictureElement
									alt={`${companyName} product image ${idx + 1}`}
									src={src}
									width={initialWidth.toString()}
								/>
								<span aria-hidden="true"></span>
							</>
						</Zoom>
					</div>
				))}
			</div>
		</>
	);
};

export default ImageGallery;

ImageGallery.propTypes = {
	company: PropTypes.object.isRequired,
	isFeatured: PropTypes.bool,
};
