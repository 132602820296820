import { useContext } from "react";
import { twMerge } from "@/helpers/twMerge";
import { getCompanyPhoneNumber } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { PhoneIcon } from "../../../client/assets/icons/thecreditreview/PhoneIcon";
import BasePhoneLink from "../../BaseComponents/BasePhoneLink";
import { CompanyContext } from "../../CompanyList/CompanyContext";

export const PhoneLink = ({ className }) => {
	const { company } = useContext(CompanyContext);
	const phoneNumber = useSelector((state) =>
		getCompanyPhoneNumber(state, { company }),
	);

	return (
		<BasePhoneLink
			company={company}
			className={twMerge(
				`tw-relative tw-mb-4 tw-flex tw-h-[48px] tw-w-[175px] tw-items-center tw-justify-center tw-rounded-[3px] tw-bg-[#44a4ed] tw-py-3 hover:tw-bg-[#449adb] lg:tw-flex lg:tw-w-full lg:tw-py-0`,
				className,
			)}
		>
			<div className="tw-flex tw-items-center tw-justify-center tw-rounded-full">
				<PhoneIcon className="tw-h-6 tw-w-6" />
			</div>
			<div className="tw-flex-col lg:tw-flex">
				<div className="tw-ml-2 tw-pt-1 tw-text-base tw-font-bold tw-text-white">
					{phoneNumber}
				</div>
			</div>
		</BasePhoneLink>
	);
};

PhoneLink.propTypes = {
	className: PropTypes.string,
};
