import { twMerge } from "@/helpers/twMerge";
import * as selectors from "@/selectors/index";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import OutboundLink from "../general/OutboundLink";

const BasePhoneLink = ({ company, children, className }) => {
	const classNameProp = twMerge("tw-w-full", className);
	const phoneNumberHref = useSelector((state) =>
		selectors.getCompanyPhoneNumberHref(state, { company }),
	);

	const companySlug = useSelector((state) =>
		selectors.getCompanySlug(state, { company }),
	);

	if (!phoneNumberHref) {
		return null;
	}

	return (
		<OutboundLink
			data-cy="phone-link"
			to={phoneNumberHref}
			data-c2c-company-slug={companySlug}
			company={company}
			openInNewTab={false}
			className={classNameProp}
		>
			{children}
		</OutboundLink>
	);
};

export default BasePhoneLink;

BasePhoneLink.propTypes = {
	company: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
