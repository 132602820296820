import { useContext } from "react";
import { CURRENT_YEAR } from "@/config/constants";
import { getCategoryName } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseFeaturedCompanyHeader = (props) => {
	const { isFeaturedCompany } = useContext(CompanyContext);
	if (!isFeaturedCompany) return null;

	const categoryName = useSelector(getCategoryName);

	return (
		<div className={props?.wrapper}>
			{props.icon && <div className={props?.iconClass}>{props.icon}</div>}
			<span className={props?.contentWrapper}>
				{`Our #1 ${categoryName} Choice for ${CURRENT_YEAR}`}
			</span>
		</div>
	);
};

BaseFeaturedCompanyHeader.propTypes = {
	wrapper: PropTypes.string,
	contentWrapper: PropTypes.string,
	icon: PropTypes.element,
	iconClass: PropTypes.string,
};
