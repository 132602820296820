import { twMerge } from "@/helpers/twMerge";
import PropTypes from "prop-types";

export const CouponDiscountIcon = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		aria-hidden="true"
		focusable="false"
		className={twMerge(
			`tw-inline-block tw-h-[1em] tw-w-[1em] tw-shrink-0 tw-align-middle tw-leading-[1em]`,
			className,
		)}
	>
		<path
			fill="currentcolor"
			d="m20.34 12-8.67-8.67c-.21-.21-.5-.33-.8-.33H4.14A1.13 1.13 0 0 0 3 4.13v6.75c0 .3.12.58.33.8L12 20.33a2.25 2.25 0 0 0 3.18 0l5.16-5.16a2.25 2.25 0 0 0 0-3.18ZM8.63 10.31a1.69 1.69 0 1 1 0-3.37 1.69 1.69 0 0 1 0 3.37Z"
		/>
	</svg>
);

CouponDiscountIcon.propTypes = {
	className: PropTypes.string,
};
